// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
// import Generic from './Generic.jsx';

import { useTranslation } from "react-i18next/hooks";

import SingleAdElementContents from "../../../components/UserAdElements/SingleAdElement/SingleAdElementContents";
import { useStore } from "../../../hook-store/store";

import { getUserDataForStore } from "../../../util/user";
import { getUserAdElements, deleteAdElement } from "../../../util/ad-visit";

import { BASE_URL } from "../../../App";

import classes from './SingleAdElement.module.css';

const SingleAdElement = (props) => {
  // console.log("SingleAdElement-props", props);
  const { isAuth, match, history } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();

  const [adElementId, setAdElementId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // console.log('store in SingleAdElement.js', store);
  useEffect(() => {
    window.scrollTo(0,0);
  },[]);

  useEffect(() => {
    if (isAuth) {
      if (store.userAdElements.length === 0) {
        getUserAdElements(BASE_URL, localStorage.getItem("token"))
          .then((result) => {
            console.log(result);
            dispatch("SET_USER_ADELEMENTS", result.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (!store.userData) {
        getUserDataForStore(BASE_URL, localStorage.getItem("token"))
          .then((result) => {
            console.log(result);

            dispatch("SET_USERDATA", result.userData);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [isAuth]);

  useEffect(() => {
    if (isAuth && match) {
      const adElementId = match.params.adElementId;
      setAdElementId(adElementId);
      // getAdElement(BASE_URL, localStorage.getItem('token'), adElementId);
    }
  }, [isAuth, match]);



  const deleteAdElementHandler = (url, token, adElementId) => {
    setIsLoading(true);
    
    deleteAdElement(url, token, adElementId)
      .then((resData) => {
        console.log(resData);

        dispatch('SHOW_NOTIFICATION', {
          // status: 'success',
          // title: 'notify-title',
          message: 'Deletion Success',
        });

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION')
        }, 1000* 5);

        const deletedList = store.userAdElements.filter(ad => {
          return ad.adElementId !== adElementId;
        });

        dispatch('SET_USER_ADELEMENTS', deletedList);
        history.push('/create-ad-element');

        setIsLoading(false);

        // return getUserAdElements(BASE_URL, localStorage.getItem("token"));
      })
      // .then((result) => {
      //   console.log(result);
      //   dispatch("SET_USER_ADELEMENTS", result.data);
      //   history.push('/create-ad-element');
      // })
      .catch((err) => {
        console.log(err);

        dispatch('SHOW_NOTIFICATION', {
          // status: 'success',
          // title: 'notify-title',
          message: 'Deletion Failed',
        });

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION')
        }, 1000* 5);

        setIsLoading(false);
      });
  };

  const selectAdElementHandler = (adElementId) => {
    const selectedAdElement = store.userAdElements.find((ad) => {
      return ad.adElementId === adElementId;
    });

    if (selectedAdElement) {
      dispatch("SET_SELECTED_ADELEMENT", selectedAdElement);
      history.push("/create-ad-element");
    }
  };

  let userAdElementsBody;

  if (store.userAdElements.length > 0 && adElementId) {
    const filteredList = store.userAdElements.filter((el) => {
      return el.adElementId === adElementId;
    });

    userAdElementsBody = (
      <ul>
        {filteredList.map((ad) => {
          return (
            <div className={classes.singleAdElementContainer} key={ad.adElementId}>
              <SingleAdElementContents 
                ad={ad}
                deleteAdElementHandler={deleteAdElementHandler}
                selectAdElementHandler={selectAdElementHandler} 
                history={history}
                isLoading={isLoading}
              />
            </div>
          );
        })}
      </ul>
    );
  }

  let createAdBody;
  if (!isAuth) {
    createAdBody = <div>auth required</div>;
  }
  
  if (isAuth) {
    createAdBody = (
      <div>
        <div>{userAdElementsBody}</div>
      </div>
    );
  }

  return <Fragment>
    {createAdBody}
    </Fragment>;
};
export default SingleAdElement;
