// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect } from "react";
// import { useTranslation } from "react-i18next/hooks";

import { useStore } from "../../hook-store/store";

import { getUserDataForStore } from "../../util/user";
import { getUserAdElements } from "../../util/ad-visit";

import { BASE_URL } from "../../App";


const GetUserAdElements = (props) => {
  // console.log('UserAdElementsItem-props', props);
  const { isAuth, history } = props;

  // const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();

  useEffect(() => {
    if (isAuth) {
      if (store.userAdElements.length === 0) {
        getUserAdElements(BASE_URL, localStorage.getItem("token"))
          .then((result) => {
            console.log(result);
            dispatch("SET_USER_ADELEMENTS", result.data);

            // history.push(`/create-ad-element`);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (!store.userData) {
        getUserDataForStore(BASE_URL, localStorage.getItem("token"))
          .then((result) => {
            console.log(result);

            dispatch("SET_USERDATA", result.userData);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [isAuth]);


  return <Fragment></Fragment>;
};
export default GetUserAdElements;
