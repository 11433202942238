import React, { Fragment } from "react";
import { withI18n } from "react-i18next";

import FeedEditImageUpdate from "../FeedEditImageUpdate/FeedEditImageUpdate";

import GrayImage from '../../../../images/light-gray-square-300.jpg';

import { marks } from "../../../../images/icon/marks";
import { isVideoFile } from "../../../../util/image";

import classes from "./ImagePreviewContents.module.css";
import "../FeedEdit.css";
// import "./ImagePreviewContents.css"


const ImagePreviewContents = (props) => {
  console.log("ImagePreviewContents.js-props", props);
  const { 
    t, 
    state, 
    selectedPost, 
    imageUrl,
    ad150x500ImageUrl,
    ad600x130ImageUrl,
    postInputChangeHandler,
    updateImagePreviewHandler,
   } = props;

  let body300x65 = (
    <div>
      <div>300x65</div>
      <div className="body300x65Container">
        <div className="body300x65Text">
          <div className="body300x65TextContents">
            <div className="body300x65TextTitle">
              {state.postForm.title.value}
            </div>
            <div className="body300x65TextDescription">
              {state.postForm.content.value}
            </div>
          </div>
        </div>
        <div>
          <img
            className="body300x65Image"
            src={imageUrl ? imageUrl : GrayImage}
            alt="300x65 image"
          ></img>
        </div>
      </div>
    </div>
  );

  if (ad600x130ImageUrl) {
    body300x65 = (
      <div>
        <div>300x65</div>
        <div className="body300x65Container">    
          <img
            src={ad600x130ImageUrl}
            alt="300x65 image"
          />
        </div>
      </div>
    )
  }



  const body300x300 = (
    <div>
      <div>300x300</div>
      <div className="body300x300Container">
        <div>
          <img
            className="body300x300Image"
            src={imageUrl ? imageUrl : GrayImage}
            alt="300x300 image"
          ></img>
        </div>
        <div className="body300x300TextContainer">
          <span className="body300x300TextTitle">
            {state.postForm.title.value}
          </span>
          <span className="body300x300TextDescription">
            {state.postForm.content.value}
          </span>
        </div>
      </div>
    </div>
  );

  let bodyInPosts = (
    <div>
      <div>
        {t('adForm.text10', 'between posts')} (in feed posts)
        </div>
      <div className="bodyInPostContainer">
        <div className="bodyInPostContents">
          <img
            className="bodyInPostImageContainer"
            src={imageUrl ? imageUrl : GrayImage}
            alt="300x300 image"
          ></img>
          <div className="bodyInPostTextContainer">
              <div className="bodyInPostTextTitle">
                {state.postForm.title.value}
              </div>
              <div className="bodyInPostTextDescription">
                {state.postForm.content.value}
              </div>
          </div>
        </div>
      </div>
    </div>
  );

  // if (ad600x130ImageUrl) {
  //   bodyInPosts = (
  //     <div>
  //       <div>{t('adForm.text10', 'between posts')} (in feed posts)</div>
  //       <div className="bodyInPostContainer"
  //         style={{border:'none', maxHeight:"150px"}}
  //       >    
  //           <img
  //             src={ad600x130ImageUrl}
  //             alt="600x130 image"
  //           />
  //       </div>
  //     </div>
  //   )
  // }



  let body150x500 = (
    <div>
      <div>150x500</div>
      <div className="body150x500Container">    
        <div className="body150x500Text">
            <div className="body150x500TextContents">
              <div className="body150x500TextTitle">
                {state.postForm.title.value}
              </div>
              <div className="body150x500TextDescription">
                {state.postForm.content.value}
              </div>
            </div>
          </div>
          <img
            className="body150x500Image"
            src={imageUrl ? imageUrl : GrayImage}
            alt="150x500 image"
          />
      </div>
    </div>
  );

  if (ad150x500ImageUrl) {
    body150x500 = (
      <div>
        <div>150x500</div>
        <div className="body150x500Container">    
            <img
              src={ad150x500ImageUrl}
              alt="150x500 image"
            />
        </div>
      </div>
    )
  }


  let body450x90 = (
    <div>
      <div>450x90 (for middle size screen)</div>
      <div
        className="body450x90Container" 
        // className={classes.body450x90Container}
      >    
        <div 
          className="body450x90Text"
          // className={classes.body450x90Text}
        >
            <div 
              className="body450x90TextContents"
              // className={classes.body450x90TextContents}
            >
              <div 
                className="body450x90TextTitle"
                // className={classes.body450x90TextTitle}
              >
                {state.postForm.title.value}
              </div>
              <div 
                className="body450x90TextDescription"
                // className={classes.body450x90TextDescription}
              >
                {state.postForm.content.value}
              </div>
            </div>
          </div>
          <img
            className="body450x90Image"
            // className={classes.body450x90Image}
            src={imageUrl ? imageUrl : GrayImage}
            alt="450x90 image"
          />
      </div>
    </div>
  );

  if (ad600x130ImageUrl) {
    body450x90 = (
      <div>
        <div>450x90 (for for middle size screen)</div>
        <div className="body450x90Container"
          // style={{justifyContent: 'space-around', backgroundColor: "transparent"}}
        >    
            <img
              style={{width: "450px", height:"90px"}}
              src={ad600x130ImageUrl}
              alt="450x90 image"
            />
        </div>
      </div>
    )
  }



  let body600x130 = (
    <div>
      <div>600x130 (for large size screen)</div>
      <div 
        className="body600x130Container"
        // className={classes.body600x130Container}
      >    
        <div 
          className="body600x130Text"
          // className={classes.body600x130Text}
        >
            <div 
              className="body600x130TextContents"
              // className={classes.body600x130TextContents}
            >
              <div 
                className="body600x130TextTitle"
                // className={classes.body600x130TextTitle}
              >
                {state.postForm.title.value}
              </div>
              <div 
                className="body600x130TextDescription"
                // className={classes.body600x130TextDescription}
              >
                {state.postForm.content.value}
              </div>
            </div>
          </div>
          <img
            className="body600x130Image"
            // className={classes.body600x130Image}
            src={imageUrl ? imageUrl : GrayImage}
            alt="600x130 image"
          />
      </div>
    </div>
  );

  if (ad600x130ImageUrl) {
    body600x130 = (
      <div>
        <div>600x130 (for large size screen)</div>
        <div className="body600x130Container">    
            <img
              src={ad600x130ImageUrl}
              alt="600x130 image"
            />
        </div>
      </div>
    )
  }

  let bodyVideo;

  if (state && state.postForm && 
      !state.postForm.image.value.name &&
      state.postForm.image.value) {

    const imageValue = state.postForm.image.value;
    const fileType = imageValue.split('.')[imageValue.split('.').length - 1].toLowerCase();
  
    console.log('imageValue', imageValue, fileType);

    if (isVideoFile(fileType)) {
      bodyVideo = (
        <video
          className="videoAdPreview"
          // style={{ width:"400px", maxWidth:"100%" }} 
          src={imageUrl} controls
        />
      );
    }
    
  }
  


  let imagePreviewContentsBody;

  if (!bodyVideo) {
    imagePreviewContentsBody = (
      <div>
        <div className={classes.previewContainer}>
          {body300x65}
          {body300x300}
    
          {body150x500}
    
          {body450x90}
    
          {body600x130}
        </div>
        {bodyInPosts}
      </div>
  
    );
  } else {
    imagePreviewContentsBody = (
      <div className={classes.previewContainer}>
        {bodyVideo}
      </div>
    );
  }

  return <Fragment>
    {imagePreviewContentsBody}

    <section className={classes.updateImage}>
      {!selectedPost && !bodyVideo && (
        <div className={classes.updateImageTitle}>
          {t('adForm.text12', 'You can update images for 150x500 and 600x130 after creating ad.')}
        </div>
      )}

      {selectedPost && !bodyVideo && (
        <div className={classes.updateImageTitle}>
           {t('adForm.text13', 'You can update images for 150x500 and 600x130')} 
           {' '}
           ({t('adForm.text14', 'smaller ads use same image')})
        </div>
      )}

      <div className={classes.updateImageContent}>
        {selectedPost && !bodyVideo && (
          <FeedEditImageUpdate 
            postInputChangeHandler={postInputChangeHandler}
            imageType={'ad150x500ImageUrl'}
            // updateImageHandler={this.updateImageHandler}
            adElementId={selectedPost ? selectedPost.adElementId : ''}
            state={state}
            image600x130Preview={state.image600x130Preview}
            image150x500Preview={state.image150x500Preview}
            updateImagePreviewHandler={updateImagePreviewHandler}
          />
        )}
        </div>
        <div className={classes.updateImageContent}>
          {selectedPost && !bodyVideo && (
            <FeedEditImageUpdate 
              postInputChangeHandler={postInputChangeHandler}
              imageType={'ad600x130ImageUrl'}
              // updateImageHandler={this.updateImageHandler}
              adElementId={selectedPost ? selectedPost.adElementId : ''}
              state={state}
              image600x130Preview={state.image600x130Preview}
              image150x500Preview={state.image150x500Preview}
              updateImagePreviewHandler={updateImagePreviewHandler}
            />
          )}
        </div>
    </section>

    </Fragment>;
};

export default withI18n()(ImagePreviewContents);
