import React, { Fragment, useState, useEffect } from "react";
import { withI18n } from "react-i18next";

import "./FeedEdit.css";
import classes from './FeedEditDeviceItems.module.css';

const FeedEditDeviceItems = (props) => {
  console.log('FeedEditDeviceItems.js-props', props);
  
  const {
    t,
    state,
    postInputChangeHandler,
  } = props;

  // console.log('targetDevice state.postForm.targetDevice', state.postForm.targetDevice)

  const [selectedValue, setSelectedValue] = useState('');


  //// initialize selected value if exist 
  useEffect(() => {
    if (state.postForm.targetDevice.value) {
      setSelectedValue(state.postForm.targetDevice.value);
    }
  },[state]);
  

  const deviceSelectHandler = (event) => {
    postInputChangeHandler('targetDevice', event.target.value);
    setSelectedValue(event.target.value);
  };

  const targetDeviceBody = (
    <div>
      <div className="feedEdit__sectionTitle">
        {t('adForm.text11', 'Target device')}
      </div>
      <select className={classes.feedEditDeviceItemsSection}
        value={selectedValue}
        onChange={deviceSelectHandler}
      >
        <option value='mobile'>
          Mobile
        </option>
        <option value='desktop'>
          Desktop
        </option>
        <option value=''>
          Both
        </option>
      </select>
    </div>
  );

  return (
    <Fragment>
      {targetDeviceBody}
    </Fragment>
  );
};

export default withI18n()(FeedEditDeviceItems);
