import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SHOW_NOTIFICATION: (state, payload) => {
      const notifyContent = {
        status: payload.status,
        title: payload.title,
        message: payload.message,
      };
      return { 
        ...state,
        notification: notifyContent, 
        // i18n: state.i18next,
      };
    },
    CLEAR_NOTIFICATION: (state, payload) => {
      // const notifyContent = {
      //   status: null,
      //   title: null,
      //   message: null,
      // };
      return { 
        ...state,
        notification: null,
        // notification: notifyContent,
        // i18n: state.i18next,
       };
    },
    // SET_MAPCENTER: (state, payload) => {
    //   return { 
    //     ...state,
    //     mapCenter: payload,
    //    };
    // },
    SET_MARKER_CENTER: (state, payload) => {
      return { 
        ...state,
        markerCenter: payload,
       };
    },
    // SET_MARKER_RADIUSKM: (state, payload) => {
    //   return { 
    //     ...state,
    //     markerRadiusKm: payload,
    //    };
    // },
    SET_AREACONTROL_VALUES:(state, payload) => {
      return { 
        ...state,
        areaControlValues: payload,
       };
    },
  };

  initStore(actions, {
    notification: null,
    // mapCenter: [0, 0],
    // mapZoom: 1,
    markerCenter: [0, 0],
    // markerRadiusKm: 2000,
    areaControlValues: {},
  });
};

export default configureStore;
