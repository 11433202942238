export const updateUserPaused = (url, token, adElementId, userPaused) => {
  return new Promise((resolve, reject) => {

    fetch(url + `/ad-element/user-paused`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        adElementId: adElementId,
        userPaused: userPaused,
        // geolocation: userLocation,
      })
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("update userPaused failed");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);
  
        resolve({ message: 'update userPaused success', data: resData });
      })
      .catch(err => {
        console.log(err);
        // resolve({ message: 'emailVerified fbUserId update failed', error: err });
        reject({ message: 'update userPaused failed', error: err });
      });
  });
};


export const getAdImageUrls = (url, token) => {
  return new Promise((resolve, reject) => {

    fetch(url + `/ad-element-image/ad-image-urls`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      // body: JSON.stringify({
      //   adElementId: adElementId,
      //   userPaused: userPaused,
      //   // geolocation: userLocation,
      // })
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("update userPaused failed");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);
  
        resolve({ message: 'get adImageUrls success', data: resData });
      })
      .catch(err => {
        console.log(err);
        // resolve({ message: 'emailVerified fbUserId update failed', error: err });
        reject({ message: 'get adImageUrls failed', error: err });
      });
  });
};