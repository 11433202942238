import React, { Fragment, useEffect, useState } from "react";
import { withI18n } from "react-i18next";

import Loader from "../../../Loader/Loader";
import { useStore } from "../../../../hook-store/store";

import { getAdImageUrls } from "../../../../util/ad-element";

import { BASE_URL } from "../../../../App";

// import "./FeedEdit.css";
import classes from './ImagesForReuse.module.css';

const ImagesForReuse = (props) => {
  const { 
    t,
    state, 
    postInputChangeHandler, 
    inputBlurHandler, 
    imagePreviewBody,
    getInputHandler,
    selectedPost,
    updateImagePreviewHandler,
    adElementId,
    imageType,
    setShowSelectImage,
  } = props;

  const [store, dispatch] = useStore();
  // const userAdElements = store.userAdElements;

  const [adImageUrls, setAdImageUrls] = useState([]);
  const [ad150x500ImageUrls, setAd150x500ImageUrls] = useState([]);
  const [ad600x130ImageUrls, setAd600x130ImageUrls] = useState([]);
  const [showReuseImages, setShowReuseImages] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAdImageUrlsHandler(BASE_URL, localStorage.getItem('token'));
  },[]);

  // useEffect(() => {
  //   const imageList = [];
  //   const image150x500List = [];
  //   const image600x130List = [];

  //   if (userAdElements.length > 0 && 
  //     (ad150x500ImageUrls.length === 0 || ad600x130ImageUrls.length ===0)
  //   ) {
  //     for (const ad of userAdElements) {
  //       if (ad.ad150x500ImageUrl) {
  //         image150x500List.push({
  //           ad150x500ImageUrl: ad.ad150x500ImageUrl,
  //           ad150x500ImagePath: ad.ad150x500ImagePath,
  //         });

  //       }

  //       if (ad.ad600x130ImageUrl) {
  //         image600x130List.push({
  //           ad600x130ImageUrl: ad.ad600x130ImageUrl,
  //           ad600x130ImagePath: ad.ad600x130ImagePath,
  //         });

  //       }
  //     }
  //   }

  //   setAd150x500ImageUrls(image150x500List);
  //   setAd600x130ImageUrls(image600x130List);

  //   console.log('image150x500List', image150x500List);
  //   console.log('image600x130List', image600x130List);

  //   // setAdImageUrls(imageList);
  //   // console.log('imageList', imageList);
  // },[adImageUrls]);

  const showReuseImagesHandler = (event) => {
    event.preventDefault();
    setShowReuseImages(prev => !prev);
  }


  const getAdImageUrlsHandler = async (url, token) => {
    try {
      const result = await getAdImageUrls(url, token);

      const adImageUrls = result.data.data;
      console.log('adImageUrls', adImageUrls, adImageUrls.ad150x500ImageUrls);
      // setAdImageUrls(adImageUrls);
      setAd150x500ImageUrls(adImageUrls.ad150x500ImageUrls);
      setAd600x130ImageUrls(adImageUrls.ad600x130ImageUrls);

    } catch(err) {
      console.log(err);
    }
  };


  const updateReuseImageHandler = async (event, adElementId, imageType, imagePath) => {
    try {
      event.preventDefault();
      setIsLoading(true);
  
      const formData = new FormData();
  
      formData.append('adElementId', adElementId);
      formData.append('imageType', imageType);
      formData.append('imagePath', imagePath);


      const result = await fetch(BASE_URL + `/ad-element-image/update-reuse-image`, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          // 'Content-Type': 'application/json'
        },
        body: formData,
      });
      
      const resData = await result.json();
      
      if (result.ok) {
        console.log(result, resData);

        const adList = store.userAdElements;
        
        const indexInAdList = adList.findIndex(ad => {
          return resData.data.adElementId === ad.adElementId; 
        });

        adList[indexInAdList] = resData.data;

        console.log('adList', adList, resData.data);
        dispatch('SET_USER_ADELEMENTS', adList);

        // updateSelectedPostHandler(resData.data);
        // startEditAdHandler(resData.data.adElementId);

        // updateImagePreviewHandler(imageType, null);
        // updateImagePreviewHandler(resData.data.adElementId, imageType);
        
        if (imageType === 'ad150x500ImageUrl') {
          updateImagePreviewHandler(resData.data.adElementId, imageType, resData.data.ad150x500ImageUrl);
        }
  
        if (imageType === 'ad600x130ImageUrl') {
          updateImagePreviewHandler(resData.data.adElementId, imageType, resData.data.ad600x130ImageUrl);
        }

        dispatch('SHOW_NOTIFICATION', {
          message: 'Image Updated',
        });

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        }, 1000* 5);

      } else {
        console.log(result, resData);
        throw new Error('something wrong');
      }

      setShowSelectImage(false);
      setIsLoading(false);
  
      // this.props.onFinishEdit(post);
      // this.setState({
      //   postForm: POST_FORM,
      //   formIsValid: false,
      //   imagePreview: null
      // });

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  let existImageList;

  if (imageType === 'ad150x500ImageUrl' && ad150x500ImageUrls.length > 0) {
    existImageList = (
      <ul className={classes.reuseImageList}>
        {ad150x500ImageUrls.map(url => {
          // const timeEndIndex = url.ad150x500ImagePath.indexOf('Z');
          // const fileOriginalName = url.ad150x500ImagePath.slice(timeEndIndex + 2);
  
          return (
            <div 
              id={url.imagePath}
              key={url.imagePath}
            >
              {/* {adImageUrl.slice(0, 50)} */}
            <img 
            // style={{height:"150px", marginRight: "20px", cursor:"pointer"}}
              className={classes.magesForReuse150x500Image} 
              onClick={(event) => { 
                if (!isLoading) {
                  updateReuseImageHandler(
                    event,
                    adElementId,
                    imageType,
                    url.imagePath,
                  );
                }
                // postInputChangeHandler('ad150x500ImageUrl', url.imagePath, null, null); 
                // setShowReuseImages(false);
              }}
              src={url.imageUrl} 
            />
              {/* <div>{fileOriginalName}</div> */}
            </div>)
        })}
      </ul>
    );
  }


  if (imageType === 'ad600x130ImageUrl' && ad600x130ImageUrls.length > 0) {
    existImageList = (
      <ul className={classes.reuseImageList}>
        {ad600x130ImageUrls.map(url => {
          // const timeEndIndex = url.ad600x130ImagePath.indexOf('Z');
          // const fileOriginalName = url.ad600x130ImagePath.slice(timeEndIndex + 2);
  
          return (
            <div 
              id={url.imagePath} 
              key={url.imagePath}
            >
              {/* {adImageUrl.slice(0, 50)} */}
            <img 
              // style={{width: '150px'}}
              className={classes.magesForReuse600x130Image} 
              onClick={(event) => { 
                if (!isLoading) {
                  updateReuseImageHandler(
                    event,
                    adElementId,
                    imageType,
                    url.imagePath,
                  );
                }
                // postInputChangeHandler('image', url.adImagePath, null, url.adImageUrl); 
                // setShowReuseImages(false);
              }}
              src={url.imageUrl} 
            />
              {/* <div>{fileOriginalName}</div> */}
            </div>)
        })}
      </ul>
    );
  }

  if (isLoading) {
    existImageList = (<Loader />);
  }

  // if (adImageUrls.length > 0) {
  //   existImageList = (
  //     <ul className={classes.reuseImageList}>
  //       {adImageUrls.map(url => {

  //         const timeEndIndex = url.adImagePath.indexOf('Z');
  //         const fileOriginalName = url.adImagePath.slice(timeEndIndex + 2);
  
  //         return (
  //           <div id={url.adImagePath}>
  //             {/* {adImageUrl.slice(0, 50)} */}
  //           <img className={classes.reuseImageImage} 
  //             onClick={() => { 
  //               postInputChangeHandler('image', url.adImagePath, null, url.adImageUrl); 
  //               setShowReuseImages(false);
  //             }}
  //             src={url.adImageUrl} 
  //           />
  //             {/* <div>{fileOriginalName}</div> */}
  //           </div>)
  //       })}
  //     </ul>
  //   );
  // }


  let imagesForReuseBody;

  if (imageType === 'ad150x500ImageUrl' && ad150x500ImageUrls.length > 0) {
    imagesForReuseBody = (
      <div>
        <div>{t('general.text38', 'or')},</div>
        <button className={classes.reuseImageOpenButton}
          onClick={showReuseImagesHandler}
        >
          {t('adForm.text06', 'Select from previous images')} (150x500)
        </button>
        {showReuseImages && (
          <div className={classes.reuseImageContainer}>
            {existImageList}
          </div>
        )}
      </div>
    );
  }

  if (imageType === 'ad600x130ImageUrl' && ad600x130ImageUrls.length > 0) {
    imagesForReuseBody = (
      <div>
        <div>{t('general.text38', 'or')},</div>
        <button className={classes.reuseImageOpenButton}
          onClick={showReuseImagesHandler}
        >
          {t('adForm.text06', 'Select from previous images')} (600x130)
        </button>
        {showReuseImages && (
          <div className={classes.reuseImageContainer}>
            {existImageList}
          </div>
        )}
      </div>
    );
  }

  return (
    <Fragment>
      {imagesForReuseBody}
    </Fragment>
  );
};

export default withI18n()(ImagesForReuse);
