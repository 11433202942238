import React from "react";
import { Fragment, useState, useEffect } from "react";
import { withI18n } from "react-i18next";
import {
  MapContainer,
  TileLayer,
  useMapEvents,
  MapConsumer,
  Marker,
  Popup,
  ScaleControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// import icon from "./constants";
// import "../styles.css";

// import PopupWithMarker from "./PopupWithMarker";
import DraggableMarker from "./DraggableMarker";
import AreaControl from "./AreaControl";
import MapComponent from "./MapComponent";

// import { useStore } from "../../hook-store/store";

import classes from './MapMainComponent.module.css';

function MapMainComponent(props) {
  // console.log('MapMainComponsent.js-props', props);
  const { selectedPost, t } = props;

  // function MyComponent() {
  //   const map = useMapEvents({
  //     click: (e) => {
  //       const { lat, lng } = e.latlng;
  //       // L.marker().remove();
  //       L.marker([lat, lng], { icon }).addTo(map);
  //     },
  //   });
  //   return null;
  // }

  const { targetLocationChangeHandler } = props;


  return (
    <Fragment>
      <div className={classes.mapTitle}>
        {t("general.text27", 'TARGET LOCATION')}
      </div>
      <MapContainer
        center={[0, 0]}
        zoom={4}
        // style={{ height: "50vh", width: "100%" }}
        style={{ height: "20rem", width: "95%" }}
      >
        <ScaleControl position="bottomleft" />

        <MapComponent />
        
        <DraggableMarker 
          selectedPost={selectedPost}
        />
        {/* <PopupWithMarker /> */}
      </MapContainer>


      <section>
        <AreaControl 
          targetLocationChangeHandler={targetLocationChangeHandler}
          selectedPost={selectedPost}
        />
      </section>
    </Fragment>
  );
}

export default withI18n()(MapMainComponent);