import React from 'react';
import { Fragment, useState } from 'react';
import { useTranslation } from "react-i18next/hooks";

// import styled from 'styled-components'

import AdReportDownload from './AdReportDownload';
import Table from './Table';
import TableControll from './TableControll';
import { useStore } from '../../hook-store/store'

import classes from './Pagination.module.css';

function Pagination(props) {
  const { userAdResults } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const listForTable = store.adReportStore.listForTable;
  const totalResult = store.adReportStore.totalResult;
  console.log('store in Pyagination.js', store);

  const columns = React.useMemo(
    () => [
      // {
      //   Header: 'Column 1',
      //   accessor: 'col1', // accessor is the "key" in the data
      // },
      // {
      //   Header: 'Column 2',
      //   accessor: 'col2',
      // },
      {
        // Header: ' Date ',
        Header: ` ${t('general.text43', 'date')} `,
        accessor: 'date',
      },
      {
        // Header: ' Display ',
        Header: ` ${t('adReport.text11', 'display')} `,
        accessor: 'displayNum',
      },
      {
        // Header: ' Visit ',
        Header: ` ${t('adReport.text13', 'visit or view')} `,
        accessor: 'visitNum',
      },
      {
        // Header: 'Visit Rate (%)',
        Header: ` ${t('adReport.text14', 'visit or view rate')} (%)`,
        accessor: 'vRate',
      },
      {
        // Header: 'Cost',
        Header: ` ${t('general.text45', 'cost')} `,
        accessor: 'charge',
      },
    ],
    []
  )

  const data = React.useMemo(() => listForTable, 
  [listForTable]);

  const vRateNum = Number(totalResult.visitNum) / Number(totalResult.displayNum);

  const totalResultBody = (
    <div className={classes.totalResult}>
      <span className={classes.totalResultItem}>
        {t('adReport.text06', 'Total display')}: {totalResult.displayNum} 
      </span>
      <span className={classes.totalResultItem}>
        {t('adReport.text07', 'Total visit or view')}: {totalResult.visitNum}
        
        {vRateNum > 0 && (
          <span> ({(vRateNum * 100).toFixed(2)}%)</span>
        )}
      </span>
      <span className={classes.totalResultItem}>
        {t('adReport.text08', 'Total cost')}: ${totalResult.charge.toFixed(2)}
      </span>
    </div>
  );
  // console.log('data', data, listForTable);

  // const [store, dispatch] = useStore();
  // console.log('store in Pagination.js', store);
  
  // const [selectedAdElement, setSelectedAdElement] = useState();
  // console.log(selectedAdElement);

  return (
    <Fragment>
      <TableControll />
      {totalResultBody}
      <Table columns={columns} data={data} />
      <AdReportDownload 
        columns={columns} 
        data={data}
      />
    </Fragment>
    // <Styles>
    // </Styles>
  )
}

export default Pagination;
