export const getPageNotification = async (url, token) => {
  try {
      const result = await fetch(url + '/page-notification', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
      });

      if (!result.ok) {
        throw new Error('error occured');
      }

      const resData = await result.json();
      console.log(resData, result);
      return resData;
  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const createTestPageNotification = async (url, token) => {
  try {
      const result = await fetch(url + '/page-notification/test-page-notification', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        // body: JSON.stringify({
        //   adElementId: adElementId,
        //   adStatus: adStatus,
        //   reason: adStatusDetail.reason,
        //   comment: adStatusDetail.comment,
        // }),
      });

      if (!result.ok) {
        throw new Error('error occured');
      }

      const resData = await result.json();
      console.log(resData, result);
      return resData;
  } catch(err) {
    console.log(err);
    throw err;
  }
};

export const updatePageNotificationLastOpenTime = async (url, token) => {
  try {
      const result = await fetch(url + '/page-notification/update-last-open-time', {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        // body: JSON.stringify({
        //   adElementId: adElementId,
        //   adStatus: adStatus,
        //   reason: adStatusDetail.reason,
        //   comment: adStatusDetail.comment,
        // }),
      });

      if (!result.ok) {
        throw new Error('error occured');
      }

      const resData = await result.json();
      console.log(resData, result);
      return resData;
  } catch(err) {
    console.log(err);
    throw err;
  }
};


export const updatePageNotificationReadState = async (url, token, id, readState) => {
  try {
      const result = await fetch(url + '/page-notification/update-read-state', {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: id,
          readState: readState,
        }),
      });

      if (!result.ok) {
        throw new Error('error occured');
      }

      const resData = await result.json();
      console.log(resData, result);
      return resData;
  } catch(err) {
    console.log(err);
    throw err;
  }
};