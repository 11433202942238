// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next/hooks";

import Loader from "../../components/Loader/Loader";
import UserFilesContent from "../../components/UserFiles/UserFilesContent";

import { useStore } from "../../hook-store/store";

import { getAdImageUrls } from '../../util/ad-element';

import { BASE_URL } from "../../App";

// import classes from './UserAdElements.module.css';

const UserFiles = (props) => {
  const { } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  // console.log('store-in-UserFiles.js', store);
  const userAdElements = store.userAdElements;
  const adImageUrls = store.userFilesStore.adImageUrls;
  const ad150x500ImageUrls = store.userFilesStore.ad150x500ImageUrls;
  const ad600x130ImageUrls = store.userFilesStore.ad600x130ImageUrls;
  const userAllImageUrls = store.userFilesStore.userAllImageUrls;

  // const [adImageUrls, setAdImageUrls] = useState([]);
  // const [ad150x500ImageUrls, setAd150x500ImageUrls] = useState([]);
  // const [ad600x130ImageUrls, setAd600x130ImageUrls] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userAllImageUrls) {
      createAdImageUrlLists();
    }

  },[userAllImageUrls, userAdElements]);

  useEffect(() => {
    if (!userAllImageUrls) {
      setUserAllImageUrlsHandler();
    }
  },[]);


  const setUserAllImageUrlsHandler = async () => {
    try {
      setIsLoading(true);

      const resData = await getAdImageUrls(BASE_URL, localStorage.getItem('token'));

      // console.log('resData', resData);
      if (resData && resData.data) {
        dispatch('SET_USERALLIMAGEURLS', resData.data.data);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  const createAdImageUrlLists = () => {

      if (userAdElements.length > 0) {
        const adImageList = [];
        const ad150x500List = [];
        const ad600x130List = [];

        //// add imageUrls used in ad
        for (const ad of userAdElements) {
          if (ad.adImagePath) {
            const isInList = adImageList.find(ele => {
              return ad.adImagePath === ele.adImagePath;
            });

            

            if (!isInList) {
              adImageList.push({
                adElementId: ad.adElementId,
                adImageUrl: ad.adImageUrl,
                adImagePath: ad.adImagePath,
              });
            }
          }

          if (ad.ad150x500ImagePath) {
            const isInList = ad150x500List.find(ele => {
              return ad.ad150x500ImagePath === ele.ad150x500ImagePath;
            });

            if (!isInList) {
              ad150x500List.push({
                adElementId: ad.adElementId,
                ad150x500ImageUrl: ad.ad150x500ImageUrl,
                ad150x500ImagePath: ad.ad150x500ImagePath,
              });
            }
          }

          if (ad.ad600x130ImagePath) {
            const isInList = ad600x130List.find(ele => {
              return ad.ad600x130ImagePath === ele.ad600x130ImagePath;
            });

            if (!isInList) {
              ad600x130List.push({
                adElementId: ad.adElementId,
                ad600x130ImageUrl: ad.ad600x130ImageUrl,
                ad600x130ImagePath: ad.ad600x130ImagePath,
              });
            }
          }
        }


        //// add imageUrls not used in ad
        for (const url of userAllImageUrls.adImageUrls) {
          const isInList = adImageList.find(ele => {
            return ele.adImagePath === url.imagePath;
          });

          if (!isInList) {
            adImageList.push({
              adElementId: '',
              adImageUrl: url.imageUrl,
              adImagePath: url.imagePath,
            });
          }
        }

        for (const url of userAllImageUrls.ad150x500ImageUrls) {
          const isInList = ad150x500List.find(ele => {
            return ele.ad150x500ImagePath === url.imagePath;
          });

          if (!isInList) {
            ad150x500List.push({
              adElementId: '',
              ad150x500ImageUrl: url.imageUrl,
              ad150x500ImagePath: url.imagePath,
            });
          }
        }

        for (const url of userAllImageUrls.ad600x130ImageUrls) {
          const isInList = ad600x130List.find(ele => {
            return ele.ad600x130ImagePath === url.imagePath;
          });

          if (!isInList) {
            ad600x130List.push({
              adElementId: '',
              ad600x130ImageUrl: url.imageUrl,
              ad600x130ImagePath: url.imagePath,
            });
          }
        }



        // console.log('adImageUrls', adImageUrls, adImageUrls.ad150x500ImageUrls);
        console.log(adImageList, ad150x500List, ad600x130List);
  
        // setAdImageUrls(adImageList);
        // setAd150x500ImageUrls(ad150x500List);
        // setAd600x130ImageUrls(ad600x130List);

        dispatch('SET_ADIMAGEURLS', adImageList);
        dispatch('SET_AD150X500IMAGEURLS', ad150x500List);
        dispatch('SET_AD600X130IMAGEURLS', ad600x130List);
      }

  };

  return (
    <Fragment>
      {isLoading && (
        <Loader />
      )}

      {!isLoading && (
        <UserFilesContent />
      )}

    </Fragment>
    );
};

export default UserFiles;
