import React, { Fragment, useEffect, useState } from "react";
import Link from "react-router-dom/Link";
import { withI18n } from "react-i18next";

// import Loader from "../Loader/Loader";
import Backdrop from "../Backdrop/Backdrop";
import Button from "../Button/Button";
import SmallModal from "../Modal/SmallModal";

import { useStore } from "../../hook-store/store";

import { isVideoFile, isImageFile } from "../../util/image";
import { createDisplayId } from "../../util/ad-visit";

import { BASE_URL } from "../../App";

import { marks } from '../../images/icon/marks';
// import "./FeedEdit.css";
import classes from './UserFilesSelectControl.module.css';


const UserFilesSelectControl = (props) => {  
  const { 
    t,
    adElementIds,
    url,
    imageType,
    deleteAdImageOfPath,
    showDeleteModal,
    setShowDeleteModal,
    deleteError,
    setDeleteError,
    // oriFileName,
    isLoading,
  } = props;


  const [store, dispatch] = useStore();
  // const userAdElements = store.userAdElements;
  // const adImageUrls = store.userFilesStore.adImageUrls;
  // const ad150x500ImageUrls = store.userFilesStore.ad150x500ImageUrls;
  // const ad600x130ImageUrls = store.userFilesStore.ad600x130ImageUrls;
  // const selectedUrls = store.userFilesStore.selectedUrls;

  // const [showDeleteModal, setShowDeleteModal] = useState(false);

  // const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {

  },[]);

  // const deleteAdImageOfPath = async (url, token, adElementIds, path, imageType) => {
  //   try {
  //     setIsLoading(true);

  //     const result = await fetch(url + 
  //       `/ad-element-image/delete-ad-image-of-path?adElementIds=${JSON.stringify(adElementIds)}&path=${path}&imageType=${imageType}`, {
  //         method: 'DELETE',
  //         headers: {
  //           Authorization: 'Bearer ' + token,
  //           'Content-Type': 'application/json'
  //         },
  //       });

  //     const resData = await result.json();

  //     console.log(resData, result);

  //     if (!result.ok) {
  //       throw new Error('error occured');
  //     }

  //     setDeletedListsHandler(path);

  //     setIsLoading(false);

  //     return resData;

  //   } catch(err) {
  //     console.log(err);

  //     setIsLoading(false);
  //     throw err;
  //   }
  // };

  // const setDeletedListsHandler = (path) => {

  //   const deletedAdUrls = adImageUrls.filter(ele => {
  //     return ele.adImagePath !== path;
  //   });

  //   const deletedAd150x500Urls = ad150x500ImageUrls.filter(ele => {
  //     return ele.ad150x500ImagePath !== path;
  //   });

  //   const deletedAd600x130Urls = ad600x130ImageUrls.filter(ele => {
  //     return ele.ad600x130ImagePath !== path;
  //   });

  //   const deletedSelectedUrls = selectedUrls.filter(ele => {
  //     return ele.path !== path;
  //   });

  //   dispatch('SET_ADIMAGEURLS', deletedAdUrls);
  //   dispatch('SET_AD150X500IMAGEURLS', deletedAd150x500Urls);
  //   dispatch('SET_AD600X130IMAGEURLS', deletedAd600x130Urls);
  //   dispatch('SET_SELECTEDURLS', deletedSelectedUrls);
  // };

  const deleteModalBody = (
    <div>
      <div>
        {t('general.text3', 'Delete')}?
      </div>
      <div className={classes.userFilesSelectDeleteButton}>
        <span className={classes.singleAdElementButton}>
          <Button
            design="flat"
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            onClick={() => { setShowDeleteModal(false); }}
          >
            {t('general.text1', 'Cancel')}
          </Button>
        </span>
        <span className={classes.singleAdElementButton}>
          <Button
            design="raised"
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            onClick={() => {
              deleteAdImageOfPath(
                BASE_URL,
                localStorage.getItem('token'),
                adElementIds,
                url.path,
                imageType,
              );
            }}
          >
            {t('general.text3', 'Delete')}
          </Button>
        </span>
      </div>

      {deleteError && (
        <div>{deleteError}</div>
      )}

      {isLoading && <Loader />}
    </div>
  );


  let usingAdsBody = <div>Not used</div>

  if (url.usingAds.length > 0) {
    usingAdsBody = (
      <div>
        <div>Using Ads</div>
        <ul>
          {url.usingAds.map(usingAd => {
            return (
              <div key={usingAd.adElementId}>
                ID: {createDisplayId(usingAd.adElementId)}
                {' '}
                <Link to={`/ad-element/${usingAd.adElementId}`}
                  // target="_blank" rel="noopener noreferrer"
                >
                  (Ad detail Page)
                </Link>
              </div>
            );
          })}
        </ul>
      </div>
    );
  }


  let userFilesSelectControlBody;
 
  userFilesSelectControlBody = (
    <div>
      {/* <div>uploaded-name: {oriFileName}</div> */}
      {usingAdsBody}
      <div className={classes.userFilesSelectDeleteButton}>
        <Button
          design="raised"
          type="submit"
          size="smaller"
          disabled={isLoading}
          onClick={() => {
            setShowDeleteModal(true);
            setDeleteError('');
          }}
        >
          Delete File
        </Button>
      </div>
    </div>
  );

  return (
    <Fragment>
      {showDeleteModal && (
        <div>
          {/* <Backdrop 
            onClick={() => { 
              if (!isLoading) {
                setShowDeleteModal(false);
              } 
            }} 
          /> */}
          <SmallModal style="confirmModal">
            {deleteModalBody}
          </SmallModal>
        </div>
      )}
      <div>{userFilesSelectControlBody}</div>
    </Fragment>
  );
};

export default withI18n()(UserFilesSelectControl);
