// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Generic from './Generic.jsx';
import { useTranslation } from "react-i18next/hooks";
import Img from "react-cool-img";

import Button from "../../Button/Button";
// import loader from "../Loader/Loader";

import UserAdElementsAdStatus from "./UserAdElementsAdStatus";

import { useStore } from "../../../hook-store/store";

import { createDisplayId } from "../../../util/ad-visit";
import { isImageFile, isVideoFile } from "../../../util/image";
// import { updateUserPaused } from "../../../util/ad-element";

// import { BASE_URL } from "../../../App";

import classes from './UserAdElementsItem.module.css';

const UserAdElementsItem = (props) => {
  // console.log('UserAdElementsItem-props', props);
  const { startEditAdHandler, ad } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { validDefaultMethod } = store.paymentStore;

  // const [isLoading, setIsLoading] = useState(false);


  const adPath = `/ad-element/${ad.adElementId}`;

  let imagesBody;
  let fileType;

  if (ad && ad.adImagePath) {
    fileType = ad.adImagePath.split('.')[ad.adImagePath.split('.').length - 1].toLowerCase();
  } 


  if (fileType && isImageFile(fileType)) {
    imagesBody = (
      <div>
        {/* <div style={{ color: "red" }}>{ad.adElementId}</div> */}
        <Img className={classes.adElementItemImage} 
          src={ad.adImageUrl} alt="ad-img" 
        />
        {ad.ad150x500ImageUrl && (
          <Img className={classes.adElementItemImage150x500} 
            src={ad.ad150x500ImageUrl} alt="ad-150x500" 
          />
        )}
        {ad.ad600x130ImageUrl && (
          <Img className={classes.adElementItemImage600x130} 
            src={ad.ad600x130ImageUrl} alt="ad-600x130" 
          />
        )}
      </div>
    );
  }

  if (fileType && isVideoFile(fileType)) {
    imagesBody = (
      <video className={classes.adElementItemVideo}
        src={ad.adImageUrl} alt="ad video"
      />
    );
  }
  

  let userAdElementsItemBody;

  userAdElementsItemBody = (
    <div className={classes.adElementItemContainer}
      key={ad.adElementId}
    >
      <Link to={adPath}>
        {imagesBody}
      </Link>
        <div className={classes.adElementItemTitle}>{ad.title}</div>
        <div>
          {t('general.text22', 'Start time')}: {new Date(ad.start).toLocaleString()}
        </div>
        <div>
          {t('general.text23', 'End time')}: {new Date(ad.end).toLocaleString()}
        </div>
        <div>
          {t('general.text24', 'Creation time')}: {new Date(ad.createdAt).toLocaleString()}
        </div>
        <div>ID: {createDisplayId(ad.adElementId)}</div>


      <UserAdElementsAdStatus 
        ad={ad}
      />
      
      {/* <div>
      <button
        onClick={() => { 
          deleteAdElement(BASE_URL, localStorage.getItem('token'), ad.adElementId);
        }}
      >
        delete-ad
      </button>
    </div> */}

      <div className={classes.adElementItemButtons}>
        <Link to={adPath}>
          <Button design="raised" type="submit" 
            onClick={() => {
              startEditAdHandler(ad.adElementId);
            }}
          > 
            {t('adList.text10', 'Detail')}
          </Button>
        </Link>
        
        {ad && ad.end > Date.now() && (
          <Button design="raised" type="submit"
            disabled={!validDefaultMethod} 
            onClick={() => {
              startEditAdHandler(ad.adElementId);
            }}
          >
            {t('general.text5', 'Edit')}
          </Button>
        )}
      </div>

    </div>
  );

  return <Fragment>{userAdElementsItemBody}</Fragment>;
};

export default UserAdElementsItem;
