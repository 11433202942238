// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
// import Generic from './Generic.jsx';

import { useTranslation } from "react-i18next/hooks";

import CreateResults from "./CreateResults";
import Loader from "../../components/Loader/Loader";
import ReactTableDoc from "./ReactTableDoc";
import Pagination from "./Pagination";
import { useStore } from "../../hook-store/store";

import classes from './AdReport.module.css';

// import { BASE_URL } from "../../App";

const AdReport = (props) => {
  const {  } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const listForTable = store.adReportStore.listForTable;
  // console.log('store in UserAdElements.js', store);

  // const [isLoading, setIsLoading] = useState(false);

  return (
    <Fragment>
      <div className={classes.adReport}>
        <div className={classes.adReportTitle}>
          {t('adReport.text01', 'Ad Report')}
        </div>

        {listForTable.length > 0 && (
          // <ReactTableDoc userAdResults={tfAdResults} />
          <Pagination />
        )}

      <CreateResults />
      </div>

    </Fragment>
    );
};
export default AdReport;
