import React, { Fragment, useEffect, useState } from "react";
// import Link from "react-router-dom/Link";
import { withI18n } from "react-i18next";
import Img from "react-cool-img";

import Loader from "../Loader/Loader";
import UserFilesSelectControl from "./UserFilesSelectControl";

import { useStore } from "../../hook-store/store";

import { isVideoFile, isImageFile } from "../../util/image";
import { createDisplayId } from "../../util/ad-visit";

import { BASE_URL } from "../../App";

import { marks } from '../../images/icon/marks';
// import "./FeedEdit.css";
import classes from './UserFilesSelect.module.css';


const UserFilesSelect = (props) => {
  // console.log('UserFilesSelect.js-props', props);
  
  const { 
    t,
    showSelectedModal,
    setShowSelectedModal,
    // selectedUrls
  } = props;


  const [store, dispatch] = useStore();
  const userAdElements = store.userAdElements;
  const adImageUrls = store.userFilesStore.adImageUrls;
  const ad150x500ImageUrls = store.userFilesStore.ad150x500ImageUrls;
  const ad600x130ImageUrls = store.userFilesStore.ad600x130ImageUrls;
  const selectedUrls = store.userFilesStore.selectedUrls;
  const userAllImageUrls = store.userFilesStore.userAllImageUrls;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteError, setDeleteError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

  },[]);

  const deleteAdImageOfPath = async (url, token, adElementIds, path, imageType) => {
    try {
      setIsLoading(true);

      console.log(adElementIds, path, imageType);
      // throw new Error('error-error');

      const result = await fetch(url + 
        `/ad-element-image/delete-ad-image-of-path?adElementIds=${JSON.stringify(adElementIds)}&path=${path}&imageType=${imageType}`, {
          method: 'DELETE',
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
        });

      const resData = await result.json();

      console.log(resData, result);

      if (!result.ok) {
        throw new Error('error occured');
      }

      setDeletedListsHandler(path);
      setShowDeleteModal(false);
      setIsLoading(false);

      dispatch('SHOW_NOTIFICATION', {
        message: 'file deleted',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      }, 1000* 5);

      return resData;

    } catch(err) {
      console.log(err);

      setDeleteError('Delete file failed');
      setIsLoading(false);
      throw err;
    }
  };

  const setDeletedListsHandler = (path) => {

    const deletedAdUrls = adImageUrls.filter(ele => {
      return ele.adImagePath !== path;
    });

    const deletedAd150x500Urls = ad150x500ImageUrls.filter(ele => {
      return ele.ad150x500ImagePath !== path;
    });

    const deletedAd600x130Urls = ad600x130ImageUrls.filter(ele => {
      return ele.ad600x130ImagePath !== path;
    });

    const deletedSelectedUrls = selectedUrls.filter(ele => {
      return ele.path !== path;
    });

    const deletedUserAllImageUrsl = {
      ad150x500ImageUrls: userAllImageUrls.ad150x500ImageUrls.filter(url => {
        return url.imagePath !== path;
      }),
      ad600x130ImageUrls: userAllImageUrls.ad600x130ImageUrls.filter(url => {
        return url.imagePath !== path;
      }),
      adImageUrls: userAllImageUrls.adImageUrls.filter(url => {
        return url.imagePath !== path;
      }),
    }

    dispatch('SET_ADIMAGEURLS', deletedAdUrls);
    dispatch('SET_AD150X500IMAGEURLS', deletedAd150x500Urls);
    dispatch('SET_AD600X130IMAGEURLS', deletedAd600x130Urls);
    dispatch('SET_SELECTEDURLS', deletedSelectedUrls);
    dispatch('SET_USERALLIMAGEURLS', deletedUserAllImageUrsl);
  };


  let userFilesSelectBody;

  if (selectedUrls && selectedUrls.length === 0) {
    userFilesSelectBody = (
      <div>file not selected</div>
    );
  }

  if (selectedUrls && selectedUrls.length > 0) {
    userFilesSelectBody = (
      <ul className={classes.userFilesList}>
      {selectedUrls.map((url) => {
        // console.log(url)

        const fileName = url.path.split('/')[url.path.split('/').length -1];
        const fileType = fileName.split('.').pop();
        const timeEndIndex = fileName.indexOf('Z');
        const oriFileName = fileName.substring(timeEndIndex + 2);

        const adElementIds = [];

        for (const usingAd of url.usingAds) {
          adElementIds.push(usingAd.adElementId);
        };


        let imageType = 'adImageUrl';
        let imageStyle = imageStyle = {
          height: '150px',
          width: '150px',
        };

        if (url.imageData.ad150x500ImageUrl) {
          imageType = 'ad150x500ImageUrl'
          imageStyle = {
            height: '150px',
          }
        }

        if (url.imageData.ad600x130ImageUrl) {
          imageType = 'ad600x130ImageUrl'
          imageStyle = {
            width: '150px',
          }
        }


        const userFileSelectControlBody = (
          <UserFilesSelectControl 
          adElementIds={adElementIds}
          url={url}
          imageType={imageType}
          deleteAdImageOfPath={deleteAdImageOfPath}
          oriFileName={oriFileName}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deleteError={deleteError}
          setDeleteError={setDeleteError}
          isLoading={isLoading}
        />
        );
        
        
        if (isImageFile(fileType)) {
          return (
            <div key={url.path}
              // title={createDisplayId(url.imageData.adElementId)}
            >
              <div className={classes.userFilesSelectAdImageContainer}>
                <div className={classes.userFilesSelectAdImageDiv}>
                  <Img
                    style={imageStyle}
                    // style={{height:"100px", width:"100px", marginRight: "20px", cursor:"pointer"}}
                    className={classes.userFilesSelectAdImage} 
                    onClick={() => { 
                      // checkReused(url, url.adImagePath, userAdElements) 
                    }}
                    src={url.url}
                  />
                </div>
                <div>
                  {userFileSelectControlBody}
                </div>
              </div>
            </div>
          )
        }
        if (isVideoFile(fileType)) {
          return (
            <div key={url.path}
              className={classes.userFilesSelectAdImageContainer}
            >
              <div>
                <div
                  // title={createDisplayId(url.imageData.adElementId)}
                  className={classes.userFilesSelectVideoContainer}
                >
                  <video
                    style={{width:"150px", height:"150px"}}
                    // className={classes.userFilesVideo} 
                    onClick={() => { 
                      // checkReused(url, url.adImagePath, userAdElements) 
                    }}
                    src={url.url}
                  />
                  <span className={classes.userFilesSelectVideoMark}>
                    {/* {marks.triangleDown} */}
                    &#9654;
                  </span>
                </div>
              </div>
              <div>
                {userFileSelectControlBody}
              </div>
            </div>
          )
        }
      })}
    </ul>
    )
  }

 

  return (
    <Fragment>
      {isLoading && (<Loader />)}
      {!isLoading && (
        <div>
          <div className={classes.userFilesSelectClose}>
            <span className={classes.userFilesSelectCloseButton}
              onClick={() => { 
                setShowSelectedModal(false); 
              }}
            >
              X
            </span>
          </div>

          <div>Selected Files</div>
          
          <button 
            onClick={() => {
              dispatch('SET_SELECTEDURLS', []);
              setShowSelectedModal(false);
            }}
          >
            Unselect all
          </button>


          <div>{userFilesSelectBody}</div>
        </div>
      )}
    </Fragment>
  );
};

export default withI18n()(UserFilesSelect);
