import { initStore } from "./store";

const configureStore = () => {
  const actions = {

    SET_USER_ADRESULTS:(state, payload) => {
      return {
        ...state,
        adReportStore: {
          ...state.adReportStore,
          userAdResults: payload,
        }
      };
    },
    SET_LIST_FORTABLE:(state, payload) => {
      return {
        ...state,
        adReportStore: {
          ...state.adReportStore,
          listForTable: payload,
        }
      };
    },
    SET_SELECTED_ADELEMENTID:(state, payload) => {
      return {
        ...state,
        adReportStore: {
          ...state.adReportStore,
          selectedAdElementId: payload,
        }
      };
    },
    SET_SELECTED_ADELEMENTIDLIST:(state, payload) => {
      return {
        ...state,
        adReportStore: {
          ...state.adReportStore,
          selectedAdElementId: payload,
        }
      };
    },
    SET_DURATION:(state, payload) => {
      return {
        ...state,
        adReportStore: {
          ...state.adReportStore,
          duration: payload,
        }
      };
    },
    SET_TOTAL_RESULT:(state, payload) => {
      return {
        ...state,
        adReportStore: {
          ...state.adReportStore,
          totalResult: payload,
        }
      };
    },
  };

  initStore(actions, {
    adReportStore: { 
      userAdResults: [],
      listForTable: [],
      selectedAdElementId: '',
      selectedAdElementIdList: [],
      duration: 10,
      totalResult: {
        visitNum: 0,
        displayNum: 0,
        charge: 0,
      },
    }
  });
};

export default configureStore;
