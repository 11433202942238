// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
// import Generic from './Generic.jsx';

import { useTranslation } from "react-i18next/hooks";

// import AdminSearchAd from "../../components/SearchAd/AdminSearchAd";
// import AdminAdElementsItem from "../../components/AdminAdElements/AdminAdElementsItem";

import AdminAccountStatus from "../../components/AdminAccountStatus/AdminAccountStatus";
import AdminSearchUser from './AdminSearchUser';
import Loader from "../../components/Loader/Loader";
import Paginator from "../../components/Paginator/Paginator";
// import UserAdElementsItem from "../../components/UserAdElements/UserAdElementsItem";

import { useStore } from "../../hook-store/store";
import { getUserDataForStore } from "../../util/user";
import { getUserAdElements } from "../../util/ad-visit";
import { getAdElementsForAdmin, isAdminUser } from "../../util/admin-ad-element";

import { BASE_URL } from "../../App";

import classes from './AdminAdElements.module.css';

const AdminTopPage = (props) => {
  console.log('AdminTopPage.js-props', props);
  const { isAuth, startEditAdHandler, history } = props;

  const perPagePost = 20;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const usersForAdmin = store.adminStore.usersForAdmin;
  const adminSelectedUser = store.adminStore.adminSelectedUser;

  // console.log('store in AdminTopPage.js', store);

  const [isLoading, setIsLoading] = useState(false);
  const [suggestId, setSuggestId] = useState('');
  const [suggest, setSuggest] = useState();
  const [pageNum, setPageNum] = useState(1);

  const [showUpdateAccountStatus, setShowUpdateAccountStatus] = useState(false);
  const [userIdForUpdate, setUserIdForUpdate] = useState('');

  useEffect(() => {
    if (isAuth && isAdminUser(store.userData) && usersForAdmin.length === 0) {
      getUsersForAdmin();
    }
  },[isAuth, store.userData]);


  const getUsersForAdmin = async () => {
    // console.log('in getUsersForAdmin');
    try {
      const result = await fetch(BASE_URL + '/admin-user/users', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
      });

      const resData = await result.json();
      // console.log(resData);
      if (resData.data && resData.data.length > 0) {
        dispatch('SET_USERS_FORADMIN', resData.data);
      }
    } catch(err) { 
      console.log(err); 
    }
  }

  const selectUserHandler = (userObj) => {
    dispatch('SET_ADMIN_SELECTEDUSER', userObj);
    
    if (userObj) {
      history.push('/admin/ad-elements');
    }
  };

  const setSuggestIdHandler = (adElementId) => {
    // console.log(adElementId);
    setSuggestId(adElementId);
  };

  const setSuggestHandler = (suggest) => {
    // console.log(suggest);
    setSuggest(suggest);
  }

  let adminUserListBody;

  if (usersForAdmin.length > 0) {
    adminUserListBody = (
      <div>
        {adminSelectedUser && (
          <div>selected-userId: {adminSelectedUser.userId}</div>
        )}
        <button onClick={() => {selectUserHandler(null); }}>
          reset-user-select
        </button>
        <ul>
          {usersForAdmin.map(user => {
            return (
              <div key={user.userId}>
                <div>userId: {user.userId}</div>
                <div>email: {user.email}</div>
                <div>accountStatus: {user.accountStatus}</div>
                <button onClick={() => {selectUserHandler(user); }}>user's ads</button>
                
                <button 
                  onClick={() => { 
                    setShowUpdateAccountStatus(!showUpdateAccountStatus); 
                    setUserIdForUpdate(user.userId);
                  }}
                >
                  update-account-status
                </button>

                {showUpdateAccountStatus && userIdForUpdate === user.userId && (
                  <AdminAccountStatus 
                    userId={user.userId}
                  />
                )}
                
                <hr/>
              </div>
            )
          })}
        </ul>
      </div>
    );
  }


  let adminTopPageBody;

  adminTopPageBody = (
    <div>
      admin-top-page-contents
      <div>
        search-users, select-user-ads, 
      </div>
      <div>
        <div>users-list</div>
        <AdminSearchUser
          getSelectedSuggestId={setSuggestIdHandler}
          getSelectedSuggest={setSuggestHandler}
        />
        {suggest && (
          <div>
            <div>
              suggest
            </div>
            <div>
              suggest-userId: {suggest.userId}
            </div>
            <div>
              suggest-email: {suggest.email}
            </div>
            <button onClick={() => {selectUserHandler(suggest); }}>suggest user's ads</button>
            <hr/>
          </div>
        )}
        <div>{adminUserListBody}</div>
      </div>
    </div>
  );
 
  return (
    <Fragment>
      {isLoading && (
        <div className="feed__loader"><Loader /></div>
      )}
      {isAdminUser(store.userData) && (
        <div>
          <div>admin-top-page</div>
          <div>
          <Link to="/admin/ad-elements">admin-ad-elements-page</Link>
        </div>
          {adminTopPageBody}
        </div>
      )}
    </Fragment>
  );
};

export default AdminTopPage;
