import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next/hooks';
import { authSignupPageLink, authPageLink } from '../../../App';
import './NavigationItems.css';

// const navItems = [
//   { id: 'feed', text: t('test.text1'), link: '/feed/posts', auth: true },
//   { id: 'login', text: 'Login', link: '/login', auth: false },
//   { id: 'signup', text: 'Signup', link: '/signup', auth: false },
//   { id: 'userinfo', text: 'User Info', link: '/userinfo', auth: true },
//   // { id: 'othernav', text: 'othernav', link: '/feed/posts', auth: true },
//   // { id: 'othernav2', text: 'othernav2', link: '/feed/posts', auth: true },
// ];

const navigationItems = props => {

  const [t] = useTranslation('translation');
  
  const navItems = [
    { id: 'create-ad', 
      // text: 'Ad List',
      text: t('nav.text8', 'Ad List'), 
      link: '/create-ad-element', auth: true },
    // { id: 'login', 
    //   // text: 'Login', 
    //   text: t('nav.text2'),
    //   link: '/login', auth: false },
    // { id: 'signup', 
    //   // text: 'Signup', 
    //   text: t('nav.text3'),
    //   link: '/signup', auth: false },
    { id: 'userinfo', 
      // text: 'User Info', 
      text: t('nav.text4'),
      link: '/userinfo', auth: true 
    },
    { id: 'payment', 
      // text: 'Payment', 
      text: t('nav.text9', 'Payment'),
      link: '/payment', auth: true 
    },
    { id: 'report', 
      // text: 'Ad Report', 
      text: t('nav.text10', 'Ad Report'),
      link: '/report', auth: true 
    },
    { id: 'files', 
      text: 'Ad Files', 
      // text: t('nav.text10', 'Ad Report'),
      link: '/user-files', auth: true 
    },
    // { id: 'ad-elements', 
    //   text: 'ad-elements-page', 
    //   // text: t('nav.text4'),
    //   link: '/ad-elements', auth: true },
    { id: 'home', 
      // text: 'Home Page',
      text: t('nav.text11', 'Home Page'), 
      link: '/', auth: false
    },
    { id: 'login-auth-sso', 
      // text: 'login', 
      text: t('nav.text2'),
      link: `${authPageLink}`, 
      auth: false 
    },
    { id: 'signup-auth-sso', 
      // text: 'signup', 
      text: t('nav.text3'),
      link: `${authSignupPageLink}`, 
      auth: false 
    },

    // { id: 'othernav', text: 'othernav', link: '/feed/posts', auth: true },
    // { id: 'othernav2', text: 'othernav2', link: '/feed/posts', auth: true },
  ];

  const logoutConfirm = () => {
    if (window.confirm("Is it no problem to logout?")) {
      // console.log('confirm true');
      props.onLogout()
    }
  }
  
  return (
    [
    ...navItems
      .filter(item => item.auth === props.isAuth)
      .map(item => {
        if (item.id === 'login-auth-sso' || item.id === 'signup-auth-sso') {
          return (
            <li key={item.id} className={['navigation-item', props.mobile ? 'mobile' : ''].join(' ')}>
              <a href={item.link}>{item.text}</a>
            </li>
          )
        } else {
          return (
            <li key={item.id} className={['navigation-item', props.mobile ? 'mobile' : ''].join(' ')}>
              <NavLink to={item.link} exact onClick={props.onChoose}>
                {item.text}
              </NavLink>
            </li>
          )
        }
    }),
    props.isAuth && (
        <li className={['navigation-item', props.mobile ? 'mobile' : ''].join(' ')} key="logout">
          <button onClick={logoutConfirm}>
            {/* Logout */}
            {t('nav.text5')}
          </button>
        </li>
    )
  ]
  );

}

export default navigationItems;
