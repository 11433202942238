// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
// import Generic from './Generic.jsx';

import { useTranslation } from "react-i18next/hooks";
import { DateTime } from "luxon";

import Loader from "../../components/Loader/Loader";
import { useStore } from "../../hook-store/store";

import { BASE_URL } from "../../App";

const CreateResults = (props) => {
  const {  } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const userAdResults = store.adReportStore.userAdResults;
  const selectedAdElementId = store.adReportStore.selectedAdElementId;
  const storeDuration = store.adReportStore.duration;
  // console.log('store in CreateResults.js', store);

  // const [isLoading, setIsLoading] = useState(false);

  // const [userAdResults, setUserAdResults] = useState([]);
  const [tfAdResults, setTfAdResults] = useState([]);

  useEffect(() => {
    getUserAdResults(
      BASE_URL, 
      localStorage.getItem('token'), 
      localStorage.getItem('userId')
    );

  },[]);

  useEffect(() => {

    if (userAdResults.length > 0) {

      createListForTable(selectedAdElementId, storeDuration);

      // const oriStart = userAdResults[0].adElement.oriStart;
      // const oriDate = new Date(oriStart).toISOString();

      // const date1 = DateTime.fromISO("2020-09-10T12:00")
      // const date2 = DateTime.fromISO("2020-09-04T14:00")

      // const dateOriStart = DateTime.fromISO(oriDate);
      // const dateNow = DateTime.fromISO(new Date().toISOString());

      // const diff = dateNow.diff(dateOriStart, ["years", "months", "days", "hours"])
      // const diff2 = dateNow.diff(dateOriStart, ["days"])
  
      // console.log('luxon diff', diff.toObject(), diff2.toObject(), Math.round(diff2.toObject().days + 7), oriDate);
    }

  },[userAdResults, selectedAdElementId, storeDuration]);


const createListForTable = (adElementId, duration) => {

    const dayArray = createDayArray(adElementId, duration, userAdResults);

    // let adResult = userAdResults[0];
    // console.log(transFormedArray);

    let totalDisplay = 0;
    let totalVisit = 0;
    let totalCharge = 0;

    if (!adElementId || adElementId === 'all') {
      const daySumArray = createDaySumArray(dayArray, userAdResults);
      // console.log('daySumArray', daySumArray);
      
      const decimalList = [];
      for (const dayResult of daySumArray) {
        decimalList.push({
          ...dayResult,
          charge: Number(dayResult.charge.toFixed(2)),
          vRate: Number(dayResult.vRate.toFixed(1))
        });
      }

      dispatch('SET_LIST_FORTABLE', decimalList);

      for (const dayResult of daySumArray) {
        totalDisplay = totalDisplay + dayResult.displayNum;
        totalVisit = totalVisit + dayResult.visitNum;
        totalCharge = totalCharge + dayResult.charge;
      }

      // console.log('luxon total', totalDisplay, totalVisit, totalCharge);
      dispatch('SET_TOTAL_RESULT', { 
        visitNum: totalVisit,
        displayNum: totalDisplay,
        charge: totalCharge,
      });

    } else {

      const adResult = userAdResults.filter(result => {
          return result.adElementId === adElementId;
        })[0];
      // console.log(userAdResults, adResult);

      const adVisitDataList = adResult.dayVisit
      // console.log('luxon array',dayArray, adVisitDataList, userAdResults[0]);
      // console.log(userAdResults);

      const transFormedArray = createTransFormedArray(dayArray, adVisitDataList, adResult.adElementId);
      
      const decimalList = [];
      
      for (const dayResult of transFormedArray) {
        decimalList.push({
          ...dayResult,
          charge: Number(dayResult.charge.toFixed(2)),
          vRate: Number(dayResult.vRate.toFixed(1))
        });
      }

      dispatch('SET_LIST_FORTABLE', decimalList);

      for (const dayResult of transFormedArray) {
        totalDisplay = totalDisplay + dayResult.displayNum;
        totalVisit = totalVisit + dayResult.visitNum;
        totalCharge = totalCharge + dayResult.charge;
      }

      // console.log('luxon total', totalDisplay, totalVisit, totalCharge);
      dispatch('SET_TOTAL_RESULT', { 
        visitNum: totalVisit,
        displayNum: totalDisplay,
        charge: totalCharge,
      });
    }
  };



  const createDayArray = (adElementId, duration, userAdResults) => {

    const adElId = adElementId && adElementId !== 'all' ? adElementId : '';
    
    const nowDateElements = new Date().toISOString().split('T')[0].split('-');
    const nowYear = parseInt(nowDateElements[0]);
    const nowMonth = parseInt(nowDateElements[1]);
    const nowDay = parseInt(nowDateElements[2]);
    const dayArray = [];

    let dayDuration = 20;

    if (duration) {
      dayDuration = duration;
    }

    if (adElId && duration === 0) {
      const userAdResult = userAdResults.find(result => {
        return result.adElementId === adElId;
      });

      console.log('luxon', adElId, duration, userAdResult);

      if (userAdResult) {
        const oriStart = userAdResult.adElement.oriStart;
        const oriDate = new Date(oriStart).toISOString();
  
        const dateOriStart = DateTime.fromISO(oriDate);
        const dateNow = DateTime.fromISO(new Date().toISOString());
  
        const diff = dateNow.diff(dateOriStart, ["years", "months", "days", "hours"])
        const diff2 = dateNow.diff(dateOriStart, ["days"])
        
        dayDuration = Math.round(diff2.toObject().days + 5);

        console.log('luxo oriDate, nowDate', new Date(oriStart).toISOString(), new Date().toISOString());
        console.log('luxon diff', diff.toObject(), diff2.toObject(), Math.round(diff2.toObject().days + 7), oriDate);
        console.log('luxon diff dayDuration', dayDuration);
      
      }
    }



    for (let i=0; i<dayDuration; i++) {
      const add = DateTime.local(nowYear, nowMonth, nowDay).plus({ days: -i }).toISODate();
      dayArray.push(add);
    }

    return dayArray;
  };

  const createTransFormedArray = (dayArray, adVisitDataList, adElementId) => {
    const transFormedArray = [];
    
    // console.log('adVisitDataList', adVisitDataList);

    for (const day of dayArray) {
      const dayVisitData = adVisitDataList.find(visitData => {
        return visitData.date === day;
      });
      // console.log(dayVisitData);

      if (dayVisitData) {

        let vRate = 0;
        if (dayVisitData.visitNum > 0 && dayVisitData.displayNum > 0) {
          const pctNum = dayVisitData.visitNum / dayVisitData.displayNum * 100
          vRate = Number(pctNum.toFixed(1));
        }

        transFormedArray.push({
          date: day,
          visitNum: dayVisitData.visitNum || 0,
          displayNum: dayVisitData.displayNum || 0,
          vRate: vRate,
          charge: dayVisitData.charge || 0,
          // adElementId: adElementId,
          // // adElementIdNum: 1,
          // adElementIdList: [adElementId],
        });
      } else {
        transFormedArray.push({
          date: day,
          visitNum: 0,
          displayNum: 0,
          vRate: 0,
          charge: 0,
          // adElementId: adElementId,
          // // adElementIdNum: 1,
          // adElementIdList: [adElementId],
        });
      }
    }

    console.log('transFormedArray', transFormedArray);

    return transFormedArray;

  };


  const createDaySumArray = (dayArray, userAdResults) => {
    const combinedArray = [];
    const sumArray = [];

    // const adElementIdList = [];

    // for (const userAdResult of userAdResults) {
    //   adElementIdList.push(userAdResult.adElementId);
    // }

    for (const userAdResult of userAdResults) {
      const list = createTransFormedArray(dayArray, userAdResult.dayVisit, userAdResult.adElementId);
      combinedArray.push(list);
    }
    console.log('combinedArray', combinedArray);


    for (let i=0; i<dayArray.length; i++) {
      
      let totalVisit = 0;
      let totalDisplay = 0;
      let totalCharge = 0;

      for (let j=0; j<combinedArray.length; j++) {
        // console.log(combinedArray[j][i]);
        totalVisit = totalVisit + combinedArray[j][i].visitNum;
        totalDisplay = totalDisplay + combinedArray[j][i].displayNum;
        totalCharge = totalCharge + combinedArray[j][i].charge;
      }
      // console.log(totalVisit, totalDisplay);
      
      let vRate = 0;

      if (totalVisit > 0 && totalDisplay > 0) {
        const pctNum = totalVisit / totalDisplay * 100
        vRate = Number(pctNum.toFixed(1));
      }

      sumArray.push({
        date: dayArray[i],
        visitNum: totalVisit,
        displayNum: totalDisplay,
        vRate: vRate,
        charge: totalCharge,
        // // adElementIdNum: combinedArray.length,
        // adElementId: '',
        // adElementIdList: adElementIdList,
      });
    }

    // console.log(dayArray);
    console.log(sumArray);

    return sumArray;
  };




  const getUserAdResults = async (url, token, userId) => {
    try {
      const response = await fetch(url + `/ad-result/user-ad-results`, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
      });
      console.log('response', response);

      //// handle ok case
      if (response.ok) {
        const resData = await response.json();
        console.log('resData', resData);
        // setUserAdResults(resData.userAdResults);
        dispatch('SET_USER_ADRESULTS', resData.userAdResults);
      } 

      //// handle not ok case
      else {

        // //// handle not ok and status code specific case
        // if (accessResponse.status === 400) {
        //   console.log('status 400');
        //   const errorData = await response.json();
        //   console.log('errorData', errorData);
        // }

        console.log('fetching data failed, error response returned');
        const errorData = await response.json();
        console.log('errorData', errorData);
      }
    } catch(err) {
      console.log(err);
    }
  };


  return <Fragment></Fragment>;
};
export default CreateResults;
