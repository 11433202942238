
export const getAdElementsForAdmin = async (url, token) => {
  try {
      const result = await fetch(url + '/admin-ad-element/ad-elements', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
      });

      const resData = await result.json();
      console.log(resData, result);
      return resData.data;
  } catch(err) {
    console.log(err);
  }
};

export const isAdminUser = (userData) => {
  if (userData && userData.status && userData.status === 'admin') {
    return true;
  } else {
    return false;
  }
};

export const adminUpdateAdStatus = async (url, token, adElementId, adStatus, adStatusDetail) => {
  try {
      const result = await fetch(url + '/admin-ad-element/update-ad-status', {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          adElementId: adElementId,
          adStatus: adStatus,
          reason: adStatusDetail.reason,
          comment: adStatusDetail.comment,
        }),
      });

      const resData = await result.json();
      console.log(resData, result);
      return resData.data;
  } catch(err) {
    console.log(err);
  }
};