import React from "react";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next/hooks';
import _ from 'lodash';

import { useStore } from "../../../../hook-store/store";

import { resources } from '../../../../translation/translation';

import classes from "./FeedEditLanguageList.module.css";

const FeedEditLanguageList = (props) => {
  // console.log('FeedEditLanguageList.js', props);

  const { postInputChangeHandler, setSelectedLngObj, showLanguageModalHandler } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();

  const languageNameList = store.languageNameList;

  useEffect(() => {
    if (store.languageNameList.length === 0) {
      const languageNameList = [];
  
      for (const element in resources) {
        // console.log(element, resources[element].translations.LANGUAGE);
        languageNameList.push({
          code: element,
          LANGUAGE: resources[element].translation.LANGUAGE
        });
      }
      // console.log('languageNameList', languageNameList);
      dispatch('SET_LANGUAGE_NAME_LIST', languageNameList);
      // setLanguageNameList(languageNameList);
    }
  },[]);

  // const languageNameList = [];

  // for (const element in resources) {
  //   // console.log(element, resources[element].translations.LANGUAGE);
  //   languageNameList.push({
  //     code: element,
  //     LANGUAGE: resources[element].translation.LANGUAGE
  //   });
  // }
  // console.log(languageNameList)

  const sortedLanguageList = _.sortBy(languageNameList, 'LANGUAGE') 
  const languageListBody = (
      <ul>
        {sortedLanguageList.map((ele, index) => {
            return (
            <div key={index} className={classes.listElement}>
              {/* {ele.code}  */}
              <span 
                onClick={() => {
                  postInputChangeHandler('language', ele.code);
                  setSelectedLngObj({
                    code: ele.code,
                    language: ele.LANGUAGE,
                  });
                  showLanguageModalHandler();
                }}
              >
                {ele.LANGUAGE}
              </span>
            </div>
            );
          })
        }
      </ul>

  );

  return (
    <div>
      {languageListBody}
    </div>

  );
}

export default FeedEditLanguageList