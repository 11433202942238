import React, { Fragment, useState } from "react";
import { withI18n } from "react-i18next";

import Input from "../../../Form/Input/Input";
import ImagePreviewContents from "./ImagePreviewContents";

import { isImageFile, isVideoFile } from "../../../../util/image";

import { marks } from "../../../../images/icon/marks";

import classes from './FeedEditImagePreview.module.css';
import "../FeedEdit.css";

const FeedEditImagePreview = (props) => {
  console.log("FeedEditImagePreview.js-props", props);
  const {
    t,
    state,
    selectedPost,

    postInputChangeHandler,
    updateImagePreviewHandler,
  } = props;

  const [showImagePreview, setShowImagePreview] = useState(true);

  // let ad150x500ImageData = null;
  // let ad600x130ImageData = null;

  let ad150x500ImageData = selectedPost && selectedPost.ad150x500ImageUrl ? selectedPost.ad150x500ImageUrl : null;
  let ad600x130ImageData = selectedPost && selectedPost.ad600x130ImageUrl ? selectedPost.ad600x130ImageUrl : null;

  if (state.postForm.ad150x500ImageUrl.value && !state.postForm.ad150x500ImageUrl.value.name) {
    ad150x500ImageData = state.postForm.ad150x500ImageUrl.value;
  }

  if (!state.postForm.ad150x500ImageUrl.value) {
    ad150x500ImageData = null;
  }

  if (state.postForm.ad600x130ImageUrl.value && !state.postForm.ad600x130ImageUrl.value.name) {
    ad600x130ImageData = state.postForm.ad600x130ImageUrl.value
  }

  if (!state.postForm.ad600x130ImageUrl.value) {
    ad600x130ImageData = null;
  }

  
  let imagePreviewBody;

  imagePreviewBody = (
    <div>
      <ImagePreviewContents 
        state={state} 
        selectedPost={selectedPost} 
        imageUrl={state.imagePreview}

        // ad150x500ImageUrl={state.postForm.ad150x500ImageUrl.value}
        // ad600x130ImageUrl={state.postForm.ad600x130ImageUrl.value}
        ad150x500ImageUrl={ad150x500ImageData}
        ad600x130ImageUrl={ad600x130ImageData}
        postInputChangeHandler={postInputChangeHandler}
        updateImagePreviewHandler={updateImagePreviewHandler}
      />
    </div>
  );
  // console.log(this.state.imagePreview);

  if (state.imagePreview) {
    if (state.imagePreview.split("/")[0] === "data:image") {
      imagePreviewBody = (
        <div>
          <ImagePreviewContents 
            state={state} 
            selectedPost={selectedPost} 
            imageUrl={state.imagePreview}

            // ad150x500ImageUrl={state.postForm.ad150x500ImageUrl.value}
            // ad600x130ImageUrl={state.postForm.ad600x130ImageUrl.value}
            ad150x500ImageUrl={ad150x500ImageData}
            ad600x130ImageUrl={ad600x130ImageData}
            postInputChangeHandler={postInputChangeHandler}
            updateImagePreviewHandler={updateImagePreviewHandler}
          />
        </div>
      );
    }
    if (state.imagePreview.split("/")[0] === "data:video") {
      imagePreviewBody = (
        <video 
          className="videoAdPreview"
          // style={{ width:"400px", maxWidth:"100%"}}
          src={state.imagePreview} 
          // width="200" 
          controls
          />
      );
    }
  } else {
    let selectedImageType;
    if (selectedPost) {
      // console.log('this.props.selectedPost', this.props.selectedPost);

      // if (this.props.selectedPost.modifiedImageUrl) {
      //   // selectedImageType = this.props.selectedPost.modifiedImageUrl.split('.').pop();

      //   const imagePlace = this.props.selectedPost.modifiedImageUrl.split('?')[0];
      //   selectedImageType = imagePlace.split('.')[imagePlace.split('.').length - 1].toLowerCase();
      //   console.log(selectedImageType);
      // }

      if (selectedPost.adImagePath) {
        selectedImageType = selectedPost.adImagePath.split(".").pop();
      }

      // console.log('selectedImageType', selectedImageType);

      if (isImageFile(selectedImageType)) {
        imagePreviewBody = (
          <div>
            <ImagePreviewContents 
              state={state} 
              selectedPost={selectedPost} 
              imageUrl={selectedPost.adImageUrl}

              // ad150x500ImageUrl={state.postForm.ad150x500ImageUrl.value}
              // ad600x130ImageUrl={state.postForm.ad600x130ImageUrl.value}
              ad150x500ImageUrl={ad150x500ImageData}
              ad600x130ImageUrl={ad600x130ImageData}
              postInputChangeHandler={postInputChangeHandler}
              updateImagePreviewHandler={updateImagePreviewHandler}
            />
          </div>
        );
      }

      if (isVideoFile(selectedImageType)) {
        imagePreviewBody = (
          <div>
            <video 
              className="videoAdPreview"
              // style={{ width:"400px", maxWidth:"100%"}}
              src={selectedPost.adImageUrl} 
              // height="400" 
              controls
              />
          </div>
        );
      }
    }
  }

  return <Fragment>
    <div className={classes.showImagePreview}>
      {showImagePreview && (
        <span className={classes.showImagePreviewButton}
          onClick={() => { setShowImagePreview(false); }}
        > 
          {t('adForm.text23', 'Hide Ad Preview')} {marks.triangleDown}
        </span>
      )}

      {!showImagePreview && (
        <span className={classes.showImagePreviewButton}
          onClick={() => { setShowImagePreview(true); }}
        >
          {t('adForm.text24', 'Display Ad Preview')} {marks.triangleDown}
        </span>
      )}
    </div>

    {showImagePreview && (
      <div>
        {imagePreviewBody}
      </div>
    )}
    </Fragment>;
};

export default withI18n()(FeedEditImagePreview);
