// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next/hooks";

import { useStore } from "../../hook-store/store";

import { getUserDataForStore } from "../../util/user";
import { getUserAdElements } from "../../util/ad-visit";
import { nearToExpire } from "../../util/payment";

import Feed from "../Feed/Feed";

import { BASE_URL } from "../../App";

const CreateAdElements = (props) => {
  const { isAuth } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  // console.log("store in CreateAdElement.js", store);
  const { validDefaultMethod } = store.paymentStore;

  const [isAdCreateSuccess, setIsAdCreateSuccess] = useState(false);



  let isNearExpireCard = false;

  if (validDefaultMethod && 
    nearToExpire(
      validDefaultMethod.card.exp_month, 
      validDefaultMethod.card.exp_year
    )
  ) {
    isNearExpireCard = true;
  }

  useEffect(() => {
    if (isAuth) {
      if (store.userAdElements.length === 0) {
        getUserAdElements(BASE_URL, localStorage.getItem("token"))
          .then((result) => {
            console.log(result);
            dispatch("SET_USER_ADELEMENTS", result.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (!store.userData) {
        getUserDataForStore(BASE_URL, localStorage.getItem("token"))
          .then((result) => {
            console.log(result);

            dispatch("SET_USERDATA", result.userData);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [isAuth]);

  useEffect(() => {
    console.log("isAdCreateSuccess", isAdCreateSuccess);
    if (isAdCreateSuccess) {
      setIsAdCreateSuccess(false);

      
      // getUserAdElements(BASE_URL, localStorage.getItem("token"))
      //   .then((result) => {
      //     console.log(result);
      //     dispatch("SET_USER_ADELEMENTS", result.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    }
  }, [isAdCreateSuccess]);


  const getAdCreateResult = (createResult, adData, editPost) => {
    setIsAdCreateSuccess(createResult);

    if (!editPost) {
      dispatch("SET_USER_ADELEMENTS", [adData].concat(store.userAdElements));
    } else {
      const adElementList = store.userAdElements;
      const adIndex = adElementList.findIndex(ad => {
        return ad.adElementId === adData.adElementId;
      });

      adElementList[adIndex] = adData;
      
      dispatch("SET_USER_ADELEMENTS", adElementList);
    }
  };

  const deleteSelectedAdElementHandler = () => {
    dispatch("SET_SELECTED_ADELEMENT", null);
  };

  let createAdBody;
  if (!isAuth) {
    createAdBody = <div>auth required</div>;
  }
  if (isAuth) {
    createAdBody = <div></div>;
  }

  return (
    <Fragment>
      {/* {store.paymentStore.isStripeLoading && (
        <div>Stripe-Loading...</div>
      )} */}
      {isNearExpireCard && 'near-expire-card'}
      {createAdBody}
      <Feed
        {...props}
        userId={""}
        token={localStorage.getItem("token")}
        isAuth={isAuth}
        getAdCreateResult={getAdCreateResult}
        userAdElements={store.userAdElements}
        selectedAdElement={store.selectedAdElement}
        deleteSelectedAdElementHandler={deleteSelectedAdElementHandler}
        store={store}
        isNearExpireCard={isNearExpireCard}
      />
    </Fragment>
  );
};
export default CreateAdElements;
