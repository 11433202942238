import React from 'react';
import { useState } from 'react';
import { withI18n } from "react-i18next";
import { Link } from 'react-router-dom';
import Img from "react-cool-img";

import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import SmallModal from '../../Modal/SmallModal';
import TransBackdrop from '../../Backdrop/TransBackdrop';
import UserModalContents from '../../Modal/UserModalContents';
import { getUserLocation } from '../../../util/user';
import { isImageFile, isVideoFile } from '../../../util/image';
import './Post.css';

import { BASE_URL } from '../../../App';


const post = props => {
  // console.log('prop-Post.js', props);
  const { t } = props;
  // console.log(t);

  // const imagePlace = BASE_URL + '/' + props.image;
  const imagePlace = props.image.split('?')[0];
  const fileType = imagePlace.split('.')[imagePlace.split('.').length - 1].toLowerCase();
  const linkToPost = `/feed/${props.id}`;
  // console.log(imagePlace, fileType);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSmallModal, setShowSmallModal] = useState(false);

  const showDeleteModalHandler = () => {
    setShowDeleteModal(!showDeleteModal);

    getUserLocation()
      .then(result => {
        console.log(result);
        // const locationData = result.data;
      })
      .catch(err => {
        console.log(err);
        this.catchError(err);
      });

  };
  const hideDeleteModalHandler = () => {
    setShowDeleteModal(false);
  };

  const showSmallModalHandler = () => {
    setShowSmallModal(!showSmallModal);
  }

  let smallImage;

  if (isImageFile(fileType)) {
    smallImage = (
      <div>
        <Link to={linkToPost}>
          {/* <img src={BASE_URL + '/' + props.modifiedImageUrl} height="50" alt=""></img> */}
          {/* <img src={props.modifiedImageUrl} height="100" alt="post-img"></img> */}
          <Img src={props.modifiedImageUrl} height="100" alt="post-img" />
        </Link>
      </div>
    )
  }

  if (isVideoFile(fileType)) {
    smallImage = (
      <div>
        <Link to={linkToPost}>
          {/* <video src={BASE_URL + '/' + props.modifiedImageUrl} height="50" autoPlay muted={true}></video> */}
          <video src={props.modifiedImageUrl} height="50" autoPlay muted={true}></video>
        </Link>
      </div>
    )
  }

  const authorModalBody = (
    <div>
      <TransBackdrop onClick={showSmallModalHandler} />
      <SmallModal style="modal2">
        <UserModalContents
          {...props}
          postCreatorUserId={props.postCreatorUserId} 
          author={props.author}
          creatorImageUrl={props.creatorImageUrl}
          // setSelectedCreatorId={props.setSelectedCreatorId}
          // resetPostPage={props.resetPostPage}
          showSmallModalHandler={showSmallModalHandler}
        />
      </SmallModal>
    </div>
  );

  return (
    <article className="post">
      {smallImage}
      <header className="post__header">
        <Link to={linkToPost} className="post__title">{props.title}</Link>
      </header>
      <div className="post__contentContainer">
        <Link to={linkToPost} className="post__content">
          {props.content.length > 50 ? 
            props.content.slice(0,50) + '.....'
          : props.content
          }</Link>
      </div>
      <h3 className="post__meta" onClick={showSmallModalHandler}>
        {/* Posted by {props.author} on {props.date} {props.public === 'private' &&  props.postCreatorUserId === localStorage.getItem('userId') ? 'private' : null} */}
        {t('feed.text8')} {props.author} ({props.date}) {props.public === 'private' && props.postCreatorUserId === localStorage.getItem('userId') ? 'private' : null}
        {/* <img src={BASE_URL + '/' + props.creatorImageUrl} alt="" height="20"></img> */}
      </h3>
      {/* <video src={BASE_URL + '/' + props.image} height="50" ></video>
      <img src={BASE_URL + '/' + props.image} width="50" alt="videofile"></img> */}
      <div className="post__actions">
        <Button mode="flat" link={props.id} action="viewpost">
          {/* View  */}
          {t('feed.text4')}
        </Button>
        {props.postCreatorUserId === localStorage.getItem('userId') ?
          <span>
            <Button mode="flat" onClick={props.onStartEdit}>
              {/* Edit */}
              {t('feed.text5')}
            </Button>
            <Button mode="flat" design="danger" onClick={showDeleteModalHandler}>
              {/* Delete */}
              {t('feed.text6')}
            </Button>
          </span>
          : null
        }
      </div>
      {showDeleteModal ?
        <div>
          <Modal
            acceptEnabled={true}
            onCancelModal={hideDeleteModalHandler}
            onAcceptModal={props.onDelete}
            // isLoading={this.props.postsLoading}
            title="Delete Post"
          // style="confirmModal"
          >
            <div className="userImageUpload__confirmContent">
              {/* Is is no problem to delete post completely? */}
              {t('feed.text7')}
            </div>
          </Modal>
        </div>
        : null}

        {showSmallModal ? authorModalBody : null}

    </article>
  )
};

export default withI18n()(post);
// export default post;
