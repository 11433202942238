import React, { Fragment, useState, useEffect } from "react";
import { withI18n } from "react-i18next";

import Input from "../../../Form/Input/Input";
import FeedEditLanguageList from "./FeedEditLanguageList";
import SmallModal from "../../../Modal/SmallModal";
import TransBackdrop from "../../../Backdrop/TransBackdrop";

import { useStore } from "../../../../hook-store/store";

import { resources } from '../../../../translation/translation';

import classes from './FeedEditLanguageList.module.css';

import "../FeedEdit.css";

const FeedEditLanguageItems = (props) => {
  console.log('FeedEditLanguageItems.js-props', props);
  const {
    t,
    state,
    postForm,
    // previousPostForm,
    postInputChangeHandler,
    // inputBlurHandler,
    // sizeChangeHandler,
    // languageChangeHandler
    // selectedPost,
  } = props;

  const [store, dispatch] = useStore();

  const languageNameList = store.languageNameList;

  const [selectedLanguage, setSelectedLanguage] = useState();
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [selectedLngObj, setSelectedLngObj] = useState();
  // const [languageNameList, setLanguageNameList] = useState([]);

  useEffect(() => {
    // if (selectedLanguage) {
    //   postInputChangeHandler('language', selectedLanguage);
    // }
    console.log('state', state);
    if (postForm && languageNameList.length > 0) {
      console.log('postForm', postForm);

      if (postForm.language.value) {
        const lngObj = languageNameList.find(lng => {
          return lng.code === postForm.language.value;
        });

        if (lngObj) {
          console.log('lngObj', lngObj);
          setSelectedLngObj(lngObj);
        }
      } 

    }
  },[state, postForm, languageNameList]);

  useEffect(() => {
    if (store.languageNameList.length === 0) {
      const languageNameList = [];
  
      for (const element in resources) {
        // console.log(element, resources[element].translations.LANGUAGE);
        languageNameList.push({
          code: element,
          LANGUAGE: resources[element].translation.LANGUAGE
        });
      }
      // console.log('languageNameList', languageNameList);
      dispatch('SET_LANGUAGE_NAME_LIST', languageNameList);
      // setLanguageNameList(languageNameList);
    }
  },[]);


  const showLanguageModalHandler = () => {
    setShowLanguageModal(!showLanguageModal);
  };

  const languageModalBody = (
    <div>
    <TransBackdrop onClick={showLanguageModalHandler} />
    <SmallModal style="languageModal">
      <div className="userInfo__closeModalButton"
      onClick={showLanguageModalHandler}
      >
        X
      </div>
      <FeedEditLanguageList
        showLanguageModalHandler={showLanguageModalHandler}
        postInputChangeHandler={postInputChangeHandler}
        postForm={postForm}
        setSelectedLngObj={setSelectedLngObj}
        // setSelectedLanguage={setSelectedLanguage}
        // getLanguageNameHandler={getLanguageNameHandler}
        // i18n={props.i18n}
      />
    </SmallModal>
  </div>
  );

  const feedEditLanguageItemBody = (
    <div>
      <div className={classes.feedEditLanguageTitle}>
        {t('general.text28', 'LANGUAGE')}
      </div>
      <div className={classes.feedEditLanguageContents}>
        {/* <strong>{postForm && postForm.language.value}</strong>  */}
        <strong>{selectedLngObj && selectedLngObj.LANGUAGE}</strong>
        <strong className={classes.feedEditLanguageChangeButton}
          onClick={showLanguageModalHandler}
        >
          ({t('general.text40', 'Change')})
        </strong>
      </div>
    </div>
  );
  return (
    <Fragment>
      {showLanguageModal && (languageModalBody)}
      {feedEditLanguageItemBody}
    </Fragment>
  );
};

export default withI18n()(FeedEditLanguageItems);
