// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next/hooks";

import AdItems from "./AdItems";
import AdItems2 from "./AdItems2";
import Backdrop from "../../Backdrop/Backdrop";
import Button from "../../Button/Button";
import Loader from "../../Loader/Loader";
import SmallModal from "../../Modal/SmallModal";
import { useStore } from "../../../hook-store/store";

import { createDisplayId } from "../../../util/ad-visit";

import { BASE_URL } from "../../../App";

import classes from "./SingleAdElementContents.module.css";

const SingleAdElementContents = (props) => {
  // console.log("SingleAdElementContents-props", props);
  
  const { 
    ad, 
    deleteAdElementHandler, 
    selectAdElementHandler,
    history,
    isLoading
  } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  // console.log('store SingleAdElementContents.js', store);
  const { validDefaultMethod } = store.paymentStore;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // console.log('store in SingleAdElement.js', store);

  useEffect(() => {
    if (store.languageNameList.length === 0) {
      const languageNameList = [];
  
      for (const element in resources) {
        languageNameList.push({
          code: element,
          LANGUAGE: resources[element].translation.LANGUAGE
        });
      }
      dispatch('SET_LANGUAGE_NAME_LIST', languageNameList);
    }
  },[]);

  let displayLngObj;

  if (ad && store.languageNameList.length > 0) {
    displayLngObj = store.languageNameList.find(lng => {
      return lng.code === ad.targetLanguage;
    });
  }

  const showDeleteModalHandler = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  let singleAdElementContentsBody = (
    <div className={classes.singleAdElementContainer} key={ad.adElementId}>
      <div className={classes.singleAdElementContents}>
        ID: <strong>{createDisplayId(ad.adElementId)}</strong>
      </div>
      <div className={classes.singleAdElementContents}>
        {t('general.text25', 'Title')}: <strong>{ad.title}</strong>
      </div>
      <div className={classes.singleAdElementContents}>
        {t('general.text26', 'Description')}:
        <br />
        <strong>{ad.description}</strong>
      </div>
      <div>{/* start: {new Date(ad.start).toLocaleString()} */}</div>
      <div className={classes.singleAdElementContents}>
        URL: <strong>{ad.linkUrl}</strong>
      </div>
      <div className={classes.singleAdElementContents}>
        {t('general.text22', 'Start time')}: <strong>{new Date(ad.start).toLocaleString()}</strong>
      </div>
      <div className={classes.singleAdElementContents}>
        {t('general.text23', 'End time')}: <strong>{new Date(ad.end).toLocaleString()}</strong>
      </div>
      <div className={classes.singleAdElementContents}>
        {t('general.text27', 'Target Location')}:
        <br /> <strong>{ad.targetLocation.radiusKm}km</strong> ({t('general.text33', 'radius')}, ({t('general.text34', 'center')}:
          {t('general.text31', 'Latitude')}: {ad.targetLocation.latitude.toFixed(2)}, {t('general.text32', 'Longitude')}:{" "}
        {ad.targetLocation.longitude.toFixed(2)})
      </div>
      
      {displayLngObj && (
        <div className={classes.singleAdElementContents}>
          {t('general.text28', 'Language')}: <strong>{displayLngObj.LANGUAGE}</strong>
        </div>
      )}

      <div className={classes.singleAdElementContents}>
        {t('general.text29', 'Budget')} / {t('general.text35', 'day')}: <strong>$ {(ad.budget)}</strong>
      </div>
      <div className={classes.singleAdElementContents}>
        {t('general.text30', 'Last Update Time')}: <strong>{new Date(ad.updatedAt).toLocaleString()}</strong>
      </div>
      <div className={classes.singleAdElementContents}>
        {t('general.text24', 'Creation time')}:{" "}
        <strong>{new Date(ad.createdAt).toLocaleString()}</strong>
      </div>
      {ad.targetDevice && (
        <div className={classes.singleAdElementContents}>
          {t('adForm.text11', 'Target device')}:{" "}
          <strong>{ad.targetDevice}</strong>
        </div>
      )}
      <div className={classes.singleAdElementContents}>
        {/* <img src={ad.adImageUrl} alt="ad-img" height="100" /> */}
        {/* <AdItems ad={ad} /> */}
        <AdItems2 ad={ad} />
      </div>
      
      {ad.adStatus && ad.adStatus === 'not-approved' && (
        <div className={classes.singleAdElementContents}>
          <div>ad status: not approved</div>
          <div>{ad.adStatusDetail.reason}</div>
          <div>{ad.adStatusDetail.comment}</div>
        </div>
      )}
      
       
        <div className={classes.singleAdElementButtons}>
          <span className={classes.singleAdElementButton}>
            {/* <Link to='/create-ad-element'> */}
              <Button
                design="flat"
                type="submit"
                size="smaller"
                disabled={isLoading}
                loading={isLoading}
                onClick={() => { history.goBack(); }}
              >
                {t('contact.text11', 'Go Back')}
              </Button>
            {/* </Link> */}
          </span>
          <span className={classes.singleAdElementButton}>
            {ad && ad.end > Date.now() && 
              <Button
                design="raised"
                type="submit"
                size="smaller"
                disabled={isLoading || !validDefaultMethod}
                loading={isLoading}
                onClick={() => {
                  selectAdElementHandler(ad.adElementId);
                }}
              >
                {t('general.text5', 'Edit')}
              </Button>
            }
            
          </span>
          <span className={classes.singleAdElementButton}>
            <Button
              design="raised"
              type="submit"
              size="smaller"
              disabled={isLoading}
              loading={isLoading}
              onClick={showDeleteModalHandler}
            >
              {t('general.text3', 'Delete')}
            </Button>
          </span>
        </div>
    </div>
  );

  const deleteModalBody = (
    <div>
      <div>
        {/* {t('adList.text05', 'Is it no problem to delete this ad and image completely?')} */}
        Is it no problem to delete this ad completely?
      </div>
      <div className={classes.singleAdElementButtons}>
        <span className={classes.singleAdElementButton}>
          <Button
            design="flat"
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            onClick={showDeleteModalHandler}
          >
            {t('general.text1', 'Cancel')}
          </Button>
        </span>
        <span className={classes.singleAdElementButton}>
          <Button
            design="raised"
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            onClick={() => {
              deleteAdElementHandler(
                BASE_URL,
                localStorage.getItem("token"),
                ad.adElementId
              );
            }}
          >
            {t('general.text3', 'Delete')}
          </Button>
        </span>
      </div>
      {isLoading && <Loader />}
    </div>
  );

  return (
    <Fragment>
      {showDeleteModal && (
        <div>
          <Backdrop onClick={showDeleteModalHandler} />
          <SmallModal style="confirmModal">{deleteModalBody}</SmallModal>
        </div>
      )}
      {singleAdElementContentsBody}
    </Fragment>
  );
};
export default SingleAdElementContents;
