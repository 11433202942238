import React, { useState, useEffect } from 'react';
// import './App.css';
import { Redirect, useHistory, Link } from 'react-router-dom';
import i18next from 'i18next';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next/hooks";

import Loader from '../../components/Loader/Loader';
import SetupForm from './SetupForm';
import * as paymentUtil from '../../util/payment';

import { useStore } from '../../hook-store/store';
// import { fetchPrices } from '../../utils/stripe';
import { BASE_URL } from '../../App';

import classes from './PaymentSetup.module.css';

const PaymentSetup = (props) => {

  const { stripePromise } = props;

  const [t] = useTranslation("translation");
  // const url = new URL(window.location.href);
  // const queryParams = url.searchParams;
  // const customerEmail = queryParams.get('customer-email');

  const customerEmail = localStorage.getItem('email');
  // console.log(customerEmail);
  
  const lsToken = localStorage.getItem('token');

  // const history = useHistory();

  const [store, dispatch] = useStore();
  // console.log(state);
  const { i18n } = store;

  // const [email, setEmail] = useState('jenny.rosen@example.com');
  const [email, setEmail] = useState(localStorage.getItem('email'));
  // const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  // const [stripePromise, setStripePromise] = useState('');
  const [clientSecret, setClientSecret] = useState('');


  const [showCardInput, setShowCardInput] = useState(false);

  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {/*...*/},
  };
  
  useEffect(() => {
    if (showCardInput) {
      getSetupIntent();
      // getStripPromise();
    }
  },[showCardInput]);

  useEffect(() => {
    if (store.paymentStore.customerMethods.length > 0) {
      const methodList = [];
      for (const method of store.paymentStore.customerMethods) {
        const cardExpire = paymentUtil.cardExpire(method.card.exp_month, method.card.exp_year);
        if (!cardExpire) {
          methodList.push(method);
        }
      }
  
      console.log(methodList);
      // if (methodList.length === 1) {
      //   dispatch('SET_VALID_DEFAULTMETHOD', methodList[0]);
      // }
  
      if (methodList.length > 0) {
        const isDefaultMethod = methodList.find(method => {
          return method.metadata.adNetworkDefault;
        });
        if (isDefaultMethod) {
          dispatch('SET_VALID_DEFAULTMETHOD', isDefaultMethod);
        } else {
          dispatch('SET_VALID_DEFAULTMETHOD', null);
        }
      }
      else {
        dispatch('SET_VALID_DEFAULTMETHOD', null);
      }
    }
  },[store.paymentStore.customerMethods]);



  const showCardInputHandler = () => {
    if (showCardInput) {
      setClientSecret('');
    }
    setShowCardInput(!showCardInput);
  };

  const getSetupIntent = async () => {
    setIsLoading(true);

    try { 
      const lsCustomerId = localStorage.getItem('customerId');
      
      if (lsCustomerId) {
        const response = await fetch(BASE_URL + `/payment/setup-intent?customerId=${lsCustomerId}`, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + lsToken,
            'Content-Type': 'application/json',
            // 'Accept': 'application/json',
          },
        });

        const {client_secret: clientSecret} = await response.json();
        setClientSecret(clientSecret);
        console.log(response, clientSecret);
        setIsLoading(false);
      }

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const showCardAddedHandler = async (paymentMethod) => {
    try {

      setIsLoading(true);
      dispatch('SET_ISSTRIPE_LOADING', true);

      const customerMethods = store.paymentStore.customerMethods;
      const addedMethodList = [paymentMethod].concat(customerMethods);
      


      //// update default metadata when previous valid defaut metadata not exist

      const methodWithDefaultMetadata = addedMethodList.filter(method => {
        return method.metadata.adNetworkDefault;
      });

      const expiredDefaultList = [];
      
      if (methodWithDefaultMetadata.length > 0) {
        for (const method of methodWithDefaultMetadata) {
          const isExpired = paymentUtil.cardExpire(method.card.exp_month, method.card.exp_year);
          if (isExpired) {
            expiredDefaultList.push(method);
          }
        }
      }

      if (methodWithDefaultMetadata.length === 0 || expiredDefaultList.length > 0) {
        //// update default meta
        const result = await paymentUtil.updateDefaultPaymentMethod(
          BASE_URL,
          localStorage.getItem('token'),
          paymentMethod.id,
          localStorage.getItem('customerId'),
        );
        
        console.log(methodWithDefaultMetadata);
        console.log(result);
        
        dispatch('SET_CUSTOMER_METHODS', result.updatedPayamentMethods);
      } else {
        dispatch('SET_CUSTOMER_METHODS', addedMethodList);
      }




      dispatch('SHOW_NOTIFICATION', {
        // status: 'success',
        // title: 'notify-title',
        // message: 'Card Add Success',
        message: `${t('payment.text13', 'Add Card Success')}`
      });
  
      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION')
      }, 1000* 5);

      
      // await getMethodsHandler(BASE_URL, lsToken);
      setIsLoading(false);
      dispatch('SET_ISSTRIPE_LOADING', false);

    } catch (err) {
      console.log(err)
      setIsLoading(false);
      dispatch('SET_ISSTRIPE_LOADING', false);
    }

  };



  return (
    <main>

      {isLoading ?
          (<div className={classes.subscriptionLoader}><Loader /></div>)
        :  
          (<div className={classes.paymentSetupAddCardTitle}
              disabled={isLoading}
              onClick={showCardInputHandler}
            >
              {t('payment.text01', 'Add New Card')}
            </div>)
      }

      {error && error}

      {showCardInput && clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={options}>
          <SetupForm 
            showCardAddedHandler={showCardAddedHandler}
            showCardInputHandler={showCardInputHandler}
          />
        </Elements>
      )}
    </main>
  );
}

export default PaymentSetup;
