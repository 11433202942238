import React from 'react';
import { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next/hooks';

import AdItems from '../../components/UserAdElements/SingleAdElement/AdItems';
import AdItems2 from '../../components/UserAdElements/SingleAdElement/AdItems2';

import './PageDescription.css';


import GifImage from '../../images/Rotating_earth.gif';

import remeetImage3 from '../../images/webinar-500-crop.png';
import horizonImages from '../../images/coffees-horizon-crop2.png';
import verticalImages from '../../images/dog-coffee.jpge-crop-vertical2.jpg';
import textEffectGif from '../../images/texteffects-crop.gif';
import rotateEarthGif from '../../images/Rotating_earth.gif';
import videoAdExample from '../../images/video-ad-example.mp4';

const PageDescription = props => {

  const [t] = useTranslation('translation');

  const [showAdExamples, setShowAdExamples] = useState(false);

  const adExampleBody = (
    <div>
      <AdItems2 ad={{ 
        title: 'your ad title', 
        description: 'This is description of your ad. Some descriptions of web site',
        adImageUrl: remeetImage3,
        }} 
      />

      <div style={{ display: 'flex', flexWrap: 'wrap'}}>
        <div>
          <div>300x65</div>
            <div className="body300x65Container">    
                <img
                  src={horizonImages}
                  alt="300x65 image"
                />
            </div>
          </div>
          <div>

          <div>450x90</div>
          <div className="body450x90Container">    
              <img style={{widows: "450px", height:"90px"}}
                src={horizonImages}
                alt="450x90 image"
              />
          </div>
          </div>
          <div>
            <div>600x130</div>
              <div className="body600x130Container">    
                  <img
                    src={horizonImages}
                    alt="600x130 image"
                  />
              </div>
          </div>

          <div>
            <div>gif example</div>
            <div className="body300x65Container">    
                <img 
                // style={{widows: "300px", height:"65px"}}
                  src={textEffectGif}
                  alt="300x65 image"
                />
            </div>
          </div>

          <div>
            <div>gif example</div>
            <div className="body300x65Container" >    
              <div 
                className="body300x65Text">
                  <div 
                    className="body300x65TextContents">
                    <div className="body300x65TextTitle">
                      your ad title
                    </div>
                    <div className="body300x65TextDescription">
                      This is gif image example. Some descriptions of web site
                    </div>
                  </div>
                </div>
                <img
                  className="body300x65Image"
                  // className={classes.body450x90Image}
                  src={rotateEarthGif}
                  alt="450x90 image"
                />
            </div>
          </div>

          <div>
            <div>150x500</div>
            <div className="body150x500Container">    
                <img
                  src={verticalImages}
                  alt="150x500 image"
                />
            </div>
          </div>
          <div>
            <div>video ad example</div>
            <video 
              style={{width:"300px"}}
              src={videoAdExample}
              muted
              autoPlay
              loop
            />
          </div>
      </div>

    </div>
  );

  return (
    <div className='pageDescription'>
      <section className="notPageFound__AdnetDescription">
        <div className='pageDescriptionTitle'>
          Adnetwork
        </div>
        <p>
        {t('topPage.text01', 'Creating ads in Adnetwork enable to reach your specified targets such as language, geolocation, and target devices.')}
        </p>
        <p>
          {t('topPage.text09','It is possible to create image ad, video ad, text ad (image ad without image). Image ad will be displayed as banner in various websites.')}
        </p>
        <div>
          <div  className='notPageFound__ExampleButton' 
            onClick={() => { setShowAdExamples(!showAdExamples); }}>
            <strong>
              {t('topPage.text05','Ad examples')} &#x25BE;
            </strong>
          </div>
          {showAdExamples && (
            <div>
              {adExampleBody}
            </div>
          )}
        </div>
        <p>
          {t('topPage.text02','It costs when viewers click your ad and visit your web site')} ({t('topPage.text03','For example, in')}{' '}
            <a href='https://www.watakura.xyz' target="_blank" rel="noopener noreferrer">
              <strong>watakura</strong>,
            </a>
            {' '}
            <a href='https://remeet-wepl.watakura.xyz/your-presentation' target="_blank" rel="noopener noreferrer">
              <strong>REMEET-WePL</strong>,
            </a>
            {' '}
            <a href='https://stellar-nftplace.spaceeight.work' target="_blank" rel="noopener noreferrer">
              <strong>Stellar NFT Place</strong>,
            </a>
            {' '}
            <a href='https://news.spaceeight.work' target="_blank" rel="noopener noreferrer">
              <strong>StellarSpace News</strong>
            </a>
            ).
          {' '}
          {t('topPage.text10','In the case of video ad, it costs when ad is displayed more than 10 seconds. ')}
          {' '}
          ({t('topPage.text11','Currently, it cost 0.01 USD per click in the case of image ads.')} 
          {' '}
          {t('topPage.text12','In the case of video ads, costs start from 0.1 USD per display. Costs of video ads depend on the length of video.')})
        </p>
        <p>
        {t('topPage.text04','You can set daily budget to control costs. For payment, credit card payment is used.')}
        </p>
        <p>
          {t('topPage.text13','Ad report page displays results of created ads.')}
          {' '}
          {t('topPage.text14','It is possible to analyze results such as ad display number or ad visit number.')}
        </p>
        <p>
          {t('topPage.text06','If you have questions, please ask in')}
          {' '}
          <Link to="/contact"> {t('topPage.text07','contact form')}</Link>.
          {/* If you have questions, please ask in
          {' '}
          <Link to="/contact"> contact form</Link>. */}
        </p>

        <p>
          {t('topPage.text08','Start to reach your targets by signup.')}
        </p>
        <p>
          {t('topPage.text15', 'When you want to display various ads in your site, it is possible to use')}
          {' ' }
          <a href='https://adnetwork-adhost.spaceeight.net' target="_blank" rel="noopener noreferrer">
            <strong>Adnetwork Ad host</strong>
          </a>.
          {' '}

        </p>
      </section>
    </div>
  );
}

export default PageDescription;