import { initStore } from "./store";

const configureStore = () => {
  const actions = {

    SET_ADELEMENTS_FORADMIN:(state, payload) => {
      return {
        ...state,
        adminStore: {
          ...state.adminStore,
          adElementsForAdmin: payload,
        }
      };
    },
    SET_LISTFORADMIN_SEARCHAD:(state, payload) => {
      return {
        ...state,
        adminStore: {
          ...state.adminStore,
          listForAdminSearchAd: payload,
        }
      };
    },
    SET_USERS_FORADMIN:(state, payload) => {
      return {
        ...state,
        adminStore: {
          ...state.adminStore,
          usersForAdmin: payload,
        }
      };
    },
    SET_ADMIN_SELECTEDUSER:(state, payload) => {
      return {
        ...state,
        adminStore: {
          ...state.adminStore,
          adminSelectedUser: payload,
        }
      };
    },

  };

  initStore(actions, {
    adminStore: { 
      adElementsForAdmin: [],
      listForAdminSearchAd: [],
      usersForAdmin: [],
      adminSelectedUser: null,
    }
  });
};

export default configureStore;
