import React, { useState, useEffect, Fragment } from "react";
// import './App.css';
import { Redirect, useHistory, Link } from "react-router-dom";
import i18next from "i18next";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next/hooks";

import ChargeRecordTable from "./ChargeRecordTable";
import Loader from "../../components/Loader/Loader";
import * as paymentUtil from '../../util/payment';

// import { useStore } from '../../hooks-store/store';
import { useStore } from "../../hook-store/store";
// import { fetchPrices } from '../../utils/stripe';
import { BASE_URL } from "../../App";

import classes from "./ChargeRecord.module.css";

const ChargeRecord = (props) => {
  // console.log('CustomerMethod.js-props', props);

  // // const url = new URL(window.location.href);
  // // const queryParams = url.searchParams;
  // // const customerEmail = queryParams.get('customer-email');
  // const customerEmail = localStorage.getItem("email");
  // // console.log(customerEmail);
  const lsToken = localStorage.getItem("token");

  // const { customerMethods, detachPaymentMethod } = props;

  // const history = useHistory();
  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();

  // console.log('customerMethod.js store', store);
  const customerMethods = store.paymentStore.customerMethods;

  const { i18n } = store;

  const [isLoading, setIsLoading] = useState(false);

  // const [customerMethods, setCustomerMethods] = useState([]);
  const [chargeRecords, setChargeRecords] = useState([]);
  const [showChargeList, setShowChargeList] = useState(false);




  useEffect(() => {
    if (localStorage.getItem('customerId')) {
      getCustomerPaymentIntentsHandler(
        BASE_URL,
        localStorage.getItem('token'),
        localStorage.getItem('customerId'),
      );
    }
  },[]);


  const getCustomerPaymentIntentsHandler = async (url, token, customerId) => {
    try {
      setIsLoading(true);

      const resData = await paymentUtil.postGetCustomerPaymentIntents(
        url,
        token,
        customerId,
      );

      console.log(resData);
      if (resData && resData.paymentIntents) {
        ////
        console.log('paymentIntents', resData.paymentIntents);
        setChargeRecords(resData.paymentIntents);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  return <Fragment>
    <div className={classes.chargeRecord}>
      <div className={classes.chargeRecordTitle}>
        recent-charge-record
      </div>
      {isLoading && (
        <Loader />
      )}

      {!isLoading && (
        <ChargeRecordTable 
          chargeRecords={chargeRecords}
        />
      )}
    </div>
    </Fragment>;
};

export default ChargeRecord;
