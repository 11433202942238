import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';

import configureUiStore from './hook-store/ui-store';
import configureMapStore from './hook-store/map-store';
import configurePaymentStore from './hook-store/payment-store';
import configureAdReportStore from './hook-store/ad-report-store';
import configureAdminStore from './hook-store/admin-store';
import configureUserFilesStore from './hook-store/user-files-store';
import configurePageNotificationStore from './hook-store/page-notification';

configureUiStore();
configureMapStore();
configurePaymentStore();
configureAdReportStore();
configureAdminStore();
configureUserFilesStore();
configurePageNotificationStore();

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
