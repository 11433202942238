// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import _ from 'lodash';
// import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next/hooks";

// import AdminAdElementsItem from "../../components/AdminAdElements/AdminAdElementsItem";
// import AdminSearchAd from "../../components/SearchAd/AdminSearchAd";

import Loader from "../../components/Loader/Loader";
import Paginator from "../../components/Paginator/Paginator";
// import UserAdElementsItem from "../../components/UserAdElements/UserAdElementsItem";

// import Backdrop from "../Backdrop/Backdrop";
import PageNotificationItem from "./PageNotificationItem";
import SmallModal from "../Modal/SmallModal";
import TransBackdrop from "../Backdrop/TransBackdrop";

import { useStore } from "../../hook-store/store";
import { 
  getPageNotification,
  createTestPageNotification,
  updatePageNotificationLastOpenTime,
  updatePageNotificationReadState,
 } from "../../util/page-notification/page-notification";

// import { getUserDataForStore } from "../../util/user";
// import { getUserAdElements } from "../../util/ad-visit";
// import { getAdElementsForAdmin, isAdminUser } from "../../util/admin-ad-element";

import { BASE_URL } from "../../App";

import { marks } from "../../images/icon/marks";
import './PageNotification.css';
// import classes from './AdminAdElements.module.css';

const PageNotification = (props) => {
  const { isAuth } = props;

  const perPagePost = 20;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { 
    userPageNotification,
    pageNotifyLocalLastOpenTime,
    pageNotifyNewNotfiyNum,
  } = store.pageNotificationStore;
  // console.log('store in AdminAdElements.js', store);

  const [isLoading, setIsLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  // const [newNotfiyNum, setNewNotifyNum] = useState(0);
  // const [localLastGetTime, setLocalLastGetTime] = useState(0);

  // const [suggestId, setSuggestId] = useState('');
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    if (isAuth) {
      getPageNotificationHandler();
    }
  },[isAuth]);

  // useEffect(() => {
  //   if (userPageNotification) {
  //       const notifyList = userPageNotification.pageNotificationList;
        
  //       const newNotifyList = notifyList.filter(element => {
  //         return element.creationTime > userPageNotification.lastOpenTime;
  //       });
    
    
  //       if (newNotifyList.length > 0) {
  //         setNewNotifyNum(newNotifyList.length);
  //       }
  //   }
  // },[userPageNotification]);


  useEffect(() => {
    if (userPageNotification) {
      const notifyList = userPageNotification.pageNotificationList;
      let newNotifyList = [];

      if (pageNotifyLocalLastOpenTime > 0) {
        newNotifyList = notifyList.filter(element => {
          return element.creationTime > pageNotifyLocalLastOpenTime;
        });
      } else {
        
        newNotifyList = notifyList.filter(element => {
          return element.creationTime > userPageNotification.lastOpenTime;
        });
  
      }

      console.log(newNotifyList);
      dispatch('SET_PAGENOTIFY_NEWNOTIFYNUM', newNotifyList.length);
      
      // if (newNotifyList.length > 0) {
      //   // setNewNotifyNum(newNotifyList.length);
      // }
    }
  },[userPageNotification, pageNotifyLocalLastOpenTime]);

  const showNotificationHandler = () => {
    if (!showNotification) {
      updateLastOpenTimeHandler();

      if (pageNotifyLocalLastOpenTime < (Date.now() - 1000*60*5) ) {
          getPageNotificationHandler();
      }

    }

    // setNewNotifyNum(0);
    dispatch('SET_PAGENOTIFY_LOCALLASTOPENTIME', Date.now());
    setShowNotification(!showNotification);
  };


  // const getPageNotificationHandler = async () => {
  //   try {
  //     setIsLoading(true);
  //     const resData = await getPageNotification(BASE_URL, localStorage.getItem('token'));
  //     // console.log(adList);

  //     console.log(resData);
  //     if (resData && resData.data) {
  //       dispatch('SET_USERPAGENOTIFICATION', resData.data);

  //       setLocalLastGetTime(Date.now());

  //     } else {
  //       dispatch('SET_USERPAGENOTIFICATION', null);
  //     }

  //     setIsLoading(false);
  //   } catch(err) {
  //     console.log(err);
  //     setIsLoading(false);
  //   }
  // };


  const getPageNotificationHandler = async () => {
    try {
      // console.log('getPageNotificationHandler');
      setIsLoading(true);
      const resData = await getPageNotification(BASE_URL, localStorage.getItem('token'));
      // console.log(adList);

      console.log(resData);
      if (resData && resData.data) {
        dispatch('SET_USERPAGENOTIFICATION', resData.data);

        // setLocalLastGetTime(Date.now());
        dispatch('SET_PAGENOTIFY_LOCALLASTGETNUM', Date.now());

      }

      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const createTestNotifyHandler = async () => {
    try {
      setIsLoading(true);
      const resData = await createTestPageNotification(BASE_URL, localStorage.getItem('token'));
      // console.log(adList);

      if (resData && resData.data) {
        dispatch('SET_USERPAGENOTIFICATION', resData.data);
      }

      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const updateLastOpenTimeHandler = async () => {
    try {
      // setIsLoading(true);
      const resData = await updatePageNotificationLastOpenTime(
        BASE_URL, 
        localStorage.getItem('token')
      );

      if (resData && resData.data) {
        dispatch('SET_USERPAGENOTIFICATION', resData.data);
      }

      // console.log(adList);
      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const updateReadStateHandler = async (id, readState) => {
    try {
      setIsLoading(true);
      const resData = await updatePageNotificationReadState(
        BASE_URL, 
        localStorage.getItem('token'),
        id,
        readState,
      );

      if (resData && resData.data) {
        dispatch('SET_USERPAGENOTIFICATION', resData.data);
      }
      // console.log(adList);
      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };



  let pageNotificationListBody;

  if (showNotification && userPageNotification && userPageNotification.pageNotificationList.length > 0) {
    
    const displayList = _.orderBy(userPageNotification.pageNotificationList, 'creationTime', 'desc');
    
    pageNotificationListBody = (
      <div>
        <TransBackdrop
          onClick={showNotificationHandler}
        />
        <SmallModal style="pageNotificationModal">
          {isLoading && (<Loader />)}

          <ul className="pageNotificationList">
            {displayList.map(notify => {
              return (
                <li key={notify.id}>
                  <PageNotificationItem 
                    notify={notify}
                    showNotificationHandler={showNotificationHandler}
                  />
                </li>
              );
            })}
          </ul>
        </SmallModal>
      </div>
    );
  }


  if (!isAuth || !userPageNotification) {
    return null;
  }

  return (
    <Fragment>
      {/* <div>page-notification</div> */}
      {/* <button onClick={createTestNotifyHandler}>
        create-test-page-notify
      </button> */}

      <span className="pageNotificationTitle"
        onClick={showNotificationHandler}
      >
        <span >Notify</span>
        {pageNotifyNewNotfiyNum > 0 && (
          <span className="pageNotificationTitleNum">
            {pageNotifyNewNotfiyNum}
          </span>
        )}
      </span>
      {/* {userPageNotification && (
        <div>last-open-time: {new Date(userPageNotification.lastOpenTime).toLocaleString()}</div>
      )}
      <div>new-notify-num: {newNotfiyNum}</div> */}
      <div>{pageNotificationListBody}</div>
    </Fragment>
  );
};

export default PageNotification;
