import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { useTranslation } from "react-i18next/hooks";

import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import { useStore } from '../../hook-store/store';

import { BASE_URL } from '../../App';

import classes from './SetupForm.module.css';

const returnUrl = 'http://localhost:8503/';

const SetupForm = (props) => {

  const { showCardAddedHandler, showCardInputHandler } = props;

  const [t] = useTranslation("translation");

  const stripe = useStripe();
  const elements = useElements();

  const [store, dispatch] = useStore();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {

    setIsLoading(true);
    dispatch('SET_ISSTRIPE_LOADING', true);

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // const {error} = await stripe.confirmSetup({
    // let error;
    const {setupIntent, error} = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // return_url: 'https://example.com/account/payments/setup-complete',
        return_url: returnUrl,
        // return_url: '',
      },
      redirect: "if_required"  // for not redirect
    });

    console.log(setupIntent);
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setIsLoading(false);
      dispatch('SET_ISSTRIPE_LOADING', false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      if (setupIntent && setupIntent.status === "succeeded") {
        
        const metaData = {
          adNetwork: 'card-added'
        };

        updatePaymentMethodMetaData(setupIntent.payment_method, metaData);
      }
    }
  };

  const updatePaymentMethodMetaData = async (paymentMethodId, metaData) => {
    try { 
        setIsLoading(true);
        dispatch('SET_ISSTRIPE_LOADING', true);

        const lsToken = localStorage.getItem('token');

        const response = await fetch(BASE_URL + `/payment/payment-method-metadata`, {
          method: 'PUT',
          headers: {
            Authorization: 'Bearer ' + lsToken,
            'Content-Type': 'application/json',
            // 'Accept': 'application/json',
          },
          body: JSON.stringify({
            paymentMethodId: paymentMethodId,
            metaData: metaData,
          })
        });

        const resData = await response.json();
        console.log(response, resData);
        setIsLoading(false);
        dispatch('SET_ISSTRIPE_LOADING', false);

        //// show add success result and close input
        await showCardAddedHandler(resData.paymentMethod);
        showCardInputHandler();
    } catch(err) {
      console.log(err);
      setIsLoading(false);
      dispatch('SET_ISSTRIPE_LOADING', false);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className={classes.setupFormSubmitButton}>
        <Button design="raised" type="submit"
          disabled={!stripe || isLoading}
          loading={isLoading}
        >
          {t('payment.text02', 'Submit')}
        </Button>
      </div>
      {/* Show error message to your customers */}
      {errorMessage && <div><strong>{errorMessage}</strong></div>}

      {isLoading && 
        <div className={classes.setupFormLoader}><Loader /></div>
      }
    </form>
  )
};

export default SetupForm;