import { initStore } from "./store";

const configureStore = () => {
  const actions = {

    SET_USERPAGENOTIFICATION:(state, payload) => {
      return {
        // ...state,
        pageNotificationStore: {
          ...state.pageNotificationStore,
          userPageNotification: payload,
        }
      };
    },
    SET_PAGENOTIFY_NEWNOTIFYNUM:(state, payload) => {
      return {
        // ...state,
        pageNotificationStore: {
          ...state.pageNotificationStore,
          pageNotifyNewNotfiyNum: payload,
        }
      };
    },
    SET_PAGENOTIFY_LOCALLASTGETNUM:(state, payload) => {
      return {
        // ...state,
        pageNotificationStore: {
          ...state.pageNotificationStore,
          pageNotifyLocalLastGetTime: payload,
        }
      };
    },
    SET_PAGENOTIFY_LOCALLASTOPENTIME:(state, payload) => {
      return {
        // ...state,
        pageNotificationStore: {
          ...state.pageNotificationStore,
          pageNotifyLocalLastOpenTime: payload,
        }
      };
    },
  };

  initStore(actions, {
    pageNotificationStore: { 
      userPageNotification: null,
      pageNotifyNewNotfiyNum: 0,
      pageNotifyLocalLastGetTime: 0,
      pageNotifyLocalLastOpenTime: 0,
    }
  });
};

export default configureStore;
