import React, { Fragment, useEffect, useState } from "react";
import { withI18n } from "react-i18next";
import Img from "react-cool-img";

// import Loader from "../Loader/Loader";
import Backdrop from "../Backdrop/Backdrop";
import SmallModal from "../Modal/SmallModal";
import UserFilesSelect from "./UserFilesSelect";

import { useStore } from "../../hook-store/store";

import { isVideoFile, isImageFile } from "../../util/image";
import { createDisplayId } from "../../util/ad-visit";

import { BASE_URL } from "../../App";

import { marks } from '../../images/icon/marks';
// import "./FeedEdit.css";
import classes from './UserFilesContent.module.css';

const UserFilesContent = (props) => {
  const { 
    t,
    // adImageUrls,
    // ad150x500ImageUrls,
    // ad600x130ImageUrls
  } = props;

  const [store, dispatch] = useStore();
  const userAdElements = store.userAdElements;
  const adImageUrls = store.userFilesStore.adImageUrls;
  const ad150x500ImageUrls = store.userFilesStore.ad150x500ImageUrls;
  const ad600x130ImageUrls = store.userFilesStore.ad600x130ImageUrls;
  const selectedUrls = store.userFilesStore.selectedUrls;

  // const [selectedUrls, setSelectedUrls] = useState([]);

  const [showSelectedModal, setShowSelectedModal] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    return () => {
      // dispatch('SET_ADIMAGEURLS', []);
      // dispatch('SET_AD150X500IMAGEURLS', []);
      // dispatch('SET_AD600X130IMAGEURLS', []);

      // dispatch('SET_SELECTEDURLS', []);
      // dispatch('SET_USERALLIMAGEURLS', null);
    }
  },[]);

  useEffect(() => {
    if (selectedUrls.length > 0) {
      setShowSelectedModal(true);
    }
  },[]);


  const checkReused = (imageData, path, url, userAdList) => {
    const filteredList = userAdList.filter(ele => {
      return (ele.adImagePath === path ||
        ele.ad150x500ImagePath === path ||
        ele.ad600x130ImagePath === path
      );
    });

    // console.log(filteredList);

    const isPathInList = selectedUrls.find(element => {
      return element.path === path;
    });

    if (isPathInList) {
      const deletedList = selectedUrls.filter(element => {
        return element.path !== path;
      });

      console.log(deletedList);

      // setSelectedUrls(deletedList);
      dispatch('SET_SELECTEDURLS', deletedList);
    } else {

      const addedList = selectedUrls.concat({
        path: path,
        url: url,
        imageData: imageData,
        usingAds: filteredList,
      });
  
      console.log(addedList);
      // setSelectedUrls(addedList);
      dispatch('SET_SELECTEDURLS', addedList);
    }

  };




  let adImagesBody = <div>no-ad-image</div>;

  if (adImageUrls && adImageUrls.length > 0) {
    adImagesBody = (
      <ul className={classes.userFilesList}>
        {adImageUrls.map((url, index) => {

          const selected = selectedUrls.find(ele => ele.path === url.adImagePath);
          
          const fileName = url.adImagePath.split('/')[url.adImagePath.split('/').length -1];
          const fileType = fileName.split('.').pop();
          // const isVideo = isVideoFile(fileType);
          
          // console.log(isVideo);
          // const timeEndIndex = url.ad150x500ImagePath.indexOf('Z');
          // const fileOriginalName = url.ad150x500ImagePath.slice(timeEndIndex + 2);
          if (isImageFile(fileType)) {
            return (
              <div key={index}
                className={classes.userFilesVideoContainer}
                // title={createDisplayId(url.adElementId)}
              >
                <Img
                  // style={{height:"100px", width:"100px", marginRight: "20px", cursor:"pointer"}}
                  className={classes.userFilesAdImage} 
                  onClick={() => { 
                    checkReused(url, url.adImagePath, url.adImageUrl, userAdElements) 
                  }}
                  src={url.adImageUrl}
                />
                {selected && (
                  <span className={classes.userFilesSelectMark}>
                    selected
                  </span>
                )}
              </div>
            )
          }
          if (isVideoFile(fileType)) {
            return (
              <div key={url.adElementId}
                // title={createDisplayId(url.adElementId)}
                className={classes.userFilesVideoContainer}
              >
                <video
                  style={{height:"100px", width:"100px", marginRight: "20px", cursor:"pointer", position: "absolutexxx"}}
                  // className={classes.userFilesVideo} 
                  onClick={() => { 
                    checkReused(url, url.adImagePath, url.adImageUrl, userAdElements) 
                  }}
                  src={url.adImageUrl}
                />
                <span className={classes.userFilesVideoMark}
                  onClick={() => { 
                    checkReused(url, url.adImagePath, url.adImageUrl, userAdElements) 
                  }}
                >
                  {/* {marks.triangleDown} */}
                  &#9654;
                </span>
                {selected && (
                  <span className={classes.userFilesSelectMark}>
                    selected
                  </span>
                )}
              </div>
            )
          }
        })}
      </ul>
    )
  }


  let ad150x500Body = <div>no-ad-image</div>;

  if (ad150x500ImageUrls && ad150x500ImageUrls.length > 0) {
    ad150x500Body = (
      <ul className={classes.userFilesList}>
      {ad150x500ImageUrls.map((url, index) => {

        const selected = selectedUrls.find(ele => ele.path === url.ad150x500ImagePath);

        // const timeEndIndex = url.ad150x500ImagePath.indexOf('Z');
        // const fileOriginalName = url.ad150x500ImagePath.slice(timeEndIndex + 2);

        return (
          <div key={index}
            // title={createDisplayId(url.adElementId)}
            className={classes.userFilesVideoContainer}
          >
            <Img 
              // style={{height:"100px", marginRight: "20px", cursor:"pointer"}}
              className={classes.userFiles150x500Image} 
              onClick={() => { 
                checkReused(url, url.ad150x500ImagePath, url.ad150x500ImageUrl, userAdElements); 
              }}
              src={url.ad150x500ImageUrl}
            />
            {selected && (
              <span className={classes.userFilesSelectMark}>
                selected
              </span>
            )}
          </div>
        );
      })}
    </ul>
    )
  }


  let ad600x130Body = <div>no-ad-image</div>;

  if (ad600x130ImageUrls && ad600x130ImageUrls.length > 0) {
    ad600x130Body = (
      <ul className={classes.userFilesList}>
      {ad600x130ImageUrls.map((url, index) => {

      const selected = selectedUrls.find(ele => ele.path === url.ad600x130ImagePath);
        // const timeEndIndex = url.ad150x500ImagePath.indexOf('Z');
        // const fileOriginalName = url.ad150x500ImagePath.slice(timeEndIndex + 2);

        return (
          <div key={index}
            // title={createDisplayId(url.adElementId)}
            className={classes.userFilesVideoContainer}
          >
            <Img 
              // style={{width:"100px", marginRight: "20px", cursor:"pointer"}}
              className={classes.userFiles600x130Image} 
              onClick={() => { 
                checkReused(url, url.ad600x130ImagePath, url.ad600x130ImageUrl, userAdElements); 
              }}
              src={url.ad600x130ImageUrl}
            />
            {selected && (
              <span className={classes.userFilesSelectMark}>
                selected
              </span>
            )}            
          </div>
        );
      })}
    </ul>
    )
  }
  

  return (
    <Fragment>
      <div className={classes.userFiles}>

        {selectedUrls.length > 0 && (
          <button className={classes.userFilesShowSelectedButton}
            onClick={() => { setShowSelectedModal(true); }}
          >
            Show Selected Files
          </button>
        )}

        {showSelectedModal && (
          <div>
            <Backdrop />
            <SmallModal style="modal">
              <div className={classes.userFilesImagesContainer}>
                <UserFilesSelect 
                  showSelectedModal={showSelectedModal}
                  setShowSelectedModal={setShowSelectedModal}
                />
              </div>
            </SmallModal>
          </div>
        )}

        <div className={classes.userFilesTitle}>
          Uploaded Files
        </div>

        <div className={classes.userFilesSelectMessage}>
          Click images to select
        </div>

        <div className={classes.userFilesImagesContainer}>
          <div>Ad Files</div>
          <div>{adImagesBody}</div>
        </div>

        <div className={classes.userFilesImagesContainer}>
          <div>150x500 Ad Files</div>
          <div>{ad150x500Body}</div>
        </div>

        <div className={classes.userFilesImagesContainer}>
          <div>600x130 Ad Files</div>
          <div>{ad600x130Body}</div>
        </div>

      </div>
    </Fragment>
  );
};

export default withI18n()(UserFilesContent);
