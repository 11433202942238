// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
// import Generic from './Generic.jsx';

import { useTranslation } from "react-i18next/hooks";

import Backdrop from "../../Backdrop/Backdrop";
import SmallModal from "../../Modal/SmallModal";

import { useStore } from "../../../hook-store/store";


// import classes from './UserAdElements.module.css';

const AccountDisabled = (props) => {
  const { } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const userData = store.userData;
  // console.log('store in UserAdElements.js', store);


  const accountDisabledBody = (
    <div>
      <div>
        <strong>Account Disabled</strong>
      </div>
      <div>
        account with email address of {userData.email} was disabled.
      </div>
      <div>
        <Link to="/contact">Contact Form Link</Link>
      </div>
    </div>
  );

  return (
    <Fragment>
          <Backdrop />
          <SmallModal style="confirmModal">
            {accountDisabledBody}
          </SmallModal>
    </Fragment>
  );
};

export default AccountDisabled;
