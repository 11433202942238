// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
// import Generic from './Generic.jsx';

import { useTranslation } from "react-i18next/hooks";

// import SingleAdElementContents from "../../../components/UserAdElements/SingleAdElement/SingleAdElementContents";
import AdminAdElementContents from "../../../components/AdminAdElements/AdminAdElement/AdminAdElementContents";
import { useStore } from "../../../hook-store/store";

import { getUserDataForStore } from "../../../util/user";
import { getUserAdElements, deleteAdElement } from "../../../util/ad-visit";
// import { adminUpdateAdStatus } from "../../../util/admin-ad-element";

import { BASE_URL } from "../../../App";

import classes from './AdminAdElement.module.css';

const AdminAdElement = (props) => {
  // console.log("AdminAdElement-props", props);
  const { isAuth, match, history } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const adElementsForAdmin = store.adminStore.adElementsForAdmin;

  const [adElementId, setAdElementId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // console.log('store in SingleAdElement.js', store);
  useEffect(() => {
    window.scrollTo(0,0);
  },[]);

  useEffect(() => {
    if (isAuth) {
      if (store.userAdElements.length === 0) {
        getUserAdElements(BASE_URL, localStorage.getItem("token"))
          .then((result) => {
            console.log(result);
            dispatch("SET_USER_ADELEMENTS", result.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      if (!store.userData) {
        getUserDataForStore(BASE_URL, localStorage.getItem("token"))
          .then((result) => {
            console.log(result);

            dispatch("SET_USERDATA", result.userData);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [isAuth]);

  useEffect(() => {
    if (isAuth && match) {
      const adElementId = match.params.adElementId;
      setAdElementId(adElementId);
      // getAdElement(BASE_URL, localStorage.getItem('token'), adElementId);
    }
  }, [isAuth, match]);



  const deleteAdElementHandler = (url, token, adElementId) => {
    setIsLoading(true);
    
    deleteAdElement(url, token, adElementId)
      .then((resData) => {
        console.log(resData);

        dispatch('SHOW_NOTIFICATION', {
          // status: 'success',
          // title: 'notify-title',
          message: 'Deletion Success',
        });

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION')
        }, 1000* 5);

        const deletedList = store.userAdElements.filter(ad => {
          return ad.adElementId !== adElementId;
        });

        dispatch('SET_USER_ADELEMENTS', deletedList);
        history.push('/create-ad-element');

        setIsLoading(false);

        // return getUserAdElements(BASE_URL, localStorage.getItem("token"));
      })
      // .then((result) => {
      //   console.log(result);
      //   dispatch("SET_USER_ADELEMENTS", result.data);
      //   history.push('/create-ad-element');
      // })
      .catch((err) => {
        console.log(err);

        dispatch('SHOW_NOTIFICATION', {
          // status: 'success',
          // title: 'notify-title',
          message: 'Deletion Failed',
        });

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION')
        }, 1000* 5);

        setIsLoading(false);
      });
  };

  const selectAdElementHandler = (adElementId) => {
    const selectedAdElement = adElementsForAdmin.find((ad) => {
      return ad.adElementId === adElementId;
    });

    if (selectedAdElement) {
      dispatch("SET_SELECTED_ADELEMENT", selectedAdElement);
      history.push("/create-ad-element");
    }
  };


  // const adminUpdateAdStatusHandler = async (url, token, adElementId, adStatus) => {
  //   setIsLoading(true);
    
  //   if (!adStatus) {
  //     throw new Error('adStatus is required');
  //   }

  //   adminUpdateAdStatus(url, token, adElementId, adStatus)
  //     .then((resData) => {
  //       console.log(resData);

  //       const adList = adElementsForAdmin;

  //       const adIndex = adList.findIndex((el) => {
  //         return el.adElementId === adElementId;
  //       });

  //       adList[adIndex].adStatus = resData.adStatus;
  //       // console.log(adList);
  //       dispatch('SET_ADELEMENTS_FORADMIN', adList);

  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsLoading(false);
  //     });
  // };


  let adminAdElementsBody;

  if (adElementsForAdmin.length > 0 && adElementId) {
    const filteredList = adElementsForAdmin.filter((el) => {
      return el.adElementId === adElementId;
    });

    adminAdElementsBody = (
      <ul>
        {filteredList.map((ad) => {
          return (
            <div className={classes.singleAdElementContainer} key={ad.adElementId}>
              <AdminAdElementContents 
                ad={ad}
                deleteAdElementHandler={deleteAdElementHandler}
                selectAdElementHandler={selectAdElementHandler}
                // adminUpdateAdStatusHandler={adminUpdateAdStatusHandler} 
                isLoading={isLoading}
              />
            </div>
          );
        })}
      </ul>
    );
  }

  let createAdBody;
  if (!isAuth) {
    createAdBody = <div>auth required</div>;
  }
  
  if (isAuth) {
    createAdBody = (
      <div>
        <div>{adminAdElementsBody}</div>
      </div>
    );
  }

  return <Fragment>
    {createAdBody}
    </Fragment>;
};
export default AdminAdElement;
