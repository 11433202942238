import { initStore } from "./store";

const configureStore = () => {
  const actions = {

    SET_ADIMAGEURLS:(state, payload) => {
      return {
        userFilesStore: {
          ...state.userFilesStore,
          adImageUrls: payload,
        }
      };
    },
    SET_AD150X500IMAGEURLS:(state, payload) => {
      return {
        userFilesStore: {
          ...state.userFilesStore,
          ad150x500ImageUrls: payload,
        }
      };
    },
    SET_AD600X130IMAGEURLS:(state, payload) => {
      return {
        userFilesStore: {
          ...state.userFilesStore,
          ad600x130ImageUrls: payload,
        }
      };
    },
    SET_SELECTEDURLS:(state, payload) => {
      return {
        userFilesStore: {
          ...state.userFilesStore,
          selectedUrls: payload,
        }
      };
    },
    SET_USERALLIMAGEURLS:(state, payload) => {
      return {
        userFilesStore: {
          ...state.userFilesStore,
          userAllImageUrls: payload,
        }
      };
    },

  };

  initStore(actions, {
    userFilesStore: { 
      adImageUrls: [],
      ad150x500ImageUrls: [],
      ad600x130ImageUrls: [],
      selectedUrls: [],
      userAllImageUrls: null,
    }
  });
};

export default configureStore;