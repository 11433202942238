// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
// import Generic from './Generic.jsx';
import { useTranslation } from "react-i18next/hooks";

import AdItems2 from "../../UserAdElements/SingleAdElement/AdItems2";
import Backdrop from "../../Backdrop/Backdrop";
import Button from "../../Button/Button";
import Loader from "../../Loader/Loader";
import SmallModal from "../../Modal/SmallModal";
import { createDisplayId } from "../../../util/ad-visit";
import { useStore } from "../../../hook-store/store";

import { adminUpdateAdStatus } from "../../../util/admin-ad-element";

import { BASE_URL } from "../../../App";

import classes from "./AdminAdElementContents.module.css";

const AdminUpdateAdStatus = (props) => {
  // console.log("AdminUpdateAdStatus-props", props);
  
  const { 
    ad, 
  } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  // console.log('store SingleAdElementContents.js', store);
  const adElementsForAdmin = store.adminStore.adElementsForAdmin;

  const [adStatusChoice, setAdStatusChoice] = useState('');
  const [statusReason, setStatusReason] = useState('');
  const [statusComment, setStatusComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // console.log('store in SingleAdElement.js', store);

  const statusDetailData = {
    status: adStatusChoice,
    reason: statusReason,
    comment: statusComment
  };

  const adminUpdateAdStatusHandler = async (url, token, adElementId, adStatus, adStatusDetail) => {
    setIsLoading(true);
    
    if (!adStatus) {
      throw new Error('adStatus is required');
    }

    adminUpdateAdStatus(url, token, adElementId, adStatus, adStatusDetail)
      .then((resData) => {
        console.log(resData);

        const adList = adElementsForAdmin;

        const adIndex = adList.findIndex((el) => {
          return el.adElementId === adElementId;
        });

        adList[adIndex].adStatus = resData.adStatus;
        adList[adIndex].adStatusDetail = resData.adStatusDetail;
        // console.log(adList);
        dispatch('SET_ADELEMENTS_FORADMIN', adList);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  let adminUpdateAdStatusBody;

  adminUpdateAdStatusBody = (
    <div>
      <div>update-adStatus-by-admin</div>
      <div>
          <div>ad-status: {ad.adStatus}</div>
          {ad.adStatusDetail && (
            <div>
              {/* {JSON.stringify(ad.adStatusDetail)} */}
              <div>reason: {ad.adStatusDetail.reason}</div>
              <div>
                comment: {ad.adStatusDetail.comment}
              </div> 
            </div>
          )}
          <div>
            <span>status-choice: </span>
            <select className={classes.tableControlSelect}
              // // value={pageSize}
              // defaultValue={adStatus}
              onChange={e => {
                // console.log(e.target.value);
                setAdStatusChoice(e.target.value);
              }}
            >
              {['', 'ok', 'other', 'other2', 'not-approved'].map((status, index) => (
                <option key={index} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
          <div>
          <span>reason-choice: </span>
            <select className={classes.tableControlSelect}
              // // value={pageSize}
              // defaultValue={adStatus}
              onChange={e => {
                // console.log(e.target.value);
                setStatusReason(e.target.value);
              }}
            >
              {['', 'reason1', 'reason2', 'reason3', 'reason4'].map((status, index) => (
                <option key={index} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
          <div>
            <span>comment-input:</span>
            <input 
              type="text" 
              onChange={(event) => {setStatusComment(event.target.value)}}
            />
          </div>
          <div>{adStatusChoice}, {statusReason} {statusComment}</div>
          <div>
            <button
                onClick={() => {
                  adminUpdateAdStatusHandler(
                    BASE_URL,
                    localStorage.getItem("token"),
                    ad.adElementId,
                    adStatusChoice,
                    statusDetailData,
                  );
                }}
              >
                change-status
            </button>
          </div>
        </div>
    </div>
  );



  return (
    <Fragment>
      {adminUpdateAdStatusBody}
    </Fragment>
  );
};
export default AdminUpdateAdStatus;
