import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next/hooks';

import i18n from '../../i18n';
import Button from '../../components/Button/Button';
// import DarkModeToggle from '../../components/DarkModeToggle/DarkModeToggle';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import Loader from '../../components/Loader/Loader';
import UserModalContents from '../../components/Modal/UserModalContents';
import LanguageList from '../../translation/LanguageList';
import { resources } from '../../translation/translation';
import SmallModal from '../../components/Modal/SmallModal';
import TransBackdrop from '../../components/Backdrop/TransBackdrop';
// import PushNotificationControl from '../../components/PushNotification/PushNotificationControl';

import {
  getUserData,
  getUserLocation,
  updateUserName,
  updateUserDescription,
  getFollowingUsers,
  getUsers
} from '../../util/user';
import { getFollowedUserList } from '../../util/follow';
import { useStore } from '../../hook-store/store';
import {
  BASE_URL,
  USERACCOUNTPAGE_URL,
} from '../../App'
import './UserInfo.css';
import { marks } from '../../images/icon/marks';


// import SampleImage from '../../components/Image/person-icon-50.jpg';

const UserInfo = props => {
  console.log('userinfo-props', props);

  const selectedLanguage = resources[i18n.language].translation.LANGUAGE;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { userNameData } = store;
  // console.log('store UserInfo.js', store);

  const [showSmallModal, setShowSmallModal] = useState(false);
  const [followingUsers, setFollowingUsers] = useState([]);
  const [showFollowingUsers, setShowFollowingUsers] = useState(false);
  const [followedByUserList, setFollowedByUserList] = useState([]);
  const [showFollowedByUserList, setShowFollowedByUserList] = useState(false);
  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [userImageUrl, setUserImageUrl] = useState('');
  const [userCreationDate, setUserCreationDate] = useState('');
  const [nameInput, setNameInput] = useState('');
  const [isNameUpdate, setIsNameUpdate] = useState(false);
  const [userDescription, setUserDescription] = useState('');
  const [descriptionInput, setDescriptionInput] = useState('');
  const [isDescriptionUpdate, setIsDescriptionUpdate] = useState(false);
  const [showUserDescription, setShowUserDescription] = useState(false);
  const [isImageUpdate, setIsImageUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const [selectedUserImageUrl, setSelectedUserImageUrl] = useState('');
  const [darkMode, setDarkMode] = useState();
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  // const [selectedLanguageName, setSelectedLanguageName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    console.log('in useeffect in userinofo.js');
    setIsLoading(true);

    getUserData(BASE_URL, props.token)
      .then(result => {
        console.log(result);
        userInfoSet(result.userData);

        setIsLoading(false);

        props.userInfoAction(result.userData);
      })
      .catch(err => {
        console.log(err);
        // catchError(err);
      })
  }, [userName, userImageUrl, userDescription]);

  useEffect(() => {
    updateLocationHandler();
    // getFollowedUserListHandler();
  }, []);

  const userInfoSet = (userInfo) => {
    setUserId(userInfo._id);
    setUserName(userInfo.name);
    setNameInput(userInfo.name);

    setUserImageUrl(userInfo.imageUrl);
    setUserCreationDate(userInfo.createdAt);

    setUserDescription(userInfo.description);
    setDescriptionInput(userInfo.description);
  }

  const nameInputChangeHandler = (input, value) => {
    setNameInput(value);
  }

  const descriptionInputChangeHandler = (input, value) => {
    setDescriptionInput(value);
    // console.log(descriptionInput.length)
  }

  const getNewImageUrl = (imageUrl) => {
    console.log('getNewImageUrl')
    setUserImageUrl(imageUrl);
  }

  const hideImageUpdateButton = () => {
    setIsImageUpdate(false);
  }

  const catchError = error => {
    setError(error);
  };

  const errorHandler = () => {
    setError(null);
  };

  const updateLocationHandler = () => {
    getUserLocation()
      .then(result => {
        console.log(result);
        // const locationData = result.data;

        // return updateUserLocation('', locationData, BASE_URL, props.token)
        //   .then(res => {
        //     console.log(res)
        //   })
        //   .catch(err => {
        //     console.log(err);
        //     catchError(err);
        //   });

      })
      .catch(err => {
        console.log(err);
        catchError(err);
      });
  }

  const updateUserNameHandler = (name) => {
    setIsLoading(true);
    updateUserName(name, BASE_URL, props.token)
      .then(result => {
        console.log(result);
        setUserName(result.data.data.user.name);
        localStorage.setItem('name', result.data.data.user.name);

        setIsNameUpdate(false);
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsNameUpdate(false);
        setIsLoading(false);
        catchError(err);
      })
  }

  const updateUserDescriptionHandler = (description) => {
    setIsLoading(true);
    updateUserDescription(description, BASE_URL, props.token)
      .then(result => {
        console.log(result);
        setUserDescription(result.data.data.user.description);
        // localStorage.setItem('name', result.data.data.user.);

        setIsDescriptionUpdate(false);
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsDescriptionUpdate(false);
        setIsLoading(false);
        catchError(err);
      })
  }

  const setShowUserDescriptionHandler = () => {
    setShowUserDescription(!showUserDescription);
  };

  const moveTestHandler = () => {
    localStorage.setItem('tempUserName', userName);

    // props.history.replace('/');
    props.history.push(`/feed/userposts/${userId}`);
  };

  const selectUserHandler = (userObj) => {
    setSelectedUserId(userObj.userId);
    setSelectedUserName(userObj.name);
    setSelectedUserImageUrl(userObj.imageUrl);
    setShowSmallModal(!showSmallModal);
  };

  const showSelectedUserPosts = (uid, uName) => {
    localStorage.setItem('tempUserName', uName);

    // props.history.replace('/');
    props.history.push(`/feed/userposts/${uid}`);
  }

  const getFollowingUsersHandler = () => {

    if (!showFollowingUsers) {
      setIsLoading(true);
      getFollowingUsers(
        localStorage.getItem('userId'), 
        BASE_URL, 
        localStorage.getItem('token')
      )
      .then(result => {
        console.log(result);
        setFollowingUsers(result.data);
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
    }

    setShowFollowingUsers(!showFollowingUsers);
  }

  const showSmallModalHandler = () => {
    setShowSmallModal(!showSmallModal);
  };

  const showLanguageModalHandler = () => {
    setShowLanguageModal(!showLanguageModal);
  };

  // const getLanguageNameHandler = (name) => {
  //   setSelectedLanguageName(name);
  // }

  const setDarkModeHandler = (darkModeInput) => {
    setDarkMode(darkModeInput)
  };

  const getFollowedUserListHandler = () => {
    // getUsers(BASE_URL, props.token)
    if (!showFollowedByUserList) {
      setIsLoading(true);
      getFollowedUserList(BASE_URL, localStorage.getItem('token'), userId)
      .then(result => {
        console.log(result);
        setIsLoading(false);
        setFollowedByUserList(result.data.followedByList);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      })
    }

    setShowFollowedByUserList(!showFollowedByUserList);
  }

  const usersModalBody = (
    <div>
      <TransBackdrop onClick={showSmallModalHandler} />
      <SmallModal style="modal2">
        <UserModalContents
          // {...props}
          postCreatorUserId={selectedUserId} 
          author={selectedUserName}
          creatorImageUrl={selectedUserImageUrl}
          setSelectedCreatorId={showSelectedUserPosts}
          resetPostPage={() => {}}
          showSmallModalHandler={showSmallModalHandler}
        />
      </SmallModal>
    </div>
  );

  const languageModalBody = (
    <div>
    <TransBackdrop onClick={showLanguageModalHandler} />
    <SmallModal style="languageModal">
      <div className="userInfo__closeModalButton"
      onClick={showLanguageModalHandler}
      >
        X
      </div>
      <LanguageList 
        showLanguageModalHandler={showLanguageModalHandler}
        // getLanguageNameHandler={getLanguageNameHandler}
        // i18n={props.i18n}
      />
    </SmallModal>
  </div>
  );

  const followingUsersBody = followingUsers.map(user => {
    return (
   
        <div key={user.userId}>
          <span onClick={() => { 
            selectUserHandler(user);
            }}
          >
            {user.name}
          </span>
          {/* <button onClick={() => {
            showSelectedUserPosts(user.userId, user.name);
            }}
          >
            user-posts
          </button> */}
        </div>
      
    );
    });

    // const followedByUsersBody = followedByUserList.map(user => {
    //   return (
     
    //       <div key={user.userId}>
    //         <span onClick={() => { 
    //           selectUserHandler(user);
    //           }}
    //         >
    //           {user.name}
    //         </span>
    //         {/* <button onClick={() => {
    //           showSelectedUserPosts(user.userId, user.name);
    //           }}
    //         >
    //           user-posts
    //         </button> */}
    //       </div>
        
    //   );
    //   });


  let body;
  if (isLoading) {
    body = (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <Loader />
      </div>
    );
  } else {
    body = (
      <div>
        {userNameData && (
          <div className="userInfo__infoContent">
            name: {userNameData.name}
            {' '}
            <a
              href={`${USERACCOUNTPAGE_URL}?tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
              target="_blank" rel="noopener noreferrer"
            >
              <Button mode="flat" type="submit">
                ({t('general.text5', 'edit')})  {marks.externalLinkMark}
              </Button>
            </a>
          </div>
        )}

        {store.userData && (
          <div className="userInfo__infoContent">
            email: {store.userData.email}
            {/* <span className="userInfo__userImage">
            </span> */}
          </div>
        )}

        {userNameData && (
          <div className="userInfo__infoContent">
            user image:
            {userNameData.imageUrl && (
              <img className='userInfoUserImage'
              src={userNameData.imageUrl} 
              />
            )}
            {!userNameData.imageUrl && (
              <span className='userInfoUserNoImage'>
                {marks.userMark}
              </span>
            )}
            {' '}
            <a
              href={`${USERACCOUNTPAGE_URL}?tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
              target="_blank" rel="noopener noreferrer"
            >
              <Button mode="flat" type="submit">
                ({t('general.text5', 'edit')}) {marks.externalLinkMark}
              </Button>
            </a>
          </div>
        )}

        <div className="userInfo__infoContent">
          <span>
            {/* creation date:  */}
            {t('userInfo.text4')}:
          </span>
          <span className="userInfo__userImage">
            {userCreationDate ? userCreationDate.split('T')[0] : null}
          </span>
        </div>

        <div className="userInfo__infoContent">
          <span>
            {/* Language: */}
            {t('userInfo.text11')}:
          </span>
          <span className="userInfo__infoContent">{selectedLanguage}</span>

          <div className="userInfo__actions">
            <Button
              mode="flat" type="submit"
              disabled={descriptionInput ? descriptionInput.length > 300 : null}
              onClick={showLanguageModalHandler}
            >
              {/* (change) */}
              ({t('userInfo.text12')})
            </Button>
          </div>

          {showLanguageModal ? languageModalBody : null}
        </div>

      </div>
    );
  }

  return (
    <div>
      <ErrorHandler error={error} onHandle={errorHandler} />

        <div>
          {/* User Information */}
          {t('userInfo.text1')}
          {body}
        </div>
 

      {/* <img src={SampleImage}></img> */}

 
      {/* <div>followed-by-users-test</div>
      <button onClick={getFollowedUserListHandler}>test-followed</button>
      {showFollowedByUserList ? 
        <div>
          {followedByUserList.length}
          {followedByUsersBody}
        </div> 
      : null
      } */}

    <div>

    </div>
    </div>
  );
}

export default UserInfo;