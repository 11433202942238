import React from "react";
import { useEffect, useState } from "react";
import { useMap, useMapEvents } from "react-leaflet";

import { useStore } from "../../hook-store/store";

function MapComponent(props) {
  console.log("mapComponent-props", props);
  
  const map = useMap();

  const [store, dispatch] = useStore();
  
  const mapCenter = store.markerCenter ? store.markerCenter : [0, 0];
  console.log('store in MapComponent', store);

  const [currentZoom, setCurrentZoom] = useState(2);

  const mapEvents = useMapEvents({
    zoomend: () => {
      console.log("zoomend", mapEvents.getZoom());
      setCurrentZoom(mapEvents.getZoom());
    },
    click: (e) => {
      const { lat, lng } = e.latlng;
      console.log('clicked lat lng', e.latlng);
    },
    moveend: (e) => {
      console.log('moveend center', e.target.getCenter());
    },
    locationfound: (location) => {
      console.log('location found:', location)
    },
  });


  useEffect(() => {
    console.log("map in MapComponent", map);
  }, [map]);

  map.setView(mapCenter, currentZoom);
  return null;
}

export default MapComponent;
