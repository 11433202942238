import React, { useState, useEffect, Fragment } from "react";
// import './App.css';
import { Redirect, useHistory, Link } from "react-router-dom";
import i18next from "i18next";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next/hooks";

import CustomerMethodList from "./CustomerMethodList";
import Loader from "../../components/Loader/Loader";
import * as paymentUtil from '../../util/payment';

// import { useStore } from '../../hooks-store/store';
import { useStore } from "../../hook-store/store";
// import { fetchPrices } from '../../utils/stripe';
import { BASE_URL } from "../../App";

import classes from "./ChargeRecordTable.module.css";

const ChargeRecordTable = (props) => {
  // console.log('CustomerMethod.js-props', props);
  const { chargeRecords } = props;


  // const history = useHistory();
  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();

  const { i18n } = store;


  let chargeRecordTableBody = <div>no record</div>;

  // const succeedList = chargeRecords.filter(charge => charge.status === 'succeeded');

  const notRefundSucceed = chargeRecords.filter(charge => {
    return (charge.status === 'succeeded' && 
            !charge.charges.data[0].refunded);
  });

  // console.log('notRefundSucceed', notRefundSucceed);

  if (notRefundSucceed.length > 0) {
    chargeRecordTableBody = (
      <tbody>
        {notRefundSucceed.slice(0, 5).map(charge => {
            return (
              <tr key={charge.id}>
                {/* {charge.id},  */}
                <td>
                  {(charge.amount/100).toFixed(2)} {charge.currency}
                </td>
                <td>
                  {new Date(charge.created*1000).toLocaleDateString()}
                </td>
                <td 
                  // title={charge.id}
                >
                  ...{charge.id.slice(-4)}
                </td>
                {/* <td>
                  {isRefunded && <span>Refunded</span>}
                </td> */}
              </tr>
            );
          
        })}
      </tbody>
    );
  }


  return <Fragment>
        <div className={classes.chargeRecordTable}>
          <table>
            <thead>
              <tr>
                <th>charge-amount</th>
                <th>charge-date</th>
                <th>charge-id</th>
                {/* <th>other</th> */}
              </tr>
            </thead>
            {chargeRecordTableBody}
          </table>
        </div>

    </Fragment>;
};

export default ChargeRecordTable;
