// const React = window.React = require('react');
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next/hooks';
import MetaTags from 'react-meta-tags';

const HeadMetaTag = (props) => {

  const [t] = useTranslation('translation');

  const title = `Adnetwork - ${t('headMeta01', 'create ads to reach specified targets')}`;

  const description = `Adnetwork by SpaceEight - ${t('headMeta02', 'Create various ads to reach specified targets such as language, geolocation, and target devices. (banner ad, video ad, text ad)')}`;

  return (
    <Fragment>
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* <title>Page 1</title>
        <meta name="description" content="Some description." />
        <meta property="og:title" content="MyApp" />
        <meta property="og:image" content="path/to/image.jpg" /> */}
      </MetaTags>
    </Fragment>
  );
}

export default HeadMetaTag;