// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
// import Generic from './Generic.jsx';

import { useTranslation } from "react-i18next/hooks";

import AdminAdElementsItem from "../../components/AdminAdElements/AdminAdElementsItem";
import AdminSearchAd from "../../components/SearchAd/AdminSearchAd";

import Loader from "../../components/Loader/Loader";
import Paginator from "../../components/Paginator/Paginator";
// import UserAdElementsItem from "../../components/UserAdElements/UserAdElementsItem";

import { useStore } from "../../hook-store/store";
import { getUserDataForStore } from "../../util/user";
import { getUserAdElements } from "../../util/ad-visit";
import { getAdElementsForAdmin, isAdminUser } from "../../util/admin-ad-element";

import { BASE_URL } from "../../App";

import classes from './AdminAdElements.module.css';

const AdminAdElements = (props) => {
  const { isAuth, startEditAdHandler } = props;

  const perPagePost = 20;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const adElementsForAdmin = store.adminStore.adElementsForAdmin;
  const adminSelectedUser = store.adminStore.adminSelectedUser;
  console.log('store in AdminAdElements.js', store);

  const [isLoading, setIsLoading] = useState(false);
  const [suggestId, setSuggestId] = useState('');
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    if (isAuth && isAdminUser(store.userData)) {
      if (adminSelectedUser) {
        getUserAdElements(adminSelectedUser.userId);
      } else {
        getAdminList();
      }
    }
  },[isAuth, store.userData]);



  const getUserAdElements = async (userId) => {
    try {
      setIsLoading(true);
      const result = await fetch(BASE_URL + `/admin-ad-element/user-ad-elements?userId=${userId}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
      });

      const resData = await result.json();
      console.log(result, resData);
      if (result.ok && resData.data) {
        dispatch('SET_ADELEMENTS_FORADMIN', resData.data);
      }

      setIsLoading(false);
    } catch(err) { 
      console.log(err); 
      setIsLoading(false);
    }
  };

  const getAdminList = async () => {
    try {
      setIsLoading(true);
      const adList = await getAdElementsForAdmin(BASE_URL, localStorage.getItem('token'));
      // console.log(adList);
      dispatch('SET_ADELEMENTS_FORADMIN', adList);
      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }

  }


  const setSuggestIdHandler = (adElementId) => {
    // console.log(adElementId);
    setSuggestId(adElementId);
  };


  let adminAdElementsBody;

  if (adElementsForAdmin && adElementsForAdmin.length === 0) {
    adminAdElementsBody = <div>no ads</div>
  }

  if (adElementsForAdmin && adElementsForAdmin.length > 0) {

    if (!suggestId) {
      const start = perPagePost * (pageNum - 1);
      const end = perPagePost * pageNum;

      adminAdElementsBody = (
        <ul>
          {adElementsForAdmin.slice(start, end).map(ad => {
            return (
              <div key={ad.adElementId}>
                <AdminAdElementsItem
                  ad={ad}
                  startEditAdHandler={startEditAdHandler}
                />
              </div>
            );
          })}
        </ul>
      );
    }
    else {
      const suggestElIndex = adElementsForAdmin.findIndex(ad => {
        return ad.adElementId === suggestId;
      });

      adminAdElementsBody = (
        <ul>
          {adElementsForAdmin.slice(suggestElIndex, suggestElIndex+1).map(ad => {
            return (
              <div key={ad.adElementId}>
                <AdminAdElementsItem
                  ad={ad}
                  startEditAdHandler={startEditAdHandler}
                />
              </div>
            );
          })}
        </ul>
      );
    }
  }

  let createAdBody;
  if (!isAuth) {
    createAdBody = <div>auth required</div>;
  }
  
  if (isAuth) {
    createAdBody = (
      <div className={classes.userAdElements}>
        
        {adminSelectedUser && (
          <div>
            admin-selected-user: {adminSelectedUser.userId} {adminSelectedUser.email} 
          <hr/>
          </div>
        )}

        <AdminSearchAd
          getSelectedSuggestId={setSuggestIdHandler}
        />
        <div>{adminAdElementsBody}</div>
        {!suggestId && (
          <Paginator
            onPrevious={() => { setPageNum(pageNum - 1); }}
            onNext={() => { setPageNum(pageNum + 1); }}
            lastPage={Math.ceil(adElementsForAdmin.length / perPagePost)}
            currentPage={pageNum}
          >
              {/* {feedPost2} */}
          </Paginator>
        )}
      </div>
    );
  }

  if (isLoading) {
    createAdBody = <div className="feed__loader"><Loader /></div>
  }

  return (
    <Fragment>
      {isAdminUser(store.userData) && (
        <div>
          <div>admin-ad-elements-page</div>
          <div>
            <Link to="/admin/top-page">admin-top-page</Link>
          </div>
          {createAdBody}
        </div>
      )}
    </Fragment>
  );
};

export default AdminAdElements;
