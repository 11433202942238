import React, { Fragment, useEffect, useState } from "react";
import { withI18n } from "react-i18next";

import { isImageFile, isVideoFile } from "../../../../util/image";
import { useStore } from "../../../../hook-store/store";

// import "./FeedEdit.css";
import classes from './FeedEditImageReuse.module.css';

const FeedEditImageReuse = (props) => {
  const { 
    t,
    state, 
    postInputChangeHandler, 
    inputBlurHandler, 
    imagePreviewBody,
    getInputHandler,
    selectedPost,
  } = props;

  const [store, dispatch] = useStore();
  const userAdElements = store.userAdElements;

  const [adImageUrls, setAdImageUrls] = useState([]);
  const [showReuseImages, setShowReuseImages] = useState(false);

  useEffect(() => {
    const imageList = [];
    if (userAdElements.length > 0) {
      for (const ad of userAdElements) {
        if (ad.adImageUrl) {
          // console.log(ad.adImagePath);
          // const timeEndIndex = ad.adImagePath.indexOf('Z');
          // const subString = ad.adImagePath.slice(timeEndIndex + 2);
          // console.log(subString);

          // const isAddedImage = imageList.find(url => {
          //   return url.adImagePath.slice(timeEndIndex + 2) === subString;
          // });

          const isAddedImage = imageList.find(url => {
            return url.adImagePath === ad.adImagePath;
          });

          if (!isAddedImage) {
            imageList.push({
              adImageUrl: ad.adImageUrl,
              adImagePath: ad.adImagePath,
            });
          }

          // imageList.push({
          //   adImageUrl: ad.adImageUrl,
          //   adImagePath: ad.adImagePath,
          // });

        }
      }
    }

    setAdImageUrls(imageList);
    console.log('imageList', imageList);
  },[userAdElements]);

  const showReuseImagesHandler = (event) => {
    event.preventDefault();
    setShowReuseImages(prev => !prev);
  }


  let existImageList;
  if (adImageUrls.length > 0) {
    existImageList = (
      <ul className={classes.reuseImageList}>
        {adImageUrls.map(url => {
          
          // const timeEndIndex = url.adImagePath.indexOf('Z');
          // const fileOriginalName = url.adImagePath.slice(timeEndIndex + 2);
          
          const fileType = url.adImagePath.split('.')[url.adImagePath.split('.').length - 1].toLowerCase();
          
          if (isImageFile(fileType)) {
            return (
              <div id={url.adImagePath}>
                {/* {adImageUrl.slice(0, 50)} */}
              <img className={classes.reuseImageImage} 
                onClick={() => { 
                  postInputChangeHandler('image', url.adImagePath, null, url.adImageUrl); 
                  setShowReuseImages(false);
                }}
                src={url.adImageUrl} 
              />
                {/* <div>{fileOriginalName}</div> */}
              </div>
            );
          }

          if (isVideoFile(fileType)) {
            return (
              <div id={url.adImagePath}
                className={classes.reuseImageVideoContainer}
              >
                <video className={classes.reuseImageImage}
                  onClick={() => { 
                    postInputChangeHandler('image', url.adImagePath, null, url.adImageUrl); 
                    setShowReuseImages(false);
                  }}
                  src={url.adImageUrl} 
                />
                <span 
                className={classes.reuseImageVideoMark}
                  // style={{ backgroundImage: `url(${url.adImageUrl})`}}
                >
                  &#9654;
                </span>
              </div>
            );
          }
        })}
      </ul>
    );
  }


  let feedEditImageReuseBody;

  if (adImageUrls.length > 0) {
    feedEditImageReuseBody = (
      <div>
        <div>{t('general.text38', 'or')},</div>
        <button className={classes.reuseImageOpenButton}
          onClick={showReuseImagesHandler}
        >
          {t('adForm.text06', 'Select from previous images')}
        </button>
        {showReuseImages && (
          <div className={classes.reuseImageContainer}>
            {existImageList}
          </div>
        )}
      </div>
    );
  }

  return (
    <Fragment>
      {feedEditImageReuseBody}
    </Fragment>
  );
};

export default withI18n()(FeedEditImageReuse);
