import React, { Fragment, useState } from "react";
import { withI18n } from "react-i18next";

import Button from "../../../Button/Button";
import ImagesForReuse from "./ImagesForReuse";
import { useStore } from "../../../../hook-store/store";

import { marks } from "../../../../images/icon/marks";

import { BASE_URL } from "../../../../App";

import "../FeedEdit.css";
import classes from "./FeedEditImageUpdate.module.css";

const FeedEditImageUpdate = (props) => {
  console.log('FeedEditImageUpdate-props', props);

  const { 
    t,
    state, 
    postInputChangeHandler, 
    inputBlurHandler, 
    adElementId,
    imageType,
    image150x500Preview,
    image600x130Preview,
    updateImagePreviewHandler,
  } = props;

  const [store, dispatch] = useStore();

  const [selectedFile, setSelectedFile] = useState();
  const [showSelectImage, setShowSelectImage] = useState();

  const [isLoading, setIsLoading] = useState('');

  // const [selectedFile, setSelectedFile] = useState([]);

  const fileSelectHandler = (event, imageType) => {
    console.log(event.target.files);
    setSelectedFile(event.target.files[0]);
    postInputChangeHandler(imageType, '', event.target.files,);
  };

  const isValidImage = () => {
    if (imageType === 'ad150x500ImageUrl' && state.postForm.ad150x500ImageUrl.valid) {
      return true;
    }

    if (imageType === 'ad600x130ImageUrl' && state.postForm.ad600x130ImageUrl.valid) {
      return true;
    }
    return false;
  }



  const updateImageHandler = async (event, imageType) => {
    try {
      event.preventDefault();
      setIsLoading(true);
  
      const ad150x500Image = state.postForm.ad150x500ImageUrl.value;
      const ad600x130Image = state.postForm.ad600x130ImageUrl.value;
      
      // console.log(ad600x130Image, ad150x500Image);
  
      const formData = new FormData();
  
      formData.append('adElementId', adElementId);
      formData.append('imageType', imageType);
  
      if (imageType === 'ad150x500ImageUrl' && ad150x500Image) {
        // if (!state.postForm.ad150x500ImageUrl.valid) {
        //   throw new Error('File is not valid');
        // }

        formData.append('image', ad150x500Image);
      }

      if (imageType === 'ad600x130ImageUrl' && ad600x130Image) {
        // if (!state.postForm.ad600x130ImageUrl.valid) {
        //   throw new Error('File is not valid');
        // }

        formData.append('image', ad600x130Image);
      }
  
  
      const result = await fetch(BASE_URL + `/ad-element-image/update-image`, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          // 'Content-Type': 'application/json'
        },
        body: formData,
      });
      
      const resData = await result.json();
      
      if (result.ok) {
        console.log(result, resData);

        const adList = store.userAdElements;
        
        const indexInAdList = adList.findIndex(ad => {
          return resData.data.adElementId === ad.adElementId; 
        });

        adList[indexInAdList] = resData.data;

        console.log('adList', adList, resData.data);
        dispatch('SET_USER_ADELEMENTS', adList);

        // updateSelectedPostHandler(resData.data);
        // startEditAdHandler(resData.data.adElementId);

        // updateImagePreviewHandler(imageType, null);
        // updateImagePreviewHandler(resData.data.adElementId, imageType);
        
        if (imageType === 'ad150x500ImageUrl') {
          updateImagePreviewHandler(resData.data.adElementId, imageType, resData.data.ad150x500ImageUrl);
        }
  
        if (imageType === 'ad600x130ImageUrl') {
          updateImagePreviewHandler(resData.data.adElementId, imageType, resData.data.ad600x130ImageUrl);
        }

        dispatch('SHOW_NOTIFICATION', {
          message: 'Image Updated',
        });

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        }, 1000* 5);

      } else {
        console.log(result, resData);
        throw new Error('something wrong');
      }

      setShowSelectImage(false);
      setSelectedFile(null);
      setIsLoading(false);
  
      // this.props.onFinishEdit(post);
      // this.setState({
      //   postForm: POST_FORM,
      //   formIsValid: false,
      //   imagePreview: null
      // });

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const deleteImageHandler = async (event, adElementId, imageType) => {
    try {
      event.preventDefault();
      setIsLoading(true);
  
      const url = BASE_URL + `/ad-element-image/delete-image?adElementId=${adElementId}&imageType=${imageType}`;
  
      const result = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        // body: formData,
      });
      
      const resData = await result.json();
      
      if (result.ok) {
        console.log(result, resData);

        const adList = store.userAdElements;
        
        const indexInAdList = adList.findIndex(ad => {
          return resData.data.adElementId === ad.adElementId; 
        });

        adList[indexInAdList] = resData.data;

        console.log('adList', adList, resData.data);
        dispatch('SET_USER_ADELEMENTS', adList);

        // updateSelectedPostHandler(resData.data);
        // startEditAdHandler(resData.data.adElementId);

        // updateImagePreviewHandler(imageType, null);
        // updateImagePreviewHandler(resData.data.adElementId, imageType);

        if (imageType === 'ad150x500ImageUrl') {
          updateImagePreviewHandler(resData.data.adElementId, imageType, resData.data.ad150x500ImageUrl);
        }
  
        if (imageType === 'ad600x130ImageUrl') {
          updateImagePreviewHandler(resData.data.adElementId, imageType, resData.data.ad600x130ImageUrl);
        }

        const successMessage = t('adForm.text19', 'Image Updated');

        dispatch('SHOW_NOTIFICATION', {
          message: `${successMessage}`,
        });

        setTimeout(() => {
          dispatch('CLEAR_NOTIFICATION');
        }, 1000* 5);

      } else {
        console.log(result, resData);
        throw new Error('something wrong');
      }

      setShowSelectImage(false);
      setIsLoading(false);
  
      // this.props.onFinishEdit(post);
      // this.setState({
      //   postForm: POST_FORM,
      //   formIsValid: false,
      //   imagePreview: null
      // });

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  let perviewImageBody;

  if (image150x500Preview && imageType === 'ad150x500ImageUrl') {
    perviewImageBody = (
      <div className="body150x500Container">    
        <img
          src={image150x500Preview}
          alt="150x500 image"
        />
      </div>
    );
  }

  if (image600x130Preview && imageType === 'ad600x130ImageUrl') {
    perviewImageBody = (
      <div className="body600x130Container">    
        <img
          src={image600x130Preview}
          alt="600x130 image"
        />
      </div>
    );
  }

  let imageDeleteButtonBody;

  if (imageType === 'ad150x500ImageUrl' && 
    state.postForm.ad150x500ImageUrl.value 
    // && 
    // !state.postForm.ad150x500ImageUrl.value.name
  ) {
    imageDeleteButtonBody = (
      <div className={classes.FEImageUpdateDelete}>
        <Button className={classes.FEImageUpdateDeleteButton}
          design='' mode='raised' size='smaller'
          disabled={isLoading}
          onClick={(event) => {
            deleteImageHandler(event, adElementId, imageType);
          }}
        >
          {t('adForm.text18', 'Back to default image')} (150x500)
      </Button>
      </div>
    )
  }

  if (imageType === 'ad600x130ImageUrl' && 
    state.postForm.ad600x130ImageUrl.value 
    // && 
    // !state.postForm.ad600x130ImageUrl.value.name
  ) {
    imageDeleteButtonBody = (
      <div className={classes.FEImageUpdateDelete}>
        <Button className={classes.FEImageUpdateDeleteButton}
          design='' mode='raised' size='smaller'
          disabled={isLoading}
          onClick={(event) => {
            deleteImageHandler(event, adElementId, imageType);
          }}
        >
          {t('adForm.text18', 'Back to default image')} (600x130)
        </Button>
      </div>
    );
}

  let selectImageBody;

  selectImageBody = (
    <div className={classes.FEImageUpdateSelectImageBody}>
      <div className={classes.FEImageUpdateUploadTitle}>
        {t('adForm.text16', 'Upload New File')}
      </div>

      <input type="file" name="file" 
        onChange={(event) => {fileSelectHandler(event, imageType)}}
      />

      <div className="feedEdit__aboutMediaFile">
        {/* think about later about size video and web thing */}
        {/* (Media File should be jpg, jpeg, png, gif file, and less than 200KB) */}
        {/* ({t('adForm.text22', 'Media File should be jpg, jpeg, png, gif file, and less than 200KB')}) */}
        (Media File should be jpg, jpeg, png, gif file, and less than 1MB)
      </div>

      {selectedFile && (
        <section>
          <div>
            {t('adForm.text25', 'image preview')}
          </div>

          {perviewImageBody}

          <div className={classes.FEImageUpdateUploadButtons}>
            <Button 
              design='' mode='flat' size='smaller'
              disabled={isLoading}
              onClick={() => {
                setSelectedFile(null);

              }}
            >
              {t('adForm.text21', 'Cancel')}
            </Button>
            <Button 
              design='' mode='raised' size='smaller'
              disabled={isLoading || !isValidImage()}
              onClick={(event) => {
                updateImageHandler(event, imageType);
              }}
            >
              {t('adForm.text20', 'Upload')}
            </Button>
          </div>
          
        </section>
      )}

      <ImagesForReuse 
        // postInputChangeHandler={postInputChangeHandler}
        // updateImageHandler={updateImageHandler}
        updateImagePreviewHandler={updateImagePreviewHandler}
        adElementId={adElementId}
        imageType={imageType}
        setShowSelectImage={setShowSelectImage}
      />

      <span>{imageDeleteButtonBody}</span>
    </div>
  )

  return (
    <Fragment>

      {imageType === 'ad150x500ImageUrl' && (
        <div 
          onClick={() => {setShowSelectImage(!showSelectImage); }}
        >
          <span className={classes.FEImageUpdateTitle}>
            {t('adForm.text15', 'Update image for')} 150x500 {marks.triangleDown}
          </span>
        </div>
      )}
      {imageType === 'ad600x130ImageUrl' && (
        <div
          onClick={() => {setShowSelectImage(!showSelectImage); }}
        >
          <span className={classes.FEImageUpdateTitle}>
            {t('adForm.text15', 'Update image for')} 600x130 {marks.triangleDown}
          </span>
        </div>
      )}

      {showSelectImage && (
        selectImageBody
      )}

    </Fragment>
  );
};

export default withI18n()(FeedEditImageUpdate);
