import React, { useState, useEffect } from 'react';
// import './App.css';
import { Redirect, useHistory, Link } from 'react-router-dom';
import i18next from 'i18next';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import PaymentRegister from './PaymentRegister';

import ChargeRecord from './ChargeRecord';
import CustomerMethod from './CustomerMethod';
import Loader from '../../components/Loader/Loader';
import PaymentSetup from './PaymentSetup';

// import { useStore } from '../../hooks-store/store';
import { useStore } from '../../hook-store/store';
// import { fetchPrices } from '../../utils/stripe';
import { BASE_URL } from '../../App';

import classes from './Register.module.css';

const Payment = (props) => {

  // // const url = new URL(window.location.href);
  // // const queryParams = url.searchParams;
  // // const customerEmail = queryParams.get('customer-email');

  // const customerEmail = localStorage.getItem('email');
  const lsCustomerId = localStorage.getItem('customerId');
  // // console.log(customerEmail);
  
  // const lsToken = localStorage.getItem('token');

  // // const history = useHistory();

  const [store, dispatch] = useStore();
  // console.log(state);
  const { i18n } = store;

  // const [email, setEmail] = useState('jenny.rosen@example.com');
  // const [email, setEmail] = useState(localStorage.getItem('email'));
  // const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const [stripePromise, setStripePromise] = useState('');


  useEffect(() => {
    getStripPromise();
  },[]);



  const getStripPromise = () => {
    setIsLoading(true);

    fetch(BASE_URL + '/price/config')
      .then(r => {
        return r.json()
      })
      .then(resData => {
        console.log('stripe-config resData', resData);
        const stripePromise = loadStripe(resData.publishableKey);
        setStripePromise(stripePromise);
        setIsLoading(false);
    
      })
      .catch(err => {
        console.log('stripe-config err', err);
        setIsLoading(false);
      });
  };



  return (
    <main>
      {isLoading && 
        <div className={classes.subscriptionLoader}><Loader /></div>
      }
      {error && error}

      {lsCustomerId && stripePromise && (
        <div>
          <PaymentSetup stripePromise={stripePromise} />
          <CustomerMethod />
          <ChargeRecord />
        </div>
      )} 

      <PaymentRegister stripePromise={stripePromise}/>


    </main>
  );
}

export default Payment;
