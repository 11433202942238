import React, { Fragment } from "react";
import { withI18n } from "react-i18next";

import { transformLocaleDate, transfromLocaleDate } from "../../../util/timeFormat";
import "./FeedEdit.css";
import classes from "./FeedEditTimeItems.module.css"

const FeedEditTimeItems = (props) => {
  console.log('FeedEditTimeItems.js-props', props);

  const {
    state,
    startDateChangeHandler,
    startTimeChangeHandler,
    endDateChangeHandler,
    endTimeChangeHandler,
    isValidTimes,
    t,
  } = props;

  const selectStyle = { 
    height:"2rem", 
    padding: "0.25rem", 
    marginLeft:"1rem"
  };

  let titleStyle;
  if (state.postForm 
    && state.postForm.start.value 
    && state.postForm.end.value
    && !isValidTimes
  ) {
      titleStyle = {
        color: 'red',
      }
  }

  return (
    <Fragment>
      <div className={classes.feedEditTimeItemsTitle} style={titleStyle}>
        {t('general.text22', 'Start time')} {t('general.text39', 'and')} {t('general.text23', 'End time')}
      </div>
      <div>
        ({t('adForm.text07', 'Start and end time should be later than now')})
      </div>
      <label className={classes.feedEditTimeItemsLabel} htmlFor="start-date">
        {t('general.text22', 'Start time')}
      </label>
        <input
          className={classes.feedEditTimeItemsDateInput}
          id="start-date"
          type="date"
          onChange={startDateChangeHandler}
          // value={state.startDateValue}
          // defaultValue={transformLocaleDate(start)}
          // defaultValue='2022-02-31'
        />
        <select
          // className={classes.feedEditTimeItemsTimeInput}
          style={selectStyle}
          name="startTime"
          id="start-time"
          className="feedEdit__selectStyle"
          // value={state.startTimeValue}
          onChange={startTimeChangeHandler}
        >
          <option selected={true} value="">
            {t('general.text41', 'hour')}
          </option>
          <option value="0">12 AM</option>
          <option value="1">1 AM</option>
          <option value="2">2 AM</option>
          <option value="3">3 AM</option>
          <option value="4">4 AM</option>
          <option value="5">5 AM</option>
          <option value="6">6 AM</option>
          <option value="7">7 AM</option>
          <option value="8">8 AM</option>
          <option value="9">9 AM</option>
          <option value="10">10 AM</option>
          <option value="11">11 AM</option>
          <option value="12">12 PM</option>
          <option value="13">1 PM</option>
          <option value="14">2 PM</option>
          <option value="15">3 PM</option>
          <option value="16">4 PM</option>
          <option value="17">5 PM</option>
          <option value="18">6 PM</option>
          <option value="19">7 PM</option>
          <option value="20">8 PM</option>
          <option value="21">9 PM</option>
          <option value="22">10 PM</option>
          <option value="23">11 PM</option>
        </select>

      {/* {this.state.startDateValue} {this.state.startTimeValue} */}
      <div className={classes.feedEditTimeItemsSelectedTime}>
        {t('general.text22', 'Start time')}: {' '}
        {state.postForm.start.value &&
          new Date(state.postForm.start.value).toLocaleString()}

      </div>

      <label className={classes.feedEditTimeItemsLabel} htmlFor="end-date">
        {t('general.text23', 'End time')}
      </label>
      <input
        className={classes.feedEditTimeItemsDateInput}
        id="end-date"
        type="date"
        onChange={endDateChangeHandler}
        // value={state.endDateValue}
      />

      <select
        style={selectStyle}
        name="endTime"
        id="end-time"
        className="feedEdit__selectStyle"
        // value={this.state.startTimeValue}
        onChange={endTimeChangeHandler}
      >
        <option selected={true} value="">
          {t('general.text41', 'hour')}
        </option>
        <option value="0">12 AM</option>
        <option value="1">1 AM</option>
        <option value="2">2 AM</option>
        <option value="3">3 AM</option>
        <option value="4">4 AM</option>
        <option value="5">5 AM</option>
        <option value="6">6 AM</option>
        <option value="7">7 AM</option>
        <option value="8">8 AM</option>
        <option value="9">9 AM</option>
        <option value="10">10 AM</option>
        <option value="11">11 AM</option>
        <option value="12">12 PM</option>
        <option value="13">1 PM</option>
        <option value="14">2 PM</option>
        <option value="15">3 PM</option>
        <option value="16">4 PM</option>
        <option value="17">5 PM</option>
        <option value="18">6 PM</option>
        <option value="19">7 PM</option>
        <option value="20">8 PM</option>
        <option value="21">9 PM</option>
        <option value="22">10 PM</option>
        <option value="23">11 PM</option>
      </select>

      <div className={classes.feedEditTimeItemsSelectedTime}>
        {t('general.text23', 'End time')}: {' '}
        {state.postForm.end.value &&
          new Date(state.postForm.end.value).toLocaleString()}
      </div>
    </Fragment>
  );
};

export default withI18n()(FeedEditTimeItems);
