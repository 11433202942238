
export const cardExpire = (expMonth, expYear) => {
  // console.log(expMonth, expYear);
  const currentDate = new Date().toLocaleDateString();
  const currentMonth = Number(currentDate.split('/')[0]);
  const currentYear = Number(currentDate.split('/')[2]);

  if (expYear < currentYear) {
    return true;
  }
  if (expYear === currentYear && expMonth < currentMonth) {
    return true;
  }

  return false;
};


export const nearToExpire = (expMonth, expYear) => {
  // console.log(expMonth, expYear);
  const currentDate = new Date().toLocaleDateString();
  const currentMonth = Number(currentDate.split('/')[0]);
  const currentYear = Number(currentDate.split('/')[2]);

  if (expYear === currentYear && expMonth === currentMonth) {
    return true;
  }

  return false;
};

export const getCardPaymentMethods = async (url, token) => {
  try { 
    const lsCustomerId = localStorage.getItem('customerId');
    
    if (lsCustomerId) {
      const response = await fetch(url + `/payment/customer-card-payment-methods?customerId=${lsCustomerId}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          // 'Accept': 'application/json',
        },
      });

      const resData = await response.json();

      console.log(response, resData);

      // setCustomerMethods(resData.customerMethods);
      // console.log(methods, adNetMethods);
      // setIsLoading(false);
      return resData;
    }

  } catch(err) {
    console.log(err);
    // setIsLoading(false);
  }
};

export const updateDefaultPaymentMethod = async (url, token, paymentMethodId, customerId) => {

  try { 
    // const lsCustomerId = localStorage.getItem('customerId');
    
    const response = await fetch(url + `/payment/default-payment-method-metadata`, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        // 'Accept': 'application/json',
      },
      body: JSON.stringify({
        paymentMethodId: paymentMethodId,
        customerId: customerId,
      })
    });

    // if (!response.ok) {
    //   throw new Error('something wrong');
    // } 
    const resData = await response.json();
    console.log(response, resData);
    // setIsLoading(false);
    // getCardPaymentMethods();

    return resData;

  } catch(err) {
    console.log(err);
    // setIsLoading(false);
  }
};


export const postGetCustomerPaymentIntents = async (url, token, customerId) => {
  try { 
    const response = await fetch(url + `/payment/post-get-customer-payment-intents`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        // 'Accept': 'application/json',
      },
      body: JSON.stringify({
        customerId: customerId,
      })
    });

    if (!response.ok) {
      throw new Error('error occured');
    } 
    
    const resData = await response.json();
    console.log(response, resData);

    return resData;

  } catch(err) {
    console.log(err);
    throw err;
    // setIsLoading(false);
  }
};
