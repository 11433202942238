import { initStore } from "./store";

const configureStore = () => {
  const actions = {

    SET_CUSTOMER_METHODS:(state, payload) => {
      return {
        ...state,
        paymentStore: {
          ...state.paymentStore,
          customerMethods: payload,
        }
      };
    },
    SET_VALID_DEFAULTMETHOD: (state, payload) => {
      return {
        ...state,
        paymentStore: {
          ...state.paymentStore,
          validDefaultMethod: payload,
        }
      };
    },
    SET_ISSTRIPE_LOADING: (state, payload) => {
      return {
        ...state,
        paymentStore: {
          ...state.paymentStore,
          isStripeLoading: payload,
        }
      };
    }
  };

  initStore(actions, {
    paymentStore: { 
      customerMethods: [],
      validDefaultMethod: null,
      isStripeLoading: false,
    }
  });
};

export default configureStore;
