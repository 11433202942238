import React from 'react';
import { Fragment, useState } from 'react';
import { useTranslation } from "react-i18next/hooks";
import { CSVLink } from "react-csv";
// import styled from 'styled-components'

import Button from '../../components/Button/Button';

import { useStore } from '../../hook-store/store'
import { createDisplayId } from '../../util/ad-visit';

// import classes from './Pagination.module.css';



function AdReportDownload(props) {
  const { columns, data } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();

  const { 
    selectedAdElementId,
   } = store.adReportStore;
  // const listForTable = store.adReportStore.listForTable;
  // const totalResult = store.adReportStore.totalResult;
  // console.log('store in Pyagination.js', store);


  const createCsvData = (columns, data) => {
    let csvData = [];

    if (columns && data) {
      const csvHead = [
        'date', 
        'display', 
        'visit or view', 
        'visit or view rate', 
        'charge'
      ];
      // for (const column of columns) {
      //   csvHead.push(column.accessor);
      // }
      // console.log(data)
  
      const dataList = data.map(element => {
        const elArray = [
          element.date, 
          element.displayNum, 
          element.visitNum, 
          element.vRate, 
          element.charge
        ];
      
        // for (const key in element) {
        //   elArray.push(element[key]);
        // }


        return elArray;
      });
  
      // console.log(dataList);
      csvData = [csvHead].concat(dataList);
      
      // console.log(csvData);
      return csvData;
    }
  };

  
  let fileName = `ad-report.csv`;

  if (selectedAdElementId) {
    fileName = `ad-report-${createDisplayId(selectedAdElementId)}.csv`;
  }
  
  if (!selectedAdElementId || selectedAdElementId === 'all') {
    fileName = 'ad-report.csv';
  }


  return (
    <Fragment>
      <CSVLink
        data={createCsvData(columns, data)}
        filename={fileName}
        className="btn btn-primary"
        target="_blank"
      >
        <Button design="flat" type="submit" size="smaller"
        >
          {t('adReport.text17', 'CSV file download')}
        </Button>
      </CSVLink>

    </Fragment>
  )
}

export default AdReportDownload;
