import React from 'react';
import { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next/hooks';

import AdItems from '../../components/UserAdElements/SingleAdElement/AdItems';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import PageDescription from './PageDescription';
import { useStore } from '../../hook-store/store';
import { getUserDataForStore } from '../../util/user';

import GroupRightElements from '../../components/GroupTalk/GroupAdElements/GroupRightElements/GroupRightElements';
import GroupTopElements from '../../components/GroupTalk/GroupAdElements/GroupTopElements/GroupTopElements';

import { authPageLink, authSignupPageLink, BASE_URL } from '../../App';
import './NotPageFound.css';


const NotPageFound = props => {
  // console.log('need-to-login-props', props);
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // console.log('store in NotPageFound.js', store);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (props.isAuth) {
      getUserDataForStore(BASE_URL, localStorage.getItem('token'))
      .then(result => {
        console.log(result);
  
        dispatch('SET_USERDATA', result.userData);
      })
      .catch(err => {
        console.log(err);
      });
    }
  }, [props.isAuth]);

  console.log(props.history);
  console.log(document.referrer);
  // const containAuthPage = document.referrer.includes('http://localhost:8501');
  
  const currentUrl = new URL(window.location.href);
  const queryParams = currentUrl.searchParams;
  // console.log(currentUrl);

  if (!queryParams.get('tokenForCasTGT') && !queryParams.get('casTGT')) {
    // props.history.push('/feed/posts');
  }

  if (props.isAuth) {
    props.history.push('/create-ad-element');
  }

  let body;
  if (props.isLoading) {
    body = (<div className="notPageFound__Loader">
      <Loader />
      </div>);
  } 
  if (queryParams.get('tokenForCasTGT') && !props.isAuth) {
    body = (<div className="notPageFound__Loader">
      <Loader />
    </div>);
  }
  else {
    body = ( 
    <div className="notPageFound__container">
      {/* <GroupTopElements />
      <GroupRightElements /> */}
      
      {props.isAuth && (
        <div>
          <div className="notPageFound__textLink"
            style={{paddingTop:"4rem"}}
          >
            <div>auth-contents</div>
            <Link 
              to="/create-ad-element">
                list-page
            </Link>
          </div>
        </div>
      )}
      {!props.isAuth && (
        <div className="notPageFound">

            <a className="notPageFound__linkButton" href={authPageLink} >
              <Button
                    mode="raised" type="submit" design="success"
                    // disabled={!props.replyInput || props.commentLoading}
              >
                {/* Login */}
                {t('general.text11')}
              </Button>
            </a>

          <a className="notPageFound__linkButton" href={authSignupPageLink} >
            <Button
                  mode="raised" type="submit" design="success"
                  // disabled={!props.replyInput || props.commentLoading}
            >
              {/* Signup */}
              {t('general.text12')}
            </Button>
          </a>
        </div>
      )}

    </div>
    );
  }

  return (
    <div>
      <PageDescription />
      {body}

      {/* <img src={GifImage} height={'100'} /> */}
      {/* <MapMainComponent /> */}

    </div>
  );
}

export default NotPageFound;