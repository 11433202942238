import React from "react";
import { 
  Fragment, 
  useEffect, 
  useState, 
} from "react";
import { withI18n } from "react-i18next";

import { useStore } from "../../hook-store/store";

import classes from './AreaControl.module.css';

function AreaControl(props) {
  // console.log('AreaControl.js-props', props);
  const { 
    targetLocationChangeHandler, 
    selectedPost,
    t,
  } = props;

  const [store, dispatch] = useStore();

  const [markerRadiusKm, setMarkerRadiusKm] = useState(2000);

  const valueObj = {
    markerRadiusKm: markerRadiusKm,
  };

  useEffect(() => {
    if (selectedPost && selectedPost.targetLocation && selectedPost.targetLocation.radiusKm) {
      setMarkerRadiusKm(selectedPost.targetLocation.radiusKm);
    }
  },[selectedPost]);

  useEffect(() => {
    if (Number(markerRadiusKm) !== NaN 
    && markerRadiusKm >= 1000
    && markerRadiusKm <= 20000
    ) {
      dispatch('SET_AREACONTROL_VALUES', valueObj);
    }
  },[markerRadiusKm]);

  useEffect(() => {
    targetLocationChangeHandler(
      store.markerCenter.lat,
      store.markerCenter.lng,
      store.areaControlValues.markerRadiusKm,
    );
  },[store.markerCenter.lat, store.markerCenter.lng, store.areaControlValues.markerRadiusKm]);

  const markerRadiusKmHandler = (event) => {
    // console.log(Number(event.target.value));
    setMarkerRadiusKm(event.target.value);
  };



  return (
    <Fragment>
      <div className={classes.areaControlTitle}>
        {t("adForm.text08", 'Area Size')} (1000 - 20000 km)
      </div>
      <input 
        className={classes.areaControlInput}
        type="text"
        onChange={(event) => {markerRadiusKmHandler(event); }}
        value={markerRadiusKm}
      />
      <div className={classes.areaControlRadius}>
        {t("general.text33", 'Radius')}: {store.areaControlValues.markerRadiusKm} km
      </div>
      {store.markerCenter.lat && store.markerCenter.lat &&
        <div>
          ({t("general.text34", 'Center')}: {t("general.text31", 'Latitude')} {store.markerCenter.lat.toFixed(2)}, {t("general.text32", 'Longitude')} {store.markerCenter.lng.toFixed(2)})
        </div>
      }
      {/* <button
        onClick={() => {
          dispatch('SET_AREACONTROL_VALUES', valueObj);
        }
      }
      >
        get-areacontrol-values
      </button> */}
    </Fragment>
  );
}



export default withI18n()(AreaControl);