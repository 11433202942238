import React from "react";
import { 
  Fragment, 
  useEffect, 
  useState, 
  useRef, 
  useMemo, 
  useCallback 
} from "react";
import {
  MapContainer,
  TileLayer,
  useMapEvents,
  MapConsumer,
  Marker, 
  Popup,
  Circle,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
// import L from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet'
// import icon from "../constants";

import { useStore } from "../../hook-store/store";

// import "../styles.css";



const center = {
  lat: 0.0,
  lng: 0.0,
}

function DraggableMarker(props) {
  const { selectedPost } = props;
  console.log('DraggableMarker-props', props);

  const [store, dispatch] = useStore();

  const [draggable, setDraggable] = useState(true);
  const [position, setPosition] = useState(center);


  useEffect(() => {
    console.log('position', position);

    dispatch('SET_MARKER_CENTER', position);

    //// move marker when selectedPost locatin is different from {0,0}
    if (selectedPost && selectedPost.targetLocation && selectedPost.targetLocation.latitude 
      && position.lat === 0 && position.lng === 0
    ) {
      setPosition({
        lat: selectedPost.targetLocation.latitude,
        lng: selectedPost.targetLocation.longitude,
      })
      dispatch('SET_MARKER_CENTER', {
        lat: selectedPost.targetLocation.latitude,
        lng: selectedPost.targetLocation.longitude,
      });
    }
  },[position, selectedPost]);

  
  useEffect(() => {
    fetch('http://localhost:4003/example')
    .then(res => {
      return res.json();
    })
    .then(resData => {
      console.log(resData);
      let latLng = [0, 0];
      if (resData.data.geo && resData.data.geo.ll) {
        latLng = resData.data.geo.ll;
      }
      console.log(latLng);

      setPosition({
        lat: latLng[0],
        lng: latLng[1],
      })

      // console.log(store);
    })
    .catch(err => {
      console.log(err);
      // console.log(store);
    })
  },[]);

  const markerRef = useRef(null)
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setPosition(marker.getLatLng())
        }
      },
    }),
    [],
  )
  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d)
  }, [])

  // const position = [51.505, -0.09]
  // const position = [50.5, 31.5]

  return (
    <Fragment>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
        draggable={draggable}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
        icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}
      >
        {/* <Popup minWidth={90}>
          <span onClick={toggleDraggable}>
            {draggable
              ? 'Marker is draggable'
              : 'Click here to make marker draggable'}
          </span>
        </Popup> */}
      </Marker>
      <Circle
        center={position}
        radius={1*10**3}
        radius={store.areaControlValues.markerRadiusKm 
          ? store.areaControlValues.markerRadiusKm * 10**3 
          : 1000 * 10**3
        }
      >
      </Circle>
    </Fragment>
  );
}

export default DraggableMarker;