// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
// import Generic from './Generic.jsx';

import { useTranslation } from "react-i18next/hooks";

import { isVideoFile } from '../../../util/image';
// import Button from "../../Button/Button";
// import { useStore } from "../../../hook-store/store";
// import { BASE_URL } from "../../../App";

// import classes from "../../../components/Feed/FeedEdit/feedEditImagePreview/ImagePreviewContents.module.css";
import classes from './AdItems2.module.css';

import GrayImage from '../../../images/light-gray-square-300.jpg';

const AdItems2 = (props) => {
  // console.log("AdItems.js-props", props);
  const { ad } = props;

  const [t] = useTranslation("translation");

  // const [store, dispatch] = useStore();

  let body300x65 = (
    <div>
      <div>300x65</div>
      <div className="body300x65Container">
        <div className="body300x65Text">
          <div className="body300x65TextContents">
            <div className="body300x65TextTitle">{ad.title}</div>
            <div className="body300x65TextDescription">
              {ad.description}
            </div>
          </div>
        </div>
        <div>
          <img
            className="body300x65Image"
            src={ad.adImageUrl ? ad.adImageUrl : GrayImage}
            alt="300x65 image"
          ></img>
        </div>
      </div>
    </div>
  );

  if (ad.ad600x130ImageUrl) {
    body300x65 = (
      <div>
        <div>300x65</div>
        <div className="body300x65Container">    
            <img
              src={ad.ad600x130ImageUrl}
              alt="300x65 image"
            />
        </div>
      </div>
    );
  }



  const body300x300 = (
    <div>
      <div>300x300</div>
      <div className="body300x300Container">
        <div>
          <img
            className="body300x300Image"
            src={ad.adImageUrl ? ad.adImageUrl : GrayImage}
            alt="300x300 image"
          ></img>
        </div>
        <div className="body300x300TextContainer">
          <span className="body300x300TextTitle">{ad.title}</span>
          <span className="body300x300TextDescription">
            {ad.description}
          </span>
        </div>
      </div>
    </div>
  );

  const bodyInPosts = (
    <div>
      <div>
        {t('adForm.text10', 'between posts')} (in feed posts)
      </div>
      <div className="bodyInPostContainer">
        <div className="bodyInPostContents">
          <img
            className="bodyInPostImageContainer"
            src={ad.adImageUrl ? ad.adImageUrl : GrayImage}
            alt="300x300 image"
          ></img>
          <div className="bodyInPostTextContainer">
            <div className="bodyInPostTextTitle">{ad.title}</div>
            <div className="bodyInPostTextDescription">
              {ad.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );


  let body150x500 = (
    <div>
      <div>150x500</div>
      <div className="body150x500Container">    
        <div className="body150x500Text">
            <div className="body150x500TextContents">
              <div className="body150x500TextTitle">
                {ad.title}
              </div>
              <div className="body150x500TextDescription">
                {ad.description}
              </div>
            </div>
          </div>
          <img
            className="body150x500Image"
            src={ad.adImageUrl ? ad.adImageUrl : GrayImage}
            alt="150x500 image"
          />
      </div>
    </div>
  );

  if (ad.ad150x500ImageUrl) {
    body150x500 = (
      <div>
        <div>150x500</div>
        <div className="body150x500Container">    
            <img
              src={ad.ad150x500ImageUrl}
              alt="150x500 image"
            />
        </div>
      </div>
    );
  }


  let body450x90 = (
    <div>
      <div>450x90</div>
      <div className="body450x90Container">
        <div className="body450x90Text">
          <div className="body450x90TextContents">
            <div className="body450x90TextTitle">{ad.title}</div>
            <div className="body450x90TextDescription">
              {ad.description}
            </div>
          </div>
        </div>
        <div>
          <img
            className="body450x90Image"
            src={ad.adImageUrl ? ad.adImageUrl : GrayImage}
            alt="450x90 image"
          ></img>
        </div>
      </div>
    </div>
  );

  if (ad.ad600x130ImageUrl) {
    body450x90 = (
      <div>
        <div>450x90</div>
        <div className="body450x90Container"
          // style={{justifyContent: 'space-around', backgroundColor: "transparent"}}
        >    
            <img
              style={{width: "450px", height:"90px"}}
              src={ad.ad600x130ImageUrl}
              alt="450x90 image"
            />
        </div>
      </div>
    );
  }


  let body600x130 = (
    <div>
      <div>600x130</div>
      <div className="body600x130Container">    
        <div className="body600x130Text">
            <div className="body600x130TextContents">
              <div className="body600x130TextTitle">
                {ad.title}
              </div>
              <div className="body600x130TextDescription">
                {ad.description}
              </div>
            </div>
          </div>
          <img
            className="body600x130Image"
            src={ad.adImageUrl ? ad.adImageUrl : GrayImage}
            alt="600x130 image"
          />
      </div>
    </div>
  );

  if (ad.ad600x130ImageUrl) {
    body600x130 = (
      <div>
        <div>600x130</div>
        <div className="body600x130Container">    
            <img
              src={ad.ad600x130ImageUrl}
              alt="600x130 image"
            />
        </div>
      </div>
    );
  }


  let bodyVideo;

  if (ad.adImagePath) {
    const fileType = ad.adImagePath.split('.')[ad.adImagePath.split('.').length - 1].toLowerCase();
    
    if (isVideoFile(fileType)) {
      bodyVideo = (
        <video
          className="videoAdPreview"
          // style={{ width:"400px", maxWidth:"100%" }} 
          src={ad.adImageUrl} controls
        />
      );
    } 
  }
  

  return (
    <Fragment>
      {!bodyVideo && (
        <div>
          <div className={classes.adItems2Container}>
            {body300x65}
            {body300x300}

            {body150x500}
            {body450x90}
            {body600x130}
          </div>
            {bodyInPosts}
        </div>
      )}

      {bodyVideo && (
        <div>
          <div className={classes.adItems2Container}>
            {bodyVideo}
          </div>
          <div className={classes.adItems2VideoDuration}>
            <span>video-length</span>
            {' '}
            <strong>
              {ad.videoDuration} seconds-duration
            </strong>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AdItems2;
