import React from 'react'
// import styled from 'styled-components'
import { useTable, usePagination, useSortBy } from 'react-table'
import { useTranslation } from "react-i18next/hooks";

import classes from './Table.module.css';

// import makeData from './makeData'

// const Styles = styled.div`
//   padding: 1rem;

//   table {
//     border-spacing: 0;
//     border: 1px solid black;

//     tr {
//       :last-child {
//         td {
//           border-bottom: 0;
//         }
//       }
//     }

//     th,
//     td {
//       margin: 0;
//       padding: 0.5rem;
//       border-bottom: 1px solid black;
//       border-right: 1px solid black;

//       :last-child {
//         border-right: 0;
//       }
//     }
//   }

//   .pagination {
//     padding: 0.5rem;
//   }
// `

function Table({ columns, data }) {
  const [t] = useTranslation("translation");

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
  )

  // console.log(headerGroups, page)

  // Render the UI for your table
  return (
    <>
      <pre>
        <code>
          {/* {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
            },
            null,
            2
          )} */}
        </code>
      </pre>
      <table {...getTableProps()}
        className={classes.table} // style={{ border: 'solid 1px blue' }}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}                 
                  className={classes.tableHead}
                  // style={{
                  //   borderBottom: 'solid 3px red',
                  //   background: 'aliceblue',
                  //   color: 'black',
                  //   fontWeight: 'bold',
                  // }}
                >
                  {column.render('Header')}
                  <span className={classes.tableHeadSort}
                    // style={{fontSize:"0.6rem", cursor:"pointer", margin:"0 0.5rem"}}
                  >
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <span> &#x25BE; </span>   // ' 🔽'
                        : <span> &#x25B4; </span>   //' 🔼'
                      : <span> &#x25BE; </span>}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}
                  className={classes.tableRow}
                  // style={{
                  //   padding: '10px',
                  //   border: 'solid 1px gray',
                  //   background: 'papayawhip',
                  // }}
                  >
                    {cell.render('Cell')}
                  </td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
      <div className="pagination">
        <div className={classes.paginatorRow}>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <span>
            <strong>
              {pageIndex + 1}/{pageOptions.length}
            </strong>{' '}
          </span>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
        </div>
        {/* <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span> */}
        <div className={classes.paginatorRow2}>
          <span>
            {t('adReport.text10', 'go to page')}:{' '}
            <input className={classes.paginatorInput}
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '4rem' }}
            />
          </span>{' '}
          <select className={classes.paginatorSelect}
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {/* {[10, 20, 30, 40, 50].map(pageSize => ( */}
            {[10, 20, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {t('adReport.text11', 'display')} {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  )
}

export default Table;