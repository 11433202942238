import React, { useState, useEffect } from 'react';
// import './App.css';
import { Redirect, useHistory, Link } from 'react-router-dom';
import i18next from 'i18next';

// import CustomerMethod from './CustomerMethod';
// import PaymentSetup from './PaymentSetup';
import Loader from '../../components/Loader/Loader';

import * as paymentUtil from '../../util/payment';
import { useStore } from '../../hook-store/store';
// import { isAdminUser } from '../../util/admin-ad-element';
import { pauseUserFutureEndAds } from '../../util/ad-user';
import { BACKEND_URL, BASE_URL } from '../../App';

import classes from './Register.module.css';

const PaymentRegister = (props) => {

  const { stripePromise } = props;
  // const url = new URL(window.location.href);
  // const queryParams = url.searchParams;
  // const customerEmail = queryParams.get('customer-email');

  const customerEmail = localStorage.getItem('email');
  // console.log(customerEmail);
  
  const lsToken = localStorage.getItem('token');

  // const history = useHistory();

  const [store, dispatch] = useStore();
  // console.log(state);
  const { i18n } = store;

  // const [email, setEmail] = useState('jenny.rosen@example.com');
  const [email, setEmail] = useState(localStorage.getItem('email'));
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  
  //// get prices data and login or register stripe user
  useEffect(() => {
    // if (customerEmail && store.userData && !isAdminUser(store.userData)) {
    if (customerEmail) {
        setIsLoading(true);
        dispatch('SET_ISSTRIPE_LOADING', true);
  
  
        retrieveEmailCustomerHandler(customerEmail, lsToken)
          .then(res => {
            console.log(res);
  
            getValidDefaultMethodHandler(BASE_URL, lsToken);
          })
          .catch(err => {
            console.log(err);
            if (err.statusCode === 404) {
              // add customer
              // console.log('add cucstomer')
              registerUser(customerEmail, lsToken);
            }
            else {
              setError(
                <div>
                    error-occured
                  {/* <div>
                    {i18next.t('subText38', 'Subscription service is not available now.')}
                  </div>
                  <div>
                    <Link to='/'>    
                      {i18next.t('subText12', 'Go Back')}
                    </Link>
                  </div> */}
                </div>
              );
              setIsLoading(false);
              dispatch('SET_ISSTRIPE_LOADING', false);
            }
          });

    }
    else {
      setError(
        <div>
          login-required
          {/* <div>
            {i18next.t('subText39', 'Login is required to access subscription service.')}
          </div>
          <div>
            <Link to='/'>    
              {i18next.t('subText12', 'Go Back')}
            </Link>
          </div> */}
        </div>
      );
    }
  },[]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch('SET_ISSTRIPE_LOADING', true);

      registerUser(email, lsToken);
  };

  const registerUser = (emailAddr, token) => {
    return new Promise( async (resolve, reject) => {
      const { customer, error } = await fetch(BASE_URL + '/customer/create-customer', {
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: emailAddr,
        }),
      })
      .then(r => {
        return r.json()
      })
      // .then(resData => {
      //   console.log(resData);
      // })
      // .catch(err => {
      //   console.log(err);
      // });
  
      if (error) {
        // console.log(error);
        setIsLoading(false);
        dispatch('SET_ISSTRIPE_LOADING', false);
        reject(error);
      }
  
      if (customer) {
        // console.log(customer);
        localStorage.setItem('customerId', customer.id);
        localStorage.setItem('customerEmail', customer.email);
        setCustomer(customer);
        setIsLoading(false);
        dispatch('SET_ISSTRIPE_LOADING', false);
        resolve(customer)

        window.location.reload();
      }
    });
    };


  const retrieveEmailCustomerHandler = async (emailAddr, token) => {
    return new Promise(async (resolve, reject) => {

      setIsLoading(true);
      dispatch('SET_ISSTRIPE_LOADING', true);
  
      const { customer, error } = await fetch(BASE_URL + '/customer/retrieve-email-customer', {
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          email: emailAddr,
        }),
      })
      .then(r => {
        return r.json()
      })
      // .then(resData => {
      //   console.log(resData);
      // })
      // .catch(err => {
      //   console.log(err);
      // });
  
      console.log(customer, error);
  
      if (customer) {
        localStorage.setItem('customerId', customer.id);
        localStorage.setItem('customerEmail', customer.email);
        setCustomer(customer);
        setIsLoading(false);
        dispatch('SET_ISSTRIPE_LOADING', false);
        resolve(customer);
      }
      if (error) {
        // console.log(error);
        setIsLoading(false);
        dispatch('SET_ISSTRIPE_LOADING', false);
        reject(error);
      }
    })

  };


  const getValidDefaultMethodHandler = async (url, token) => {
    try {
      setIsLoading(true);
      dispatch('SET_ISSTRIPE_LOADING', true);
      // setError('');

      const result = await paymentUtil.getCardPaymentMethods(url, token);

      if (result && result.customerMethods) {

        const methodList = [];
        const expiredMethodList = [];

        for (const method of result.customerMethods) {
          const cardExpire = paymentUtil.cardExpire(method.card.exp_month, method.card.exp_year);
          if (!cardExpire) {
            methodList.push(method);
          } else {
            expiredMethodList.push(method);
          }
        }

        console.log('methodLists', methodList, expiredMethodList);

        // if (methodList.length === 1) {
        //   dispatch('SET_VALID_DEFAULTMETHOD', methodList[0]);
        // }
        if (methodList.length > 0) {
          const isDefaultMethod = methodList.find(method => {
            return method.metadata.adNetworkDefault;
          });

          if (isDefaultMethod) {
            dispatch('SET_VALID_DEFAULTMETHOD', isDefaultMethod);
          } else {
            dispatch('SET_VALID_DEFAULTMETHOD', null);

            // // pause future end ads when valid default method not exist
            pauseUserFutureEndAds(BASE_URL,localStorage.getItem('token'));

            // // // pause future end ads not all expired methods
            // if (expiredMethodList.length < result.customerMethods.length) {
            //   pauseUserFutureEndAds(BASE_URL,localStorage.getItem('token'));
            // }
          }
        } else {
          dispatch('SET_VALID_DEFAULTMETHOD', null);

          // // pause future end ads when valid method not exist
          pauseUserFutureEndAds(BASE_URL,localStorage.getItem('token'));
        }

      }
      setIsLoading(false);
      dispatch('SET_ISSTRIPE_LOADING', false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      dispatch('SET_ISSTRIPE_LOADING', false);
    }
  };


  // if(customer) {
  //   // console.log(customer);
  //   // return <Redirect to={{pathname: '/prices'}} />
  //   // return <Redirect to={{pathname: '/account'}} />
  //   // return <Redirect to={{pathname: '/subscription/account'}} />
  //   // history.push('/subscription/account');
  // }

  return (
    <main>
      {isLoading && 
        <div className={classes.subscriptionLoader}><Loader /></div>
      }
      {/* {error && error} */}

      {/* <PaymentSetup stripePromise={stripePromise} />
      <CustomerMethod /> */}
    </main>
  );
}

export default PaymentRegister;
