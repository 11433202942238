import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next/hooks";

import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";

import * as paymentUtil from '../../util/payment';
import { useStore } from "../../hook-store/store";
// import { fetchPrices } from '../../utils/stripe';
import { BASE_URL } from "../../App";

import classes from "./CustomerMethodList.module.css";

const CustomerMethodListItems = (props) => {
  // console.log('CustomerMethodListItems.js-props', props);

  const lsToken = localStorage.getItem("token");

  // const history = useHistory();
  const {
    customerMethods,
    updateDefaultMethodHandler,
    detachPaymentMethod,
    // testCardCharge,
    // showMthodListHandler,
    // showMethodList,
    isLoading,
    error,
  } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();

  const validDefaultMethod = store.paymentStore.validDefaultMethod;
  const isStripeLoading = store.paymentStore.isStripeLoading;

  // console.log(state);
  const { i18n } = store;

  const [selectedId, setSelectedId] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const showDeleteConfirmHandler = (id) => {
    if (id) {
      setSelectedId(id);
      setShowDeleteConfirm(true);
    } else {
      setSelectedId("");
      setShowDeleteConfirm(false);
    }
  };

  let customerMethodListBody;
  if (customerMethods.length === 0 && !isLoading) {
    customerMethodListBody = (
    <div>
      {t('payment.text04', 'no payment method')}
    </div>
    );
  }
  if (customerMethods.length > 0) {

    customerMethodListBody = (
      <ul>
        {customerMethods.map((method) => {
          
          let isDefaultMethod = false;

          if (store.paymentStore.validDefaultMethod) {
            isDefaultMethod = method.id === store.paymentStore.validDefaultMethod.id;
            // console.log(isDefaultMethod, method.id, store.paymentStore.validDefaultMethod.id);
          }

          let isCardExpired = paymentUtil.cardExpire(method.card.exp_month, method.card.exp_year);
          // console.log(isCardExpired);
          // console.log(paymentUtil.cardExpire(3, 2022))

          return (
            <div key={method.id} className={classes.customerMethodListCard} >
              {/* <div>id: {method.id}</div> */}
              <div className={classes.customerMethodListItem}>
                {t('payment.text05', 'Last 4 digits of Card')}: {method.card.last4}
              </div>
              <div className={classes.customerMethodListItem}>
                {t('payment.text06', 'Expire Date')}: {method.card.exp_month}/{method.card.exp_year}
              </div>
              <div className={classes.customerMethodListItem}>
                {t('payment.text07', 'Added Date')}: {new Date(method.created * 1000).toLocaleDateString()}
              </div>
              {validDefaultMethod && validDefaultMethod.id === method.id && (
                <div className={classes.customerMethodListItem}>
                  <strong>{validDefaultMethod && t('payment.text12', 'Default Card')}</strong>
                </div>
              )}
              <div className={classes.customerMethodListButtons}>
                <div>
                  <Button
                    disabled={isLoading || isDefaultMethod || isCardExpired || isStripeLoading || showDeleteConfirm}
                    loading={isLoading || isStripeLoading}
                    onClick={() => {
                      updateDefaultMethodHandler(
                        BASE_URL,
                        lsToken,
                        method.id,
                        localStorage.getItem("customerId")
                      );
                    }}
                  >
                    {t('payment.text08', 'Set To Default')}
                  </Button>
                </div>
                <div className={classes.customerMethodListButton}>
                  <Button design="raised" type="submit"
                    disabled={isLoading || isStripeLoading || showDeleteConfirm}
                    loading={isLoading || isStripeLoading}
                    onClick={() => {
                      // detachPaymentMethod(method.id);
                      showDeleteConfirmHandler(method.id);
                    }}
                  >
                    {t('payment.text09', 'Delete Card')}
                  </Button>
                </div>
                {/* <button
                  disabled={isLoading}
                  onClick={() => {
                    testCardCharge(method.id);
                  }}
                >
                  {" "}
                  test-charge{" "}
                </button> */}
              </div>

              {showDeleteConfirm && selectedId === method.id && (
                <div>
                  <div className={classes.customerMethodListConfirm}>
                  {t('payment.text10', 'Is it no problem delete this card?')}
                  </div>
                  <div className={classes.customerMethodListButtons}>
                    <Button design="" mode="flat"
                      disabled={isLoading}
                      loading={isLoading}
                      onClick={() => {
                        showDeleteConfirmHandler("");
                      }}
                    >
                      {t('general.text1', 'Cancel')}
                    </Button>
                    <Button design="raised" mode="submit"
                      disabled={isLoading}
                      loading={isLoading}
                      onClick={() => {
                        detachPaymentMethod(method.id);
                        showDeleteConfirmHandler("");
                      }}
                    >
                      {t('general.text3', 'Delete')}
                    </Button>
                  </div>
                </div>
              )}
              {/* {isLoading && 
                <div className={classes.subscriptionLoader}><Loader /></div>
              } */}
            </div>
          );
        })}
      </ul>
    );
  }

  return (
    <Fragment>
      {isLoading && (
        <div className={classes.customerMethodListLoader}>
          <Loader />
        </div>
      )}

    {!validDefaultMethod && !isLoading && (
      <div className={classes.customerMethodListNoDefault}>
        There is no default card, please add or select card.
        {' '}
        Ads will be disabled without default payment card.
      </div>
    )}

      {error && <div>{error}</div>}

      {customerMethodListBody}
    </Fragment>
  );
};

export default CustomerMethodListItems;
