import React, { Component, Fragment } from "react";
import { withI18n } from "react-i18next";

import FilePicker from '../../Form/Input/FilePicker';
import Input from "../../Form/Input/Input";
import FeedEditImagePreview from "./feedEditImagePreview/FeedEditImagePreview";

import "./FeedEdit.css";

const FeedEditBudgetItems = (props) => {
  const { 
    t,
    state, 
    postInputChangeHandler, 
    inputBlurHandler, 
    budgetChangeHandler,
  } = props;

  const budgetLabel = `${t('general.text29', 'Budget')} / ${t('general.text35', 'day')}`;
  return (
    <Fragment>
      <Input
        id="budget"
        label={budgetLabel}
        // label={t("feed.text10")}
        control="input"
        placeholder="Budget for ad"
        // placeholder={t('feed.text15')}
        // onChange={postInputChangeHandler}
        onChange={budgetChangeHandler}
        onBlur={inputBlurHandler.bind(this, "budget")}
        valid={state.postForm["budget"].valid}
        touched={state.postForm["budget"].touched}
        // value={state.postForm["budget"].value}
      />

      {state.postForm["budget"].value && (
        <div>$ {state.postForm["budget"].value}</div>
      )}
      
      {!state.postForm["budget"].value && (
        <div>$ 0</div>
      )}
    </Fragment>
  );
};

export default withI18n()(FeedEditBudgetItems);
