import React, { useState, useEffect, Fragment } from "react";
// import './App.css';
import { Redirect, useHistory, Link } from "react-router-dom";
import i18next from "i18next";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next/hooks";

import CustomerMethodList from "./CustomerMethodList";
import Loader from "../../components/Loader/Loader";
import * as paymentUtil from '../../util/payment';

// import { useStore } from '../../hooks-store/store';
import { useStore } from "../../hook-store/store";
// import { fetchPrices } from '../../utils/stripe';
import { BASE_URL } from "../../App";

import classes from "./CustomerMethod.module.css";

const CustomerMethod = (props) => {
  // console.log('CustomerMethod.js-props', props);

  // // const url = new URL(window.location.href);
  // // const queryParams = url.searchParams;
  // // const customerEmail = queryParams.get('customer-email');
  // const customerEmail = localStorage.getItem("email");
  // // console.log(customerEmail);
  const lsToken = localStorage.getItem("token");

  // const { customerMethods, detachPaymentMethod } = props;

  // const history = useHistory();
  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();

  console.log('customerMethod.js store', store);
  const customerMethods = store.paymentStore.customerMethods;

  const { i18n } = store;

  // const [email, setEmail] = useState('jenny.rosen@example.com');
  const [email, setEmail] = useState(localStorage.getItem("email"));
  // const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // const [customerMethods, setCustomerMethods] = useState([]);
  const [showMethodList, setShowMethodList] = useState(true);

  useEffect(() => {
    getMethodsHandler(BASE_URL, lsToken);
  },[]);

  useEffect(() => {
    if (showMethodList && customerMethods.length === 0) {
      // getCardPaymentMethods();
      getMethodsHandler(BASE_URL, lsToken);
    }
  }, [showMethodList]);

  useEffect(() => {
    setDefaultMethod(customerMethods);
  },[customerMethods]);

  const showMthodListHandler = () => {
    setShowMethodList(!showMethodList);
  };

  const getMethodsHandler = async (url, token) => {
    try {
      setIsLoading(true);
      setError('');

      const result = await paymentUtil.getCardPaymentMethods(url, token);

      if (result) {

        const methodList = [];
        for (const method of result.customerMethods) {
          methodList.push(method);
        }

        // console.log(methodList);
        // setCustomerMethods(result.customerMethods);
        // setCustomerMethods(methodList);
        dispatch('SET_CUSTOMER_METHODS', methodList);

        setDefaultMethod(methodList);

      }
      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const detachPaymentMethod = async (paymentMethodId) => {
    try { 
      setIsLoading(true);
      setError('');

      const lsCustomerId = localStorage.getItem('customerId');
      
      if (lsCustomerId) {
        const response = await fetch(BASE_URL + `/payment/detach-payment-method`, {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + lsToken,
            'Content-Type': 'application/json',
            // 'Accept': 'application/json',
          },
          body: JSON.stringify({
            paymentMethodId: paymentMethodId
          })
        });

        // if (!response.ok) {
        //   throw new Error('something wrong');
        // } 
        const resData = await response.json();
        console.log(response, resData);
        setIsLoading(false);
        // getCardPaymentMethods();


        const methodList = customerMethods.filter(method => {
          return method.id !== paymentMethodId;
        });

        dispatch('SET_CUSTOMER_METHODS', methodList);
        // setDefaultMethod(methodList);


        // const methods = await getMethodsHandler(BASE_URL, lsToken);
        // console.log(methods);

      }

    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const updateDefaultMethodHandler = async (url, token, paymentMethodId, customerId ) => {
    try {
      setIsLoading(true);
      setError('');

      const result = await paymentUtil.updateDefaultPaymentMethod(
        url,
        token,
        paymentMethodId,
        customerId,
      );

      console.log(result);
      setIsLoading(false);
      
      // getCardPaymentMethods();
      // const methods = await getMethodsHandler(BASE_URL, lsToken);
      
      // console.log(methods);
      


      console.log(result.updatedPayamentMethods);
      const newDefaultMethod = result.updatedPayamentMethods.find(method => {
        return method.id === result.paymentMethodId;
      });
      // console.log(newDefaultMethod);
      dispatch('SET_CUSTOMER_METHODS', result.updatedPayamentMethods);

    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }

  };


  const setDefaultMethod = (customerMethods) => {
    const methodList = [];
    for (const method of customerMethods) {
      const cardExpire = paymentUtil.cardExpire(method.card.exp_month, method.card.exp_year);
      if (!cardExpire) {
        methodList.push(method);
      }
    }

    console.log(methodList);
    // if (methodList.length === 1) {
    //   dispatch('SET_VALID_DEFAULTMETHOD', methodList[0]);
    // }

    if (methodList.length > 0) {
      const isDefaultMethod = methodList.find(method => {
        return method.metadata.adNetworkDefault;
      });
      if (isDefaultMethod) {
        dispatch('SET_VALID_DEFAULTMETHOD', isDefaultMethod);
      } else {
        dispatch('SET_VALID_DEFAULTMETHOD', null);
      }
    }
    else {
      dispatch('SET_VALID_DEFAULTMETHOD', null);
    }
  };


  return <Fragment>

    <div className={classes.customerMethodTitle}>
      {t('payment.text03', 'Manage Card Payment Methods')}
    </div>


    {/* {isLoading && (
      <Loader />
    )} */}

    {showMethodList && 
      <CustomerMethodList 
        customerMethods={customerMethods}
        updateDefaultMethodHandler={updateDefaultMethodHandler}
        detachPaymentMethod={detachPaymentMethod}
        // testCardCharge={testCardCharge}
        // showMethodList={showMethodList}
        // showMthodListHandler={showMthodListHandler}
        isLoading={isLoading}
        error={error}
      />
    }
    </Fragment>;
};

export default CustomerMethod;
