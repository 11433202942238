import React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next/hooks";

import SearchAd from '../../components/SearchAd/SearchAd';

import { createDisplayId } from '../../util/ad-visit';
import { useStore } from '../../hook-store/store'


import classes from './TableControll.module.css';

function TableControll(props) {
  const { } = props;

  const [t] = useTranslation("translation");

  // console.log(data);
  const [store, dispatch] = useStore();
  // console.log('store in TableControll.js', store);

  const { 
    selectedAdElementId,
    duration,
   } = store.adReportStore;

  useEffect(() => {
    if (!selectedAdElementId || selectedAdElementId === 'all') {
      dispatch('SET_DURATION', 10);
    }
  },[selectedAdElementId]);

  useEffect(() => {
    return () => {
      // dispatch('SET_USER_ADRESULTS', []);
      // dispatch('SET_LIST_FORTABLE', []);
      dispatch('SET_SELECTED_ADELEMENTID', '');
      // dispatch('SET_SELECTED_ADELEMENTIDLIST', []);
      dispatch('SET_DURATION', 10);
      // dispatch('SET_TOTAL_RESULT', {
      //   visitNum: 0,
      //   displayNum: 0,
      //   charge: 0,
      // });
    }
  },[]);

  const setSuggestIdHandler = (adElementId) => {
    dispatch('SET_SELECTED_ADELEMENTID', adElementId);
  };

  let displayId = 'all';
  let displayDuration = `${duration} days`;
  let selectIdDisplay = true;

  if (selectedAdElementId && selectedAdElementId !== 'all') {
    displayId = createDisplayId(selectedAdElementId);
  }

  if (duration === 0) {
    displayDuration = 'from start';
  }

  if (window.innerWidth < 480 || window.innerHeight < 480) {
    selectIdDisplay = false;
  }

  return (
    <Fragment>

      {/* <div>table-controll</div> */}
      <div 
        className={classes.tableControlSearchAd}
      >
        <SearchAd 
          getSelectedSuggestId={setSuggestIdHandler}
        />
        {/* <div>or,</div> */}
      </div>

      {selectIdDisplay && (
        <div>
          <select className={classes.tableControlSelect}
              // value={pageSize}
              onChange={e => {
                dispatch('SET_SELECTED_ADELEMENTID', e.target.value);
                // setSelectedAdElement(e.target.value);
              }}
            >
            <option value="">
              {t('adReport.text03', 'Select Ad')}
            </option>
            <option 
              // value="all"
              value=""
            >
              {t('adReport.text04', 'Select All')}
            </option>
            {store.adReportStore.userAdResults.map((adResult, index) => (
              <option key={index} value={adResult.adElementId}>
                {t('general.text25', 'Title')}: {adResult.adElement.title} (ID: {createDisplayId(adResult.adElementId)})
              </option>
            ))}
          </select>
        </div>
      )}
        
        <div>
          {/* <div>select-duration</div> */}
          <select className={classes.tableControlSelect}
            // value={pageSize}
            onChange={e => {
              dispatch('SET_DURATION', Number(e.target.value));
              // setSelectedAdElement(e.target.value);
            }}
          >
            <option value={10}>
              {t('adReport.text05', 'Select Duration')}
            </option>
            {[10, 30, 90, 365].map((element, index) => (
              <option key={index} 
                value={element}
                // defaultValue={duration}
              >
                {element} {t('general.text47', 'days')}
              </option>
            ))}
            {selectedAdElementId && selectedAdElementId !== 'all' && (
              <option value={0}>
                {t('adReport.text12', 'from start date')}
              </option>
            )}
          </select>
        </div>

        <div className={classes.tableControlSelect}>
          <div className={classes.tableControlSelected}>
              <span>
                {t('adReport.text15', 'Selected ID')}: <strong>{displayId}</strong>, 
              </span>
              <span>
                {t('adReport.text16', 'Duration')}: <strong>{displayDuration}</strong>
              </span>
          </div>
        </div>

    </Fragment>
    // <Styles>
    // </Styles>
  )
}

export default TableControll;
