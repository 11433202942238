import React, { Fragment, useState } from "react";
import { withI18n } from "react-i18next";

// import Input from "../../../Form/Input/Input";
// import ImagePreviewContents from "./ImagePreviewContents";

import { isImageFile, isVideoFile } from "../../../../util/image";

import { marks } from "../../../../images/icon/marks";

import classes from './FeedEditVideoDuration.module.css';
import "../FeedEdit.css";

const FeedEditVideoDuration = (props) => {
  // console.log("FeedEditVideoDuration.js-props", props);
  const {
    t,
    state,
    selectedPost,

    postInputChangeHandler,
  } = props;


  let isVideoSelected = false;
  let postFormValue = 0;

  if (state && state.postForm.image.value) {

    if (state.postForm.image.value.type &&
          state.postForm.image.value.type.split('/')[0] === 'video'
    ) {
      isVideoSelected = true;
    }

    if (!state.postForm.image.value.type) {
      // const fileName = state.postForm.image.value;
      const fileNameList = state.postForm.image.value.split('.');
      const fileType = fileNameList.pop();
      
      isVideoSelected = isVideoFile(fileType);
    }
  }


  // // set default value depend on file type
  if (!isVideoSelected && state && 
    state.postForm.videoDuration.value > 0
  ) {
    postInputChangeHandler('videoDuration', 0);
  }

  if (isVideoSelected && state && 
    state.postForm.videoDuration.value === 0
  ) {
    postInputChangeHandler('videoDuration', 15);
  }


  if (state && state.postForm.videoDuration.value) {
    postFormValue = state.postForm.videoDuration.value;
  }


  //// when video selected, initialize images data (long wide)
  if (isVideoSelected && state && state.postForm.ad150x500ImageUrl.value) {
    postInputChangeHandler('ad150x500ImageUrl', '');
  }

  if (isVideoSelected && state && state.postForm.ad600x130ImageUrl.value) {
    postInputChangeHandler('ad600x130ImageUrl', '');
  }


  let feedEditVideoDurationBody;

  feedEditVideoDurationBody = (
    <div className={classes.feedEditVideoDuration}>
      <div className={classes.feedEditVideoDurationTitle}>
        Video Length
      </div>
      <div className={classes.feedEditVideoDurationContent}>
        <select className={classes.feedEditVideoDurationSelect}
          value={postFormValue}
          // defaultValue={postFormValue}
          onChange={e => {
            postInputChangeHandler(
              'videoDuration', 
              parseInt(e.target.value)
            );
          }}
        >
          {[15, 30, 60].map((duration, index) => (
            <option key={index} value={duration}>
              {duration} seconds-duration
            </option>
          ))}
        </select>
      </div>
      <div className={classes.feedEditVideoDurationContent}>
        Longer video will be trimed to selected duration.
      </div>
      <div>is-video-selected: {isVideoSelected ? 'video-selected' : 'not-video-selected'}</div>
      <div>post-form-value: {postFormValue}</div>
    </div>
  );

  return <Fragment>
    <div>
      {isVideoSelected && (
        <div>{feedEditVideoDurationBody}</div>
      )}
    </div>
    </Fragment>;
};

export default withI18n()(FeedEditVideoDuration);
