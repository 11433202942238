import React, { Fragment, useState } from "react";
import { withI18n } from "react-i18next";

import FilePicker from '../../Form/Input/FilePicker';
import Input from "../../Form/Input/Input";
import FeedEditImagePreview from "./feedEditImagePreview/FeedEditImagePreview";
import FeedEditImageReuse from './FeedEditImageReuse/FeedEditImageReuse';
import FeedEditVidoDuration from "./FeedEditVideoDuration/FeedEditVideoDuration";

import "./FeedEdit.css";

const FeedEditAdItems = (props) => {
  const { 
    t,
    state, 
    postInputChangeHandler, 
    inputBlurHandler, 
    imagePreviewBody,
    getInputHandler,
    selectedPost,
    updateImagePreviewHandler,
  } = props;

  const [showReuseImages, setShowReuseImages] = useState(false);

  return (
    <Fragment>
      <Input
        id="title"
        // label="Title"
        label={t("feed.text10", "Title")}
        control="input"
        // placeholder="Title for ad, up to 100 character length"
        placeholder={t('adForm.text01', 'Title for ad, up to 100 character length')}
        onChange={postInputChangeHandler}
        onBlur={inputBlurHandler.bind(this, "title")}
        valid={state.postForm["title"].valid}
        touched={state.postForm["title"].touched}
        value={state.postForm["title"].value}
      />

      <Input
        id="content"
        // label="Description"
        label={t('general.text26', 'Description')}
        // placeholder="Description, up to 250 character length"
        placeholder={t('adForm.text02', 'Description, up to 250 character length')}
        control="textarea"
        rows="2"
        onChange={postInputChangeHandler}
        // onChange={() => {
        //   this.contentInputChangeHandler('', this.state.contentInput);
        // }}
        onBlur={inputBlurHandler.bind(this, "content")}
        valid={state.postForm["content"].valid}
        touched={state.postForm["content"].touched}
        value={state.postForm["content"].value}
        // value={this.state.contentInput}
        getInput={getInputHandler}
        previousContentInput={
          selectedPost && selectedPost.content
        }
        // contentChangeNum={this.state.contentChangeNum}
      />

      <FilePicker
        id="image"
        // label="Media File"
        label={t('adForm.text03', 'Media File')}
        control="input"
        onChange={postInputChangeHandler}
        onBlur={inputBlurHandler.bind(this, "image")}
        valid={state.postForm["image"].valid}
        touched={state.postForm["image"].touched}
        style={{height:"75px"}}
      />

      <div className="feedEdit__aboutMediaFile">
        (
          Image File should be jpg, jpeg, png, or gif file, and should be less than 1MB.
          <br/>
          Video File should be mp4, or webm file, and should be less than 25MB. Wider video size (16:9) is recommended.
        )

      </div>
      
      <div>
        <FeedEditImageReuse 
          postInputChangeHandler={postInputChangeHandler}
        />
      </div>
      
      <div className="new-post__preview-image">
        {/* {imagePreviewBody} */}
        <FeedEditImagePreview 
          t={t}
          state={state}
          selectedPost={selectedPost}

          postInputChangeHandler={postInputChangeHandler}
          updateImagePreviewHandler={updateImagePreviewHandler}
        />
      </div>

      <div>
        <FeedEditVidoDuration 
          t={t}
          state={state}
          selectedPost={selectedPost}

          postInputChangeHandler={postInputChangeHandler}
        />
      </div>
    </Fragment>
  );
};

export default withI18n()(FeedEditAdItems);
