import React, { Component, Fragment } from 'react';
import { withI18n } from "react-i18next";

import Backdrop from '../../Backdrop/Backdrop';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import Input from '../../Form/Input/Input';
import InputEmoji from '../../Form/Input/InputEmoji';
import FeedEditAdItems from './FeedEditAdItems';
import FeedEditBudgetItems from './FeedEditBudgetItems';
import FeedEditDeviceItems from './FeedEditDeviceItems';
import FeedEditImageUpdate from './FeedEditImageUpdate/FeedEditImageUpdate';
import FeedEditLanguageItem from './FeedEditLanguage/FeedEditLanguageItem';
import FeedEditTimeItems from './FeedEditTimeItems';
import FeedEiditSizeLinkItems from './FeedEditSizeLinkItems';
import FilePicker from '../../Form/Input/FilePicker';
import Loader from '../../Loader/Loader';
import MapMainComponent from '../../Map/MapMainComponent';
// import Image from '../../Image/Image';
import { 
  required, 
  length, 
  acceptableFile, 
  acceptableFileSize,
  acceptable150x500File,
  acceptable150x500FileSize,
  startEndTimeValidity,
  targetLocationValidity,
} from '../../../util/validators';
import {
  generateBase64FromImage, isImageFile, isVideoFile
}
  from '../../../util/image';
import { BASE_URL } from '../../../App';
import './FeedEdit.css'

const POST_FORM = {
  title: {
    value: '',
    valid: false,
    touched: false,
    validators: [required, length({ min: 1, max: 100 })]
  },
  image: {
    value: '',
    valid: false,
    touched: false,
    validators: [required, acceptableFile]
    // validators: []
  },
  content: {
    value: '',
    valid: true,
    touched: false,
    validators: [length({ min: 0, max: 500 })]
  },
  public: {
    // value: 'private',
    value: 'public',
    valid: true,
    touched: false,
    validators: [required]
  },
  imageUrl: {
    value: '',
    valid: true,
    touched: false,
    validators: [required]
    // validators: []
  },
  size: {
    value: '',
    valid: true,
    touched: false,
    validators: [required, length({ min: 1 })]
  },
  linkUrl: {
    value: '',
    valid: false,
    touched: false,
    validators: [required, length({ min: 1 })]
  },
  start: {
    value: '',
    valid: false,
    touched: false,
    validators: []
  },
  end: {
    value: '',
    valid: false,
    touched: false,
    validators: []
  },
  targetLocation: {
    value: {},
    valid: false,
    touched: false,
    validators: [],
  },
  language: {
    // value: '',
    value: 'en',
    valid: true,
    touched: false,
    validators: [],
  },
  budget: {
    // value: '',
    value: 1,
    valid: true,
    touched: false,
    validators: [],
  },
  targetDevice: {
    // value: '',
    value: '',
    valid: true,
    touched: false,
    validators: [],
  },
  ad600x130ImageUrl: {
    value: '',
    valid: true,
    touched: false,
    validators: []
  },
  ad150x500ImageUrl: {
    value: '',
    valid: true,
    touched: false,
    validators: []
  },
  videoDuration: {
    value: 0,
    valid: true,
    touched: false,
    validators: []
  },
};

class FeedEdit extends Component {

  state = {
    postForm: POST_FORM,
    formIsValid: false,
    imagePreview: null,
    publicValue: '',
    sizeValue: '',
    linkUrlValue: '',
    showDeleteModal: false,
    contentInput: '',
    contentInputChanged: false,
    startDateValue: '',
    startTimeValue: '',
    endDateValue: '',
    endTimeValue: '',
    // targetLocationValue: {},
    isValidStartEnd: false,
    // contentChangeNum: 0,
    previousPostForm: POST_FORM,
    formInputChanged: false,
    // showReuseImages: false,
    image600x130Preview: null,
    image150x500Preview: null,
  };

  componentDidUpdate(prevProps, prevState) {
    console.log('FeedEdit-props', this.props)
    // console.log(prevProps, prevState);
    // console.log(this.state.postForm);
    if (
      this.props.editing &&
      prevProps.editing !== this.props.editing &&
      prevProps.selectedPost !== this.props.selectedPost
    ) {
      const postForm = {
        title: {
          ...prevState.postForm.title,
          value: this.props.selectedPost.title,
          valid: true
        },
        image: {
          ...prevState.postForm.image,
          // value: this.props.selectedPost.imagePath,
          value: this.props.selectedPost.adImagePath,
          valid: true
        },
        content: {
          ...prevState.postForm.content,
          // value: this.props.selectedPost.content,
          value: this.props.selectedPost.description,
          valid: true
        },
        public: {
          ...prevState.postForm.public,
          value: this.props.selectedPost.public,
          valid: true
        },
        imageUrl: {
          ...prevState.postForm.imageUrl,
          // value: this.props.selectedPost.imageUrl,
          value: this.props.selectedPost.adImageUrl,
          valid: true,
        },
        size: {
          ...prevState.postForm.size,
          value: this.props.selectedPost.size,
          valid: true,
        },
        linkUrl: {
          ...prevState.postForm.linkUrl,
          value: this.props.selectedPost.linkUrl,
          valid: true,
        },
        start: {
          ...prevState.postForm.start,
          value: this.props.selectedPost.start,
          valid: true,
        },
        end: {
          ...prevState.postForm.end,
          value: this.props.selectedPost.end,
          valid: true,
        },
        targetLocation: {
          ...prevState.postForm.targetLocation,
          value: this.props.selectedPost.targetLocation,
          valid: true,
        },
        language: {
          ...prevState.postForm.language,
          value: this.props.selectedPost.targetLanguage,
          valid: true,
        },
        budget: {
          ...prevState.postForm.budget,
          value: this.props.selectedPost.budget,
          valid: true,
        },
        targetDevice: {
          ...prevState.postForm.targetDevice,
          value: this.props.selectedPost.targetDevice,
          valid: true,
        },
        ad600x130ImageUrl: {
          ...prevState.postForm.ad600x130ImageUrl,
          value: this.props.selectedPost.ad600x130ImageUrl,
          valid: true,
        },
        ad150x500ImageUrl: {
          ...prevState.postForm.ad150x500ImageUrl,
          value: this.props.selectedPost.ad150x500ImageUrl,
          valid: true,
        },
        videoDuration: {
          ...prevState.postForm.videoDuration,
          value: this.props.selectedPost.videoDuration,
          valid: true,
        },
      };
      console.log(postForm);
      this.setState({ 
        postForm: postForm, 
        formIsValid: true,
        previousPostForm: postForm
       });
    }

    if (this.props.imageDeleted) {
      setTimeout(() => {
        console.log('in componentdidupdate');
        this.hideDeleteModalHandler();
        this.props.imageDeletedHandler(null);

      }, 1000 * 3);
    }

  }

  publicChangeHandler = (event) => {
    console.log(event.target);
    this.setState({
      publicValue: event.target.value
    }, () => {
      this.postInputChangeHandler('public', this.state.publicValue);
    });
  }

  sizeChangeHandler = (event) => {
    console.log(event.target);
    this.setState({
      sizeValue: event.target.value
    }, () => {
      this.postInputChangeHandler('size', this.state.sizeValue);
    });
  }

  linkUrlChangeHandler = (input, event) => {
    console.log(input, event.target);
    // this.setState({
    //   linkUrlValue: event.target.value
    // }, () => {
    //   this.postInputChangeHandler('linkUrl', this.state.linkUrlValue);
    // });
  }



  startDateChangeHandler = (event) => {
    console.log(event.target.value);
    this.setState({
      startDateValue: event.target.value
    }, () => {
      
      // const startDateTime = this.state.startDateValue + ', ' + this.state.startTimeValue;
      // this.postInputChangeHandler('start', startDateTime);

      if (this.state.startTimeValue) {
        const startDateTime = this.state.startDateValue + ', ' + this.state.startTimeValue;
        console.log('start time date', startDateTime, new Date(startDateTime), new Date(startDateTime).toLocaleString());
        if (new Date(startDateTime)) {
          this.postInputChangeHandler('start', new Date(startDateTime).getTime());
        }
      }
    });
  };

  startTimeChangeHandler = (event) => {
    console.log('event.target.value', event.target.value);

    const startDate = event.target.value.toString() + ':00:00';
    this.setState({
      startTimeValue: startDate,
    }, () => {
      const startDateTime = this.state.startDateValue + ', ' + this.state.startTimeValue;
      
      if (this.state.startDateValue) {
        console.log('start time date', startDateTime, new Date(startDateTime), new Date(startDateTime).toLocaleString());
        if (new Date(startDateTime)) {
          this.postInputChangeHandler('start', new Date(startDateTime).getTime());
        }
      }
    });
  };



  endDateChangeHandler = (event) => {
    console.log(event.target.value);
    this.setState({
      endDateValue: event.target.value
    }, () => {
      // const endDateTime = this.state.endDateValue + ', ' + this.state.endTimeValue;
      // this.postInputChangeHandler('end', endDateTime);

      if (this.state.endTimeValue) {
        const endDateTime = this.state.endDateValue + ', ' + this.state.endTimeValue;
        console.log('end time date', endDateTime, new Date(endDateTime), new Date(endDateTime).toLocaleString());
        if (new Date(endDateTime)) {
          this.postInputChangeHandler('end', new Date(endDateTime).getTime());
        }
      }
    });
  };

  endTimeChangeHandler = (event) => {
    console.log('event.target.value', event.target.value);

    const endDate = event.target.value.toString() + ':00:00';
    this.setState({
      endTimeValue: endDate,
    }, () => {

      const endDateTime = this.state.endDateValue + ', ' + this.state.endTimeValue;
      if (this.state.endDateValue) {
        console.log('end time date', endDateTime, new Date(endDateTime), new Date(endDateTime).toLocaleString());
        if (new Date(endDateTime)) {
          this.postInputChangeHandler('end', new Date(endDateTime).getTime());
        }
      }
    });
  };


  targetLocationChangeHandler = (latitude, longitude, radiusKm) => {
    console.log(latitude, longitude, radiusKm);
    this.postInputChangeHandler('targetLocation', {
      latitude: latitude,
      longitude: longitude,
      radiusKm: radiusKm,
    });
  }

  // languageChangeHandler = (language) => {
  //   console.log(language);
  //   this.postInputChangeHandler('language', language);
  // }

  budgetChangeHandler = (id, input) => {
    console.log('budget Number(input)', input, parseFloat(input));
    const budgetNumber = parseFloat(input);

    //// think limit minimum etc later...

    if (!isNaN(Number(input)) && budgetNumber > 0) {
      // console.log('budget >= 0');
      // this.postInputChangeHandler('budget', input);
      this.postInputChangeHandler('budget', budgetNumber.toFixed(2));
    }
    if (!isNaN(Number(input)) && budgetNumber === 0) {
      // console.log('budget >= 0');
      // this.postInputChangeHandler('budget', Number(input));
      this.postInputChangeHandler('budget', '');
    }
 
  }





  // postInputChangeHandler = (input, value, files) => {
  postInputChangeHandler = (input, value, files, reuseImageUrl) => {
    console.log('input value files', input, value, files);

    // if(input ==='content') {
    //   // this.setState({ contentChangeNum: this.state.contentChangeNum + 1 });
    // }

    if (files && input === 'image') {
      generateBase64FromImage(files[0])
        .then(b64 => {
          // console.log(b64);
          this.setState({ imagePreview: b64 });
        })
        .catch(e => {
          this.setState({ imagePreview: null });
        });
    }


    if (files && input === 'ad150x500ImageUrl') {
      generateBase64FromImage(files[0])
        .then(b64 => {
          // console.log(b64);
          this.setState({ image150x500Preview: b64 });
        })
        .catch(e => {
          this.setState({ image150x500Preview: null });
        });
    }

    if (files && input === 'ad600x130ImageUrl') {
      generateBase64FromImage(files[0])
        .then(b64 => {
          // console.log(b64);
          this.setState({ image600x130Preview: b64 });
        })
        .catch(e => {
          this.setState({ image600x130Preview: null });
        });
    }



    //// display reused image in image preview
    if (!files && input === 'image' && reuseImageUrl) {
      //// show reuse image
      this.setState({ imagePreview: reuseImageUrl });
    }

    this.setState(prevState => {
      // console.log(prevState);
      let isValid = true;
      for (const validator of prevState.postForm[input].validators) {
        // console.log(validator);
        // console.log(value);
        isValid = isValid && validator(value);
      }
      // console.log(prevState.postForm);
      const updatedForm = {
        ...prevState.postForm,
        [input]: {
          ...prevState.postForm[input],
          valid: isValid,
          value: files ? files[0] : value
        },
      };


      //// check file validity when file exist
      if (files && input === 'image') {
        updatedForm.image.valid = false;

        let isAcceptableFile = false;
        let isAcceptableFileSize = false;

        if (files[0] && files[0].type.split('/')[0] === 'video') {
          isAcceptableFile = acceptableFile(value);
          isAcceptableFileSize = acceptableFileSize(files[0]);
        }

        if (files[0] && files[0].type.split('/')[0] === 'image') {
          isAcceptableFile = acceptable150x500File(value);
          isAcceptableFileSize = acceptable150x500FileSize(files[0]);
        }

        // const isAcceptableFile = acceptableFile(value);
        // const isAcceptableFileSize = acceptableFileSize(files[0]);

        if (isAcceptableFile && isAcceptableFileSize) {
          updatedForm.image.valid = true;
        }
      } else {
        //// for no-file ad
        updatedForm.image.valid = true;
      }


      if (files && input === 'ad150x500ImageUrl') {
        updatedForm.ad150x500ImageUrl.valid = false;

        const isAcceptableFile = acceptable150x500File(files[0].name);
        const isAcceptableFileSize = acceptable150x500FileSize(files[0]);

        if (isAcceptableFile && isAcceptableFileSize) {
          updatedForm.ad150x500ImageUrl.valid = true;
        }
      }

      if (files && input === 'ad600x130ImageUrl') {
        updatedForm.ad600x130ImageUrl.valid = false;

        const isAcceptableFile = acceptable150x500File(files[0].name);
        const isAcceptableFileSize = acceptable150x500FileSize(files[0]);

        if (isAcceptableFile && isAcceptableFileSize) {
          updatedForm.ad600x130ImageUrl.valid = true;
        }
      }



      let formIsValid = true;
      for (const inputName in updatedForm) {
        formIsValid = formIsValid && updatedForm[inputName].valid;
      }

      console.log(updatedForm);
      console.log('formIsValid', formIsValid);


      // this.checkInputChanges(updatedForm);

      return {
        postForm: updatedForm,
        formIsValid: formIsValid
      };
    });
  };

  inputBlurHandler = input => {
    this.setState(prevState => {
      return {
        postForm: {
          ...prevState.postForm,
          [input]: {
            ...prevState.postForm[input],
            touched: true
          }
        }
      };
    });
  };

  cancelPostChangeHandler = () => {
    console.log('this.state.formInputChanged',this.state.formInputChanged)

    const selectedPostId = localStorage.getItem('selectedPostId');

    if (this.state.formInputChanged) {
      const closeConfirm = window.confirm(
        `
        Your inputs are changed.
        Is it no problem to close without saving inputs? 
        `)

      if (closeConfirm) {
        this.setState({
          postForm: POST_FORM,
          formIsValid: false,
          imagePreview: null,

          image600x130Preview: null,
          image150x500Preview: null,

          previousForm: POST_FORM,
        });
        this.props.onCancelEdit();

        //// delete selectedId, edit from single postpage
        if (selectedPostId) {
          this.props.history.push(`/feed/${selectedPostId}`);
          localStorage.removeItem('selectedPostId');
        }
      }
      
    } else {
      this.setState({
        postForm: POST_FORM,
        formIsValid: false,
        imagePreview: null,

        image600x130Preview: null,
        image150x500Preview: null,

        previousForm: POST_FORM,
      });
      this.props.onCancelEdit();

      //// delete selectedId, edit from single postpage
      if (selectedPostId) {
        this.props.history.push(`/feed/${selectedPostId}`);
        localStorage.removeItem('selectedPostId');
      }
    }



    // this.setState({
    //   postForm: POST_FORM,
    //   formIsValid: false,
    //   imagePreview: null,
    // });
    // this.props.onCancelEdit();
  };

  acceptPostChangeHandler = () => {
    const post = {
      title: this.state.postForm.title.value,
      image: this.state.postForm.image.value,
      imageUrl: this.state.postForm.imageUrl.value,
      content: this.state.postForm.content.value,
      public: this.state.postForm.public.value,
      size: this.state.postForm.size.value,
      linkUrl: this.state.postForm.linkUrl.value,
      start: this.state.postForm.start.value,
      end: this.state.postForm.end.value,
      targetLocation: this.state.postForm.targetLocation.value,
      language: this.state.postForm.language.value,
      budget: this.state.postForm.budget.value,
      targetDevice: this.state.postForm.targetDevice.value,
      ad600x130ImageUrl: this.state.postForm.ad600x130ImageUrl.value,
      ad150x500ImageUrl: this.state.postForm.ad150x500ImageUrl.value,
      videoDuration: this.state.postForm.videoDuration.value,
    };
    this.props.onFinishEdit(post);
    this.setState({
      postForm: POST_FORM,
      formIsValid: false,
      imagePreview: null,

      image600x130Preview: null,
      image150x500Preview: null,
    });

    // delete selectedId, edit from single postpage
    const selectedPostId = localStorage.getItem('selectedPostId');
    if (selectedPostId) {
      localStorage.removeItem('selectedPostId');
    }
  };

  showDeleteModalHandler = () => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };
  hideDeleteModalHandler = () => {
    this.setState({ showDeleteModal: false });
  };

  // showReuseImagesHandler = (event) => {
  //   event.preventDefault();
  //   this.setState({ showReuseImages: !this.state.showReuseImages });
  // }

  // contentInputChangeHandler = (input, value) => {
  //   this.setState({ contentInput: value }, () => {
  //     console.log(this.state.contentInput);
  //   });
  //   // console.log(commentInput);
  // }
  getInputHandler = (input) => {
    if (this.props.selectedPost) {
      // console.log(this.props.selectedPost.content);
      console.log(input);
      // const previousContent = this.props.selectedPost.content;
      
      if (input || (!input && this.state.contentInputChanged)) {
        this.setState({ 
          contentInput: input,
          contentInputChanged: true
        }, () => {
          console.log(this.state.contentInput);
          this.postInputChangeHandler('content', this.state.contentInput);
        });
      }
    } else {
      this.setState({ 
        contentInput: input,
        contentInputChanged: true
      }, () => {
        console.log(this.state.contentInput);
        this.postInputChangeHandler('content', this.state.contentInput);
      });
    }

  }

  checkInputChanges = (postForm) => {
    const previousForm = this.state.previousPostForm;
    if (previousForm.title.value !== postForm.title.value ||
        previousForm.image.value !== postForm.image.value ||
        previousForm.content.value !== postForm.content.value ||
        previousForm.public.value !== postForm.public.value
    ) {
      this.setState({
        formInputChanged: true
      });
      // return true;
    }
    else {
      this.setState({
        formInputChanged: false
      });
      // return false;
    }
  }

  
  updateImagePreviewHandler = (adElementId, imageType, imageTypeUpdatedUrl) => {
    
    // this.cancelPostChangeHandler();

    // this.props.startEditAdHandler(adElementId);

    const postForm = this.state.postForm;

    if (imageType === 'ad150x500ImageUrl') {
      this.setState({ image150x500Preview: null });

      postForm.ad150x500ImageUrl.value = imageTypeUpdatedUrl;

    }

    if (imageType === 'ad600x130ImageUrl') {
      this.setState({ image600x130Preview: null });

      postForm.ad600x130ImageUrl.value = imageTypeUpdatedUrl;
    }
    
    this.setState({
      postForm: postForm,
    });

  }


  render() {
    const { t } = this.props;


    const isValidTimes = startEndTimeValidity(this.state.postForm.start.value, this.state.postForm.end.value);
    const isValidTargetLocation = targetLocationValidity(
      this.state.postForm.targetLocation.value.latitude,
      this.state.postForm.targetLocation.value.longitude,
      this.state.postForm.targetLocation.value.radiusKm, 
    );
    console.log('isValidTimes', isValidTimes, 'start', this.state.postForm.start.value, 'end', this.state.postForm.end.value);
    console.log('isValidTargetLocation', isValidTargetLocation)
    // console.log('this.state.imagePreview', this.state.imagePreview);



    return this.props.editing ? (
      <Fragment>
        <Backdrop onClick={this.cancelPostChangeHandler} />
        <Modal
          title=""
          // acceptEnabled={this.state.formIsValid && this.state.postForm.size.value}
          acceptEnabled={this.state.formIsValid && isValidTimes && isValidTargetLocation}
          onCancelModal={this.cancelPostChangeHandler}
          onAcceptModal={this.acceptPostChangeHandler}
          isLoading={this.props.loading}
        >
          <form>

            <FeedEditAdItems
              t={this.props.t}
              state={this.state}
              postInputChangeHandler={this.postInputChangeHandler}
              inputBlurHandler={this.inputBlurHandler}
              // imagePreviewBody={imagePreviewBody}
              getInputHandler={this.getInputHandler}
              selectedPost={this.props.selectedPost}
              updateImagePreviewHandler={this.updateImagePreviewHandler}
              // showReuseImagesHandler={this.showReuseImagesHandler}
            />


            <FeedEiditSizeLinkItems 
              state={this.state}
              postInputChangeHandler={this.postInputChangeHandler}
              inputBlurHandler={this.inputBlurHandler}
              sizeChangeHandler={this.sizeChangeHandler}
              // selectedPost={this.props.selectedPost}
            />

            
            <FeedEditTimeItems 
              state={this.state}
              startDateChangeHandler={this.startDateChangeHandler}
              startTimeChangeHandler={this.startTimeChangeHandler}
              endDateChangeHandler={this.endDateChangeHandler}
              endTimeChangeHandler={this.endTimeChangeHandler}
              isValidTimes={isValidTimes}
            />
            
            <MapMainComponent 
              targetLocationChangeHandler={this.targetLocationChangeHandler}
              selectedPost={this.props.selectedPost}
            />


            <FeedEditLanguageItem 
              state={this.state}
              postForm={this.state.postForm}
              // languageChangeHandler={this.languageChangeHandler}
              postInputChangeHandler={this.postInputChangeHandler}
            />

            <FeedEditDeviceItems
              state={this.state}
              postInputChangeHandler={this.postInputChangeHandler}
            />
            

            <FeedEditBudgetItems
              t={this.props.t}
              state={this.state}
              postInputChangeHandler={this.postInputChangeHandler}
              inputBlurHandler={this.inputBlurHandler}
              budgetChangeHandler={this.budgetChangeHandler}
              // imagePreviewBody={imagePreviewBody}
              // getInputHandler={this.getInputHandler}
              // selectedPost={this.props.selectedPost}
            />

          </form>
        </Modal>
      </Fragment>
    ) : null;
  }
}

export default withI18n()(FeedEdit);
// export default FeedEdit;
