// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
// import Generic from './Generic.jsx';

import { useTranslation } from "react-i18next/hooks";

// import AdminSearchAd from "../../components/SearchAd/AdminSearchAd";
// import AdminAdElementsItem from "../../components/AdminAdElements/AdminAdElementsItem";
// import AdminSearchUser from './AdminSearchUser';
import Loader from "../../components/Loader/Loader";
// import Paginator from "../../components/Paginator/Paginator";
// // import UserAdElementsItem from "../../components/UserAdElements/UserAdElementsItem";

import { useStore } from "../../hook-store/store";
// import { getUserDataForStore } from "../../util/user";
// import { getUserAdElements } from "../../util/ad-visit";
// import { getAdElementsForAdmin, isAdminUser } from "../../util/admin-ad-element";

import { BASE_URL } from "../../App";

// import classes from './AdminAdElements.module.css';

const AdminAccountStatus = (props) => {
//   console.log('AdminTopPage.js-props', props);
  const { userId } = props;

//   const perPagePost = 20;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const usersForAdmin = store.adminStore.usersForAdmin;
//   const adminSelectedUser = store.adminStore.adminSelectedUser;

  const [statusInput, setStatusInput] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {

//     console.log(statusInput);
//   },[statusInput]);


  const updateAccountStatus = async (userId, newStatus) => {
    // console.log('in getUsersForAdmin');
    try {
      setIsLoading(true);

      const result = await fetch(BASE_URL + '/admin-user/account-status', {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            accountStatus: newStatus,
            userId: userId,
        }),
      });

      const resData = await result.json();
      console.log(resData);

      if (result.ok) {
        const userList = usersForAdmin;

        const userIndex = userList.findIndex((el) => {
          return el.userId === userId;
        });
  
        userList[userIndex].accountStatus = newStatus;
        // console.log(adList);
        dispatch('SET_USERS_FORADMIN', userList);

      } else {
        throw new Error('error-occured');
      }

      setShowConfirm(false);
      setIsLoading(false);

    } catch(err) { 
      console.log(err); 
      setIsLoading(false);
    }
  }

 
  return (
    <Fragment>
      <div>admin-update-account-status-content</div>
      <div>
            <span>account-status-choice: </span>
            <select 
            // className={classes.tableControlSelect}
              // // value={pageSize}
              // defaultValue={adStatus}
              onChange={e => {
                // console.log(e.target.value);
                setStatusInput(e.target.value);
                setShowConfirm(false);
              }}
            >
              {['', 'as1', 'as2', 'as-other', 'disabled' ].map((status, index) => (
                <option key={index} value={status}>
                  {status}
                </option>
              ))}
            </select>
            <button
              disabled={isLoading}
              onClick={() => { setShowConfirm(true); }}            
            >
                update-status
            </button>
            {showConfirm && (
              <div>
                <div>confirm-account-status-update: {statusInput}</div>
                <button 
                  disabled={isLoading}
                  onClick={() => { setShowConfirm(false); }}
                >
                  cancel
                </button>
                <button 
                  disabled={isLoading}
                  onClick={() => { updateAccountStatus(userId, statusInput );}}
                >
                  update
                </button>
              </div>
            )}
            {isLoading && <Loader />}
          </div>
    </Fragment>
  );
};

export default AdminAccountStatus;
