export const pauseUserFutureEndAds = async (url, token) => {
    try {
      const result = await fetch(url + `/ad-user/pause-user-future-end-ads`, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        // body: JSON.stringify('bodycontent'),
      });

      const resData = await result.json();

      console.log(result,resData);
      return resData;

    } catch(err) {
      console.log(err);
    }
};