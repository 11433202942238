// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
// import Generic from './Generic.jsx';

import { useTranslation } from "react-i18next/hooks";

import AccountDisabled from "../../components/UserAdElements/AccountStatus/AccountDisabled";
import SearchAd from "../../components/SearchAd/SearchAd";
import Loader from "../../components/Loader/Loader";
import Paginator from "../../components/Paginator/Paginator";
import UserAdElementsItem from "../../components/UserAdElements/UserAdElementsItem/UserAdElementsItem";

import { useStore } from "../../hook-store/store";
import { getUserDataForStore } from "../../util/user";
import { getUserAdElements } from "../../util/ad-visit";

import { BASE_URL } from "../../App";

import classes from './UserAdElements.module.css';

const UserAdElements = (props) => {
  const { isAuth, startEditAdHandler } = props;

  const perPagePost = 20;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  // console.log('store in UserAdElements.js', store);

  const [isLoading, setIsLoading] = useState(false);
  const [suggestId, setSuggestId] = useState('');
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    // getAccessInfo();
  },[]);

  useEffect(() => {
    if (isAuth) {
      if (store.userAdElements.length === 0) {
        setIsLoading(true);

        getUserAdElements(BASE_URL, localStorage.getItem('token'))
        .then(result => {
          console.log(result);
          dispatch('SET_USER_ADELEMENTS', result.data);
          setIsLoading(false);
        })
        .catch(err => {
          console.log(err);
          setIsLoading(false);
        });
      }

      if (!store.userData) {
          setIsLoading(true);
          getUserDataForStore(BASE_URL, localStorage.getItem('token'))
          .then(result => {
            console.log(result);
      
            dispatch('SET_USERDATA', result.userData);
            setIsLoading(false);
          })
          .catch(err => {
            console.log(err);
            setIsLoading(false);
          });
      }
    }
  },[isAuth]);

  const getAccessInfo = async () => {
    try {
      const response = await fetch(BASE_URL + '/example');
      console.log('response', response);

      //// handle ok case
      if (response.ok) {
        const resData = await response.json();
        console.log('resData', resData);
      } 

      //// handle not ok case
      else {

        // //// handle not ok and status code specific case
        // if (accessResponse.status === 400) {
        //   console.log('status 400');
        //   const errorData = await response.json();
        //   console.log('errorData', errorData);
        // }

        console.log('fetching data failed, error response returned');
        const errorData = await response.json();
        console.log('errorData', errorData);
      }

    } catch (err) {
      console.log(err);
    }
  };

  // const deleteAdElement = (url, token, adElementId) => {
  //   fetch(url + `/ad-element/${adElementId}`, {
  //     method: 'DELETE',
  //     headers: {
  //       Authorization: 'Bearer ' + token,
  //       'Content-Type': 'application/json'
  //     },
  //   })
  //     .then(resData => {
  //       console.log(resData);
        
  //       getUserAdElements(BASE_URL, localStorage.getItem('token'));

  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // };

  const setSuggestIdHandler = (adElementId) => {
    // console.log(adElementId);
    setSuggestId(adElementId);
  };


  let userAdElementsBody;

  if (store.userAdElements.length > 0) {

    if (!suggestId) {
      const start = perPagePost * (pageNum - 1);
      const end = perPagePost * pageNum;

      userAdElementsBody = (
        <ul>
          {store.userAdElements.slice(start, end).map(ad => {
            return (
              <UserAdElementsItem
                ad={ad}
                startEditAdHandler={startEditAdHandler}
              />
            );
          })}
        </ul>
      );
    }
    else {
      const suggestElIndex = store.userAdElements.findIndex(ad => {
        return ad.adElementId === suggestId;
      });

      userAdElementsBody = (
        <ul>
          {store.userAdElements.slice(suggestElIndex, suggestElIndex+1).map(ad => {
            return (
              <UserAdElementsItem
                ad={ad}
                startEditAdHandler={startEditAdHandler}
              />
            );
          })}
        </ul>
      );
    }
  }

  let createAdBody;
  if (!isAuth) {
    createAdBody = <div>auth required</div>;
  }
  
  if (isAuth) {
    createAdBody = (
      <div className={classes.userAdElements}>
        <SearchAd 
          getSelectedSuggestId={setSuggestIdHandler}
        />
        <div>{userAdElementsBody}</div>
        {!suggestId && (
          <Paginator
            onPrevious={() => { setPageNum(pageNum - 1); }}
            onNext={() => { setPageNum(pageNum + 1); }}
            lastPage={Math.ceil(store.userAdElements.length / perPagePost)}
            currentPage={pageNum}
          >
              {/* {feedPost2} */}
          </Paginator>
        )}
      </div>
    );
  }

  if (isLoading) {
    createAdBody = <div className="feed__loader"><Loader /></div>
  }

  return (<Fragment>
    {store.userData && store.userData.accountStatus && store.userData.accountStatus === 'disabled' && (
      <div>
        {/* account-status: {store.userData.accountStatus} */}
        <AccountDisabled/>
      </div>
    )}

    {store.userData && store.userData.status && store.userData.status === 'admin' && (
      <div>
        user-status: admin
        <div>
          <Link to="/admin/ad-elements">admin-ad-elements-page</Link>
        </div>
        <div>
          <Link to="/admin/top-page">admin-top-page</Link>
        </div>
      </div>
    )}
    {createAdBody}
    </Fragment>);
};

export default UserAdElements;
