export const storeClickData = (url, token, adElementId, type, linkUrl) => {
  return new Promise((resolve, reject) => {

    fetch(url + `/ad-visit/store-click-visit`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        adElementId: adElementId,
        type: type,
        linkUrl: linkUrl,
        // geolocation: userLocation,
      })
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Can't store click data");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);
  
        resolve({ message: 'store click data success', data: resData });
      })
      .catch(err => {
        console.log(err);
        // resolve({ message: 'emailVerified fbUserId update failed', error: err });
        reject({ message: 'store click data failed', error: err });
      });
  });
};


export const deleteAdElement = (url, token, adElementId) => {
  return new Promise((resolve, reject) => {
    fetch(url + `/ad-element/${adElementId}`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Can't delete adElements data");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);
        
        // dispatch('SET_USER_ADELEMENTS', resData.data);
        resolve({ message: 'delete adElement data success', data: resData });
      })
      .catch(err => {
        console.log(err);
        // resolve({ message: 'emailVerified fbUserId update failed', error: err });
        reject({ message: 'delete AdElment data failed', error: err });
      });
  });
};


export const getUserAdElements = (url, token) => {
  return new Promise((resolve, reject) => {
    fetch(url + `/ad-element/user-ad-elements`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error("Can't get user adElements data");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);
        
        // dispatch('SET_USER_ADELEMENTS', resData.data);
        resolve({ message: 'get user adElements data success', data: resData.data });
      })
      .catch(err => {
        console.log(err);
        // resolve({ message: 'emailVerified fbUserId update failed', error: err });
        reject({ message: 'get user AdElments data failed', error: err });
      });
  });
};

export const createListForSearchAd = (userAdElements) => {
  const list = [];
  for (const userAdElement of userAdElements) {
    list.push({
      adElementId: userAdElement.adElementId,
      title: userAdElement.title,
      description: userAdElement.description,
    });
  }

  return list;
};

export const createDisplayId = (adElementId) => {
  if (!adElementId) {
    return ''
  }
  
  const elementList = adElementId.split('-');
  const lastEl = elementList[elementList.length - 1];
  return lastEl;
};