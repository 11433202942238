import React, { Fragment } from "react";
import { withI18n } from "react-i18next";

import Input from "../../Form/Input/Input";
import "./FeedEdit.css";

const FeedEditSizeLinkItems = (props) => {
  // console.log('FeedEditSizeLinItems.js-props', props);
  const {
    state,
    postInputChangeHandler,
    inputBlurHandler,
    sizeChangeHandler,
    t,
    // selectedPost,
  } = props;

  const urlPlaceHolder = `${t('adForm.text09', 'website')} URL (for example, https://yoursite.com)`;

  return (
    <Fragment>
      {/* <div>
        <label for="size-select">SIZE</label>
        <div>
          <select
            name="size"
            id="size-select"
            className="feedEdit__selectStyle"
            value={state.postForm.size.value}
            onChange={sizeChangeHandler}
          >
            <option value="">Select Size (width, height)</option>
            <option value="300x300">300px, 300px</option>
            <option value="300x65">300px, 65px</option>
          </select>
        </div>
      </div> */}

      <Input
        id="linkUrl"
        label="URL"
        // label={t('feed.text10')}
        control="input"
        placeholder={urlPlaceHolder}
        // placeholder={t('feed.text15')}
        // onChange={this.linkUrlChangeHandler}
        onChange={postInputChangeHandler}
        onBlur={inputBlurHandler.bind(this, 'linkUrl')}
        valid={state.postForm['linkUrl'].valid}
        touched={state.postForm['linkUrl'].touched}
        value={state.postForm['linkUrl'].value}
        type='url'
      />
    </Fragment>
  );
};

export default withI18n()(FeedEditSizeLinkItems);
