import QRCode from 'qrcode';

import { acceptImageType, acceptVideoType } from './validators';

export const generateBase64FromImage = imageFile => {
  const reader = new FileReader();
  const promise = new Promise((resolve, reject) => {
    reader.onload = e => {
      console.log(e.target);
      return resolve(e.target.result);
    }
    reader.onerror = err => {
      // console.log(err);
      return reject(err);
    }
  });

  reader.readAsDataURL(imageFile);
  return promise;
};

export const isImageFile = (fileType) => {
  // console.log(fileType)
  let matchType;
  if (fileType) {
    matchType = acceptImageType.find(type => type === fileType.toLowerCase());
  }
  // console.log(matchType);

  if (
    matchType
  ) {
    return true
  } else {
    return false
  }
}

export const isVideoFile = (fileType) => {
  // console.log(fileType)
  let matchType;
  if (fileType) {
    matchType = acceptVideoType.find(type => type === fileType.toLowerCase());
  }
  // console.log(matchType);

  if (
    matchType
  ) {
    return true;
  } else {
    return false;
  }
}

export const makeQrcode = (textData) => {
  return new Promise((resolve, reject) => {
    QRCode.toDataURL(textData, {
      margin: 2,
    })
      .then(url => {
        // console.log(url);
        resolve(url);
      })
      .catch(err => {
        // console.error(err);
        reject(err);
      })
  });
}